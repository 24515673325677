// --------------------------------------------------------------
// Created On: 2023-10-05
// Author: Zachary Thomas
//
// Last Modified: 2024-03-18
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Chart from "react-apexcharts";
import { FUEL_RATE } from "../../constants/attributes";
import { ApexOptions } from "apexcharts";
import PropTypes from "prop-types";
import EllipsisSpinner from "../EllipsisSpinner/EllipsisSpinner";
import { BREAKPOINT_SMALL } from "../../constants/breakpoints";
import styles from "./Gauge.module.scss";

// Gauge that displays the current value and the expected ranges of the value.
export default function Gauge(props: Props): Component {
  const windowDimensions = useWindowDimensions();
  const tickInterval = (props.max - props.min) / 6;
  const midTickOneValue = Math.round(props.min + tickInterval);
  const midTickTwoValue = Math.round(props.min + tickInterval * 2);
  const midTickThreeValue = Math.round(props.min + tickInterval * 3);
  const midTickFourValue = Math.round(props.min + tickInterval * 4);
  const midTickFiveValue = Math.round(props.min + tickInterval * 5);
  const dataIsStale = props.staleAttributeCodes.includes(props.code);

  const options = useMemo(
    () => getOptions(props.min, props.max, props.value, props.unitShortName, props.code),
    [props.min, props.max, props.value, props.unitShortName, props.code]
  );

  // Get the percentage of where the current value should fall on the gauge graph.
  function getPercentageGraph(value: string | null, max: number, min: number): number {
    if (value === null) {
      return min;
    }
    let valuePercentage = (parseInt(value, 10) * 100) / max;
    if (valuePercentage > 100) {
      valuePercentage = 100;
    } else if (valuePercentage < 0) {
      valuePercentage = 0;
    }
    return valuePercentage;
  }

  // Get the options needed to configure the gauge.
  function getOptions(
    min: number,
    max: number,
    value: string | null,
    unitShortName: string | null,
    code: string
  ): ApexOptions | null {
    let unitLabel = "";

    // Set a different offset for the label based on if the units are under it.
    let dataLabelOffset = -15;
    if (unitShortName !== null && unitShortName.length > 2) {
      unitLabel = unitShortName;
      dataLabelOffset = -25;
    }

    // Make sure most values are shown as whole numbers,
    // while supporting floating points on rare exceptions.
    let displayValue: number | null = null;
    if (value !== null && code === FUEL_RATE) {
      displayValue = Math.round(parseFloat(value) * 100) / 100;
    } else if (value !== null) {
      displayValue = parseInt(value, 10);
    }

    return {
      responsive: [
        {
          breakpoint: BREAKPOINT_SMALL,
          options: {
            plotOptions: {
              radialBar: {
                track: {
                  background: "var(--border)",
                },
                hollow: {
                  size: "40%",
                },
                startAngle: -110,
                endAngle: 110,
                dataLabels: {
                  name: {
                    fontSize: "8px",
                    color: "var(--content-text)",
                    offsetY: 2,
                  },
                  value: {
                    offsetY: dataLabelOffset,
                    fontSize: "10px",
                    formatter: function () {
                      if (displayValue === null) {
                        return "N/A";
                      } else if (unitShortName !== null && unitShortName.length <= 2) {
                        return `${displayValue}${unitShortName}`;
                      } else {
                        return `${displayValue}`;
                      }
                    },
                  },
                },
              },
            },
          },
        },
      ],

      plotOptions: {
        radialBar: {
          track: {
            background: "var(--border)",
          },
          hollow: {
            size: "60%",
          },
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "14px",
              color: "var(--content-text)",
              offsetY: 10,
            },
            value: {
              offsetY: dataLabelOffset,
              fontSize: "18px",
              formatter: function () {
                if (displayValue === null) {
                  return "N/A";
                } else if (unitShortName !== null && unitShortName.length <= 2) {
                  return `${displayValue}${unitShortName}`;
                } else {
                  return `${displayValue}`;
                }
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [min, max],
        },
      },
      stroke: {
        dashArray: 4,
      },
      labels: [unitLabel],
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      theme: {
        palette: value || "",
      },
    };
  }

  return options ? (
    <div className={dataIsStale || props.value === null ? `${styles.body} ${styles.stale}` : styles.body}>
      <span className={styles.label}>{props.label}</span>
      <Chart
        options={options}
        series={[getPercentageGraph(props.value, props.max, props.min)]}
        type="radialBar"
        height={windowDimensions.width <= BREAKPOINT_SMALL ? 120 : 300}
      />
      <div className={styles.minTick}>{props.min}</div>
      <div className={styles.midTickOne}>{midTickOneValue}</div>
      <div className={styles.midTickTwo}>{midTickTwoValue}</div>
      <div className={styles.midTickThree}>{midTickThreeValue}</div>
      <div className={styles.midTickFour}>{midTickFourValue}</div>
      <div className={styles.midTickFive}>{midTickFiveValue}</div>
      <div className={styles.maxTick}>{props.max}</div>
      <EllipsisSpinner loading={props.loading} />
    </div>
  ) : null;
}

Gauge.propTypes = {
  code: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.string,
  time: PropTypes.string,
  unitShortName: PropTypes.string,
  staleAttributeCodes: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

interface Props {
  code: string;
  label: string;
  min: number;
  max: number;
  value: string | null;
  time: string | null;
  unitShortName: string | null;
  staleAttributeCodes: string[];
  loading: boolean;
}
