// --------------------------------------------------------------
// Created On: 2022-06-23
// Author: Zachary Thomas
//
// Last Modified: 2024-06-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import useApi from "../../../../hooks/useApi";
import Spinner from "../../../../components/Spinner/Spinner";
import getApiError from "../../../../utilities/api/getApiError";
import PropTypes from "prop-types";
import DataHistoryControls from "../../../../components/DataHistoryControls/DataHistoryControls";
import { API } from "../../../../constants/miscellaneous";
import { useSelector, useDispatch } from "react-redux";
import { getDeviceAttributes, getCurrentUser } from "../../../../redux/selectors";
import { setDeviceAttributes, toggleDeviceAttribute } from "../../../../redux/actions";
import { useParams } from "react-router-dom";
import styles from "./GraphControls.module.scss";

// Controls for what to display on asset data graph.
export default function GraphControls(props: Props): Component {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const deviceAttributes = useSelector(getDeviceAttributes);
  const { assetId } = useParams<{ assetId: string }>();
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  // Get all valid attributes for each monitoring device.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/attribute`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        // Filter out attributes that are not graphable.
        dispatch(setDeviceAttributes(responseBody.attributes));
      } else {
        setErrorMessage(await getApiError(response, "Unable to get attribute information."));
      }
      setLoading(false);
    },
    [currentUser.companyId, assetId]
  );

  return (
    <Fragment>
      <Spinner loading={loading} />
      {loading ? null : (
        <div className={styles.body}>
          {deviceAttributes.length > 0 && (
            <DataHistoryControls
              startDate={props.startDate}
              endDate={props.endDate}
              mode={props.mode}
              isGraphable={true}
              onChangeStartDate={(startDate) => props.onChangeStartDate(startDate)}
              onChangeEndDate={(endDate) => props.onChangeEndDate(endDate)}
              onChangeMode={(mode) => props.onChangeMode(mode)}
            />
          )}

          <div className="row align-items-center m-0 py-5">
            {deviceAttributes.length > 0 ? (
              <Fragment>
                {deviceAttributes.map((deviceAttribute) => (
                  <div key={deviceAttribute.code} className="col-12 col-lg-4 py-2">
                    <label
                      className={styles.label}
                      onClick={() => dispatch(toggleDeviceAttribute(deviceAttribute.code))}
                    >
                      {deviceAttribute.name}
                    </label>
                    <input
                      className={`form-check-input ${styles.check}`}
                      type="checkbox"
                      checked={deviceAttribute.checked}
                      onChange={() => dispatch(toggleDeviceAttribute(deviceAttribute.code))}
                    />
                  </div>
                ))}
              </Fragment>
            ) : (
              <span className={styles.error}>
                {errorMessage.length > 0
                  ? errorMessage
                  : "The current monitoring device does not support any graphable attributes."}
              </span>
            )}
          </div>

          {deviceAttributes.length > 0 && (
            <div className={styles.buttonContainer}>
              <button className="btn btn-primary" type="button" onClick={() => props.onGraph()}>
                Query Data
              </button>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

GraphControls.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onGraph: PropTypes.func.isRequired,
};

interface Props {
  startDate: string;
  endDate: string;
  mode: string;
  onChangeStartDate: (startDate: string) => void;
  onChangeEndDate: (endDate: string) => void;
  onChangeMode: (mode: string) => void;
  onGraph: () => void;
}

interface ResponseBody {
  attributes: BasicAttribute[];
  error?: string;
}

interface BasicAttribute {
  attributeId: number;
  code: string;
  name: string;
}
