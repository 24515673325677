// --------------------------------------------------------------
// Created On: 2023-05-27
// Author: Zachary Thomas
//
// Last Modified: 2024-08-20
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer, Fragment, useEffect, useMemo } from "react";
import { API, MS_PER_MINUTE, MS_PER_SECOND, MAX_MINUTES_FOR_ACCURATE_READING } from "../../constants/miscellaneous";
import { POWER_TO_PANEL_ATTRIBUTE, ENGINE_RUNNING_ATTRIBUTE, RPM_ATTRIBUTE } from "../../constants/attributes";
import { ASSET_DETAILS_MAX_INACTIVE_USER_SECONDS, ASSET_DETAILS_POLLING_DELAY_SECONDS } from "../../constants/polling";
import isValueBooleanString from "../../utilities/isValueBooleanString";
import formatDateLocal from "../../utilities/time/formatDateLocal";
import { LOCATION_ATTRIBUTE } from "../../constants/attributes";
import { MAINTENANCE_LOG_TYPES } from "../../constants/reducerActions";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import Error404Page from "../Error404Page/Error404Page";
import deepCopy from "../../utilities/deepCopy";
import AssetGraphContainer from "./AssetGraphContainer/AssetGraphContainer";
import LocationMapContainer from "./LocationMapContainer/LocationMapContainer";
import MaintenanceContainer from "./MaintenanceContainer/MaintenanceContainer";
import AssetDataContainer from "./AssetDataContainer/AssetDataContainer";
import RemoteOperationContainer from "./RemoteOperationContainer/RemoteOperationContainer";
import AlertLogContainer from "./AlertLogContainer/AlertLogContainer";
import AttributeHighlights from "./AttributeHighlights/AttributeHighlights";
import isoToLocalDate from "../../utilities/time/isoToLocalDate";
import isoToTimePassed from "../../utilities/time/isoToTimePassed";
import formatDateShortLocal from "../../utilities/time/formatDateShortLocal";
import AssetDetails from "../../components/AssetDetails/AssetDetails";
import isoUtcIsStale from "../../utilities/time/isoUtcIsStale";
import InfoBanner from "../../components/InfoBanner/InfoBanner";
import userIsSuperAdmin from "../../utilities/userIsSuperAdmin";
import DoubleConfirmModal from "../../components/DoubleConfirmModal/DoubleConfirmModal";
import {
  UPDATE_ASSETS_PERMISSION,
  MANAGE_ALERT_THRESHOLDS_PERMISSION,
  MIGRATE_ASSETS_PERMISSION,
} from "../../constants/permissions";
import Error from "../../components/Error/Error";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import userHasPermission from "../../utilities/userHasPermission";
import ProductDataVisual from "./ProductDataVisual/ProductDataVisual";
import apiRequest from "../../utilities/api/apiRequest";
import useLastActive from "../../hooks/useLastActive";

// Page for viewing individual asset information.
//
// NOTE: This is a messy way to handle multiple pages. The structure you see here is due to early development
// limitations on lambda development that required mashing together pages into one API call, then rendering different
// pages with the same call. The ideal solution would be to break up each page.
export default function AssetPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [controllerModel, setControllerModel] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState<number | null>(null);
  const [deviceType, setDeviceType] = useState<string | null>(null);
  const [deviceTypeName, setDeviceTypeName] = useState<string | null>(null);
  const [deviceIdentifier, setDeviceIdentifier] = useState<string | null>(null);
  const [locationAttribute, setLocationAttribute] = useState<Attribute | null>(null);
  const [productType, setProductType] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [productModel, setProduct] = useState<string>("");
  const [productIdentifier, setProductIdentifier] = useState<string>("");
  const [productManufacturer, setProductManufacturer] = useState<string>("");
  const [isRented, setIsRented] = useState<boolean>(false);
  const [operationIsAllowed, setOperationIsAllowed] = useState<boolean>(false);
  const [maintenanceLogs, dispatch] = useReducer(maintenanceLogReducer, []);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [productTypeAttributes, setProductTypeAttributes] = useState<Attribute[]>([]);
  const [controllerAttributes, setControllerAttributes] = useState<Attribute[]>([]);
  const [analogSensorAttributes, setAnalogSensorAttributes] = useState<AnalogAttribute[]>([]);
  const [digitalSensorAttributes, setDigitalSensorAttributes] = useState<Attribute[]>([]);
  const [genericSensorAttributes, setGenericSensorAttributes] = useState<Attribute[]>([]);
  const [isStale, setIsStale] = useState<boolean>(false);
  const [lastComm, setLastComm] = useState<string | null>(null);
  const [isLockedOut, setIsLockedOut] = useState<boolean>(false);
  const [canUnlock, setCanUnlock] = useState<boolean>(false);
  const [isMigrating, setIsMigrating] = useState<boolean>(false);
  const [showMigrateAsset, setShowMigrateAsset] = useState<boolean>(false);
  const [migrationCompleted, setMigrationCompleted] = useState<boolean>(false);
  const [lastConfigSuccessful, setLastConfigSuccessful] = useState<boolean>(true);
  const [lastConfigAttemptUtc, setLastConfigAttemptUtc] = useState<string>("");
  const [attemptedToReconfigure, setAttemptedToReconfigure] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [timerCounter, setTimerCounter] = useState<number>(0);
  const [dataPollCounter, setDataPollCounter] = useState<number>(0);
  const [userPreviouslyInactive, setUserPreviouslyInactive] = useState<boolean>(false);
  const [userJustReturned, setUserJustReturned] = useState<boolean>(false);
  const userInactiveSeconds = useLastActive(ASSET_DETAILS_POLLING_DELAY_SECONDS);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();
  const location = useLocation();
  const isViewingDataPage = location.pathname.includes("data");
  const isViewingOperationPage = location.pathname.includes("operation");
  const staleAttributeCodes = useMemo(() => getStaleAttributeCodes(attributes), [JSON.stringify(attributes)]);
  const staleProductTypeAttributeCodes = useMemo(
    () => getStaleAttributeCodes(productTypeAttributes),
    [JSON.stringify(productTypeAttributes)]
  );
  const staleHighlightAttributeCodes = useMemo(
    () =>
      getStaleAttributeCodes([
        ...controllerAttributes,
        ...analogSensorAttributes,
        ...digitalSensorAttributes,
        ...genericSensorAttributes,
      ]),
    [
      JSON.stringify(controllerAttributes),
      JSON.stringify(analogSensorAttributes),
      JSON.stringify(digitalSensorAttributes),
      JSON.stringify(genericSensorAttributes),
    ]
  );

  // Get asset data from API.
  useApi(
    () => {
      if (dataPollCounter === 0) {
        setLoading(true);
      }
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setName(responseBody.assetName);
        setNickname(responseBody.assetNickname);
        setProduct(responseBody.productModel);
        setProductIdentifier(responseBody.productIdentifier);
        setProductManufacturer(responseBody.productManufacturer);
        setIsRented(responseBody.isRented);
        setControllerModel(responseBody.controllerModel);
        setDeviceId(responseBody.deviceId);
        setDeviceType(responseBody.deviceModel);
        setDeviceTypeName(responseBody.deviceTypeName);
        setDeviceIdentifier(responseBody.deviceIdentifier);
        setProductType(responseBody.productType);
        setOperationIsAllowed(responseBody.operationIsAllowed);
        setAttributes(responseBody.attributes);
        setProductTypeAttributes(responseBody.productTypeAttributes);
        setControllerAttributes(responseBody.controllerAttributes);
        setGenericSensorAttributes(responseBody.genericSensorAttributes);
        setDigitalSensorAttributes(responseBody.digitalSensorAttributes);
        setAnalogSensorAttributes(responseBody.analogSensorAttributes);
        setLastComm(responseBody.lastComm);
        setIsStale(isoUtcIsStale(responseBody.lastComm));
        setIsLockedOut(responseBody.isLockedOut);
        setCanUnlock(responseBody.canUnlock);
        setIsMigrating(responseBody.isMigrating);
        if (responseBody.lastConfigAttemptUtc !== undefined && responseBody.lastConfigAttemptUtc !== null) {
          setLastConfigAttemptUtc(responseBody.lastConfigAttemptUtc);
        }
        if (responseBody.lastConfigSuccessful !== undefined && responseBody.lastConfigSuccessful !== null) {
          setLastConfigSuccessful(responseBody.lastConfigSuccessful);
        }
        dispatch({
          type: MAINTENANCE_LOG_TYPES.SET_MAINTENANCE_LOGS,
          payload: {
            maintenanceLogs: responseBody.maintenanceLogs,
          },
        });

        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [JSON.stringify(currentUser), assetId, migrationCompleted, dataPollCounter]
  );

  // Make a new API call for asset data anytime we go back to the 'asset details - data' page.
  // This is being supported as a limitation of how this page(s) are structured, once separated into multiple pages
  // this will no longer be required.
  useApi(
    () => {
      if (location.pathname === `/asset/${assetId}/data`) {
        if (dataPollCounter === 0) {
          setLoading(true);
        }
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setName(responseBody.assetName);
        setNickname(responseBody.assetNickname);
        setProduct(responseBody.productModel);
        setProductIdentifier(responseBody.productIdentifier);
        setProductManufacturer(responseBody.productManufacturer);
        setIsRented(responseBody.isRented);
        setControllerModel(responseBody.controllerModel);
        setDeviceId(responseBody.deviceId);
        setDeviceType(responseBody.deviceModel);
        setDeviceTypeName(responseBody.deviceTypeName);
        setDeviceIdentifier(responseBody.deviceIdentifier);
        setProductType(responseBody.productType);
        setOperationIsAllowed(responseBody.operationIsAllowed);
        setAttributes(responseBody.attributes);
        setProductTypeAttributes(responseBody.productTypeAttributes);
        setControllerAttributes(responseBody.controllerAttributes);
        setGenericSensorAttributes(responseBody.genericSensorAttributes);
        setDigitalSensorAttributes(responseBody.digitalSensorAttributes);
        setAnalogSensorAttributes(responseBody.analogSensorAttributes);
        setLastComm(responseBody.lastComm);
        setIsStale(isoUtcIsStale(responseBody.lastComm));
        setIsLockedOut(responseBody.isLockedOut);
        setCanUnlock(responseBody.canUnlock);
        setIsMigrating(responseBody.isMigrating);
        if (responseBody.lastConfigAttemptUtc !== undefined && responseBody.lastConfigAttemptUtc !== null) {
          setLastConfigAttemptUtc(responseBody.lastConfigAttemptUtc);
        }
        if (responseBody.lastConfigSuccessful !== undefined && responseBody.lastConfigSuccessful !== null) {
          setLastConfigSuccessful(responseBody.lastConfigSuccessful);
        }
        dispatch({
          type: MAINTENANCE_LOG_TYPES.SET_MAINTENANCE_LOGS,
          payload: {
            maintenanceLogs: responseBody.maintenanceLogs,
          },
        });
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [location, userJustReturned]
  );

  // Periodically poll data to give users a live feed.
  useEffect(() => {
    const newTimerId = setTimeout(() => {
      setTimerCounter((prev) => prev + 1);
      // Only poll data if the user is active.
      console.log(
        `Looking to see if data should be polled` +
          ` (inactive for ${userInactiveSeconds} seconds of ${ASSET_DETAILS_MAX_INACTIVE_USER_SECONDS} second limit).`
      );
      if (userInactiveSeconds < ASSET_DETAILS_MAX_INACTIVE_USER_SECONDS) {
        // Check if the user should be getting polled data.
        if (isViewingDataPage || isViewingOperationPage) {
          setDataPollCounter((prev) => prev + 1);
        }
        setUserPreviouslyInactive(false);
      } else {
        console.log("User is inactive. Data will not be polled.");
        setUserPreviouslyInactive(true);
      }
    }, ASSET_DETAILS_POLLING_DELAY_SECONDS * MS_PER_SECOND);
    return () => {
      clearTimeout(newTimerId);
    };
  }, [timerCounter]);

  // If the user returns to the page after being inactive, trigger polling data immediately instead of waiting for timer.
  useEffect(() => {
    if (userPreviouslyInactive && userInactiveSeconds === 0) {
      setUserJustReturned(true);
    } else {
      setUserJustReturned(false);
    }
  }, [userInactiveSeconds, userPreviouslyInactive]);

  // Perform an asset migration for the current asset.
  async function migrateAsset(): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/migrate`,
      "PUT",
      null
    )) as [Response, MigrateResponseBody];
    setLoading(false);
    if (response.ok) {
      setErrorMessage("");
      setMigrationCompleted(true);
      setShowMigrateAsset(false);
      setDataPollCounter(0);
    } else {
      setErrorMessage("Internal server error. Unable to migrate asset.");
      setShowMigrateAsset(false);
    }
  }

  // Attempts to reconfigure the monitoring device of the current asset.
  async function retryConfiguration(): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/configretry`,
      "POST",
      null
    )) as [Response, MigrateResponseBody];
    setLoading(false);
    if (response.ok) {
      setErrorMessage("");
      setAttemptedToReconfigure(true);
    } else {
      setErrorMessage("Internal server error. Unable to configure asset.");
    }
  }

  // Check if location data was returned, if it was get the date of the most recent reading date.
  useEffect(() => {
    const locationAttribute = attributes.find((attribute) => attribute.attributeCode === LOCATION_ATTRIBUTE);
    if (locationAttribute !== undefined) {
      setLocationAttribute(locationAttribute);
    } else {
      setLocationAttribute(null);
    }
  }, [JSON.stringify(attributes)]);

  // Returns an array of attribute codes that represent attributes with stale data.
  function getStaleAttributeCodes(attributes: Attribute[]): string[] {
    const staleAttributeCodes: string[] = [];
    const nowDate = new Date();

    // Mark attributes as stale if they haven't reported a new reading in a certain amount of time.
    // We do not mark boolean attributes as stale in this way, due to them only sending new readings on state change.
    attributes.forEach((attribute) => {
      if (attribute.currentValue === null || !isValueBooleanString(attribute.currentValue)) {
        const attributeDate = isoToLocalDate(attribute.currentValueUtc);
        const attributeIsStale =
          (nowDate.getTime() - attributeDate.getTime()) / MS_PER_MINUTE > MAX_MINUTES_FOR_ACCURATE_READING;
        if (attributeIsStale) {
          staleAttributeCodes.push(attribute.attributeCode);
        }
      }
    });

    // Some attributes values indicate that other attributes may not have a reliable value.
    // In these cases we will mark attributes as stale if they cannot be trusted.
    const powerToPanelAttribute = attributes.find((attribute) => attribute.attributeCode === POWER_TO_PANEL_ATTRIBUTE);

    const engineRunningAttribute = attributes.find((attribute) => attribute.attributeCode === ENGINE_RUNNING_ATTRIBUTE);

    if (
      !staleAttributeCodes.includes(ENGINE_RUNNING_ATTRIBUTE) &&
      powerToPanelAttribute !== undefined &&
      isValueBooleanString(powerToPanelAttribute.currentValue) &&
      powerToPanelAttribute.currentValue.toLowerCase() === "false"
    ) {
      staleAttributeCodes.push(ENGINE_RUNNING_ATTRIBUTE);
    }

    if (
      !staleAttributeCodes.includes(RPM_ATTRIBUTE) &&
      ((powerToPanelAttribute !== undefined &&
        isValueBooleanString(powerToPanelAttribute.currentValue) &&
        powerToPanelAttribute.currentValue.toLowerCase() === "false") ||
        (engineRunningAttribute !== undefined &&
          isValueBooleanString(engineRunningAttribute.currentValue) &&
          engineRunningAttribute.currentValue.toLowerCase() === "false"))
    ) {
      staleAttributeCodes.push(RPM_ATTRIBUTE);
    }

    return staleAttributeCodes;
  }

  // Sort maintenance logs by date and time.
  function sortMaintenanceLogs(maintenanceLogs: MaintenanceLog[]): MaintenanceLog[] {
    return maintenanceLogs.sort((a, b) => {
      const dateA = a.datePerformed;
      const dateB = b.datePerformed;
      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Maintenance log reducer.
  function maintenanceLogReducer(state: MaintenanceLog[], action: Action): MaintenanceLog[] {
    switch (action.type) {
      case MAINTENANCE_LOG_TYPES.SET_MAINTENANCE_LOGS: {
        // Convert times from UTC to local time.
        if (action.payload.maintenanceLogs !== undefined) {
          let maintenanceLogs = action.payload.maintenanceLogs;
          maintenanceLogs = sortMaintenanceLogs(maintenanceLogs);
          return maintenanceLogs;
        } else {
          return state;
        }
      }

      case MAINTENANCE_LOG_TYPES.CREATE_MAINTENANCE_LOG: {
        let stateShallowCopy = state;

        // Check if the log already exists before creating it.
        if (action.payload.maintenanceLog !== undefined) {
          const newMaintenanceLog = action.payload.maintenanceLog;
          const maintenanceLogExists = stateShallowCopy.some(
            (maintenanceLog) => maintenanceLog.assetMaintenanceLogId === newMaintenanceLog.assetMaintenanceLogId
          );
          if (!maintenanceLogExists) {
            stateShallowCopy.push(newMaintenanceLog);
            stateShallowCopy = sortMaintenanceLogs(stateShallowCopy);
          }
        }

        return stateShallowCopy;
      }

      case MAINTENANCE_LOG_TYPES.UPDATE_MAINTENANCE_LOG: {
        let stateDeepCopy = deepCopy(state);

        if (action.payload.maintenanceLog !== undefined) {
          const updatedMaintenanceLog = action.payload.maintenanceLog;

          const maintenanceLogIndex = state.findIndex(
            (maintenanceLog) => maintenanceLog.assetMaintenanceLogId === updatedMaintenanceLog.assetMaintenanceLogId
          );
          if (maintenanceLogIndex > -1) {
            stateDeepCopy.splice(maintenanceLogIndex, 1, updatedMaintenanceLog);
            stateDeepCopy = sortMaintenanceLogs(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case MAINTENANCE_LOG_TYPES.DELETE_MAINTENANCE_LOG: {
        const stateDeepCopy = deepCopy(state);
        const maintenanceLogIndex = state.findIndex(
          (maintenanceLog) => maintenanceLog.assetMaintenanceLogId === action.payload.assetMaintenanceLogId
        );
        if (maintenanceLogIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(maintenanceLogIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div>
      <Spinner loading={loading} />

      <Routes>
        <Route
          path="/data"
          element={
            <Fragment>
              <Spinner loading={loading} />

              <div className="pt-1" />

              <ProductDataVisual
                assetName={name}
                productType={productType}
                attributes={productTypeAttributes}
                staleAttributeCodes={staleProductTypeAttributeCodes}
              />

              <div className="row justify-content-center mx-0 mb-3">
                <div className="col-12 col-xl-8 px-3">
                  {lastComm !== null && (
                    <InfoBanner
                      message={`This asset reported new data ${isoToTimePassed(lastComm)} ago (${formatDateShortLocal(
                        lastComm
                      )}).`}
                    />
                  )}

                  {isMigrating && (
                    <div className={isStale ? "mt-3" : ""}>
                      <InfoBanner
                        message={
                          "This asset has not completed migration. " +
                          "There are some limitations on configuration, alerting, and remote operation until this migration is complete."
                        }
                      >
                        {userIsSuperAdmin() || (userHasPermission([[MIGRATE_ASSETS_PERMISSION]]) && !isRented) ? (
                          <div className="text-center">
                            <button
                              className="btn btn-primary mt-2"
                              type="button"
                              onClick={() => setShowMigrateAsset(true)}
                            >
                              Migrate Asset
                            </button>
                          </div>
                        ) : null}
                      </InfoBanner>
                    </div>
                  )}

                  {!isMigrating && !lastConfigSuccessful && (
                    <div className={isStale ? "mt-3" : ""}>
                      <InfoBanner
                        message={
                          `This asset failed to update its monitoring device configuration settings on ${formatDateLocal(
                            lastConfigAttemptUtc
                          )}. ` +
                          "This could possibly be due to not being able to establish a stable connection with the physical device. " +
                          `${
                            userHasPermission([[UPDATE_ASSETS_PERMISSION], [MANAGE_ALERT_THRESHOLDS_PERMISSION]])
                              ? "You can attempt to reapply the configuration settings. You will get a notification when the attempt is complete."
                              : ""
                          }`
                        }
                      >
                        {userHasPermission([[UPDATE_ASSETS_PERMISSION], [MANAGE_ALERT_THRESHOLDS_PERMISSION]]) ? (
                          <div className="text-center">
                            <button
                              className="btn btn-primary mx-auto mt-2"
                              type="button"
                              onClick={() => retryConfiguration()}
                              disabled={attemptedToReconfigure}
                            >
                              Retry Configuration
                            </button>
                          </div>
                        ) : null}
                      </InfoBanner>
                    </div>
                  )}

                  {errorMessage.length > 0 && (
                    <div className={isStale || isMigrating ? "mt-3" : ""}>
                      <Error message={errorMessage} />
                    </div>
                  )}
                </div>
              </div>

              {(controllerAttributes.length > 0 ||
                genericSensorAttributes.length > 0 ||
                analogSensorAttributes.length > 0 ||
                digitalSensorAttributes.length > 0 ||
                deviceType !== null) && (
                <div className="row justify-content-center mx-0 mb-4">
                  <div className="col-12 col-xl-8 px-0">
                    <AttributeHighlights
                      controllerType={controllerModel}
                      deviceType={deviceType}
                      deviceTypeName={deviceTypeName}
                      deviceIdentifier={deviceIdentifier}
                      deviceId={deviceId}
                      controllerAttributes={controllerAttributes}
                      genericSensorAttributes={genericSensorAttributes}
                      digitalSensorAttributes={digitalSensorAttributes}
                      analogSensorAttributes={analogSensorAttributes}
                      staleAttributeCodes={staleHighlightAttributeCodes}
                      operationIsAllowed={operationIsAllowed}
                      onDeviceRead={() => {
                        setDataPollCounter(0);
                      }}
                    />
                  </div>
                </div>
              )}

              <AssetDataContainer attributes={attributes} staleAttributeCodes={staleAttributeCodes} />

              <AssetDetails
                assetId={assetId !== undefined ? parseInt(assetId, 10) : assetId}
                name={name}
                nickname={nickname}
                productType={productType}
                productModel={productModel}
                productIdentifier={productIdentifier}
                productManufacturer={productManufacturer}
                deviceId={deviceId}
                deviceType={deviceTypeName}
                deviceIdentifier={deviceIdentifier}
                controllerModel={controllerModel}
                loading={loading}
                isRented={isRented}
                showAssociatedAssetgroups={true}
              />

              <DoubleConfirmModal
                showModal={showMigrateAsset}
                title="Migrate Asset?"
                content={
                  "If migrated, this asset will no longer be available in its current capacity in legacy software. " +
                  "Alert thresholds from legacy software will not be transferred. This action in not reversible! " +
                  "Do you still want to migrate this asset?"
                }
                confirmPin="MIGRATE ASSET"
                confirmText="Migrate Asset"
                onConfirm={() => migrateAsset()}
                onCancel={() => setShowMigrateAsset(false)}
              />
            </Fragment>
          }
        />

        <Route path="/graph" element={<AssetGraphContainer assetName={name} loading={loading} />} />

        <Route
          path="/map"
          element={
            <LocationMapContainer
              assetId={parseInt(assetId || "0", 10)}
              assetName={name}
              locationAttribute={locationAttribute}
            />
          }
        />

        <Route
          path="/operation"
          element={
            <RemoteOperationContainer
              assetName={name}
              deviceId={deviceId}
              operationIsAllowed={operationIsAllowed}
              deviceType={deviceType}
              controllerModel={controllerModel}
              isLockedOut={isLockedOut}
              canUnlock={canUnlock}
              isMigrating={isMigrating}
              lastConfigAttemptUtc={lastConfigAttemptUtc}
              lastConfigSuccessful={lastConfigSuccessful}
              onChangeLock={(isLocked) => setIsLockedOut(isLocked)}
            />
          }
        />

        <Route
          path="/logs"
          element={
            <MaintenanceContainer
              assetName={name}
              isRented={isRented}
              maintenanceLogs={maintenanceLogs}
              attributes={attributes}
              onAction={(action) => dispatch(action)}
            />
          }
        />

        <Route path="/alerts" element={<AlertLogContainer assetName={name} />} />

        <Route path="*" element={<Error404Page />} />
      </Routes>
    </div>
  );
}

interface ResponseBody {
  operationIsAllowed: boolean;
  assetId: number;
  assetName: string;
  assetNickname: string;
  productManufacturer: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  controllerModel: string | null;
  isRented: boolean;
  deviceId: number | null;
  deviceModel: string | null;
  deviceTypeName: string | null;
  deviceIdentifier: string | null;
  pumpCurves: Resource[];
  productManuals: Resource[];
  maintenanceLogs: MaintenanceLog[];
  attributes: Attribute[];
  productTypeAttributes: Attribute[];
  controllerAttributes: Attribute[];
  analogSensorAttributes: AnalogAttribute[];
  digitalSensorAttributes: Attribute[];
  genericSensorAttributes: Attribute[];
  lastComm: string | null;
  isLockedOut: boolean;
  canUnlock: boolean;
  isMigrating: boolean;
  lastConfigAttemptUtc: string;
  lastConfigSuccessful: boolean;
}

interface MigrateResponseBody {
  message: string;
}

interface Resource {
  resourceId: number;
  name: string;
  endpoint: string;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  isHistorical: boolean;
}

interface AnalogAttribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  isHistorical: boolean;
  isConnected: boolean | null;
}

interface MaintenanceLog {
  assetMaintenanceLogId: number;
  workPerformed: string;
  workPerformedBy: string;
  datePerformed: string;
  currentServiceRuntime: string | null;
  vibrationBasedRuntime: string | null;
  engineRuntime: string | null;
  cost: string;
  note: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assetMaintenanceLogId?: number;
  maintenanceLog?: MaintenanceLog;
  maintenanceLogs?: MaintenanceLog[];
}
