// --------------------------------------------------------------
// Created On: 2022-07-28
// Author: Zachary Thomas
//
// Last Modified: 2024-06-17
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Warning from "../../../components/Warning/Warning";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import AssetDetails from "../../../components/AssetDetails/AssetDetails";
import { API, RPM_COMPANY, TESTING_NEW_FEATURE } from "../../../constants/miscellaneous";
import { LENT_ASSET_TYPES } from "../../../constants/reducerActions";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import userHasPermission from "../../../utilities/userHasPermission";
import { REVOKE_ASSET_RENTALS_PERMISSION } from "../../../constants/permissions";
import styles from "./RentOutAssetModal.module.scss";

// Modal for renting out assets to companies or for viewing assets rented to other companies.
export default function RentOutAssetModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailMessage, setEmailMessage] = useState("");
  const [asset, setAsset] = useState<DetailedAsset | null>(null);
  const [companyName, setCompanyName] = useState<string>("");
  const [allowRemoteOperation, setAllowRemoteOperation] = useState<boolean>(false);
  const [allowAlertModification, setAllowAlertModification] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);

  // If an asset is already selected, search for asset details to display.
  useEffect(() => {
    if (!props.isCreatingNewRecord) {
      searchAssets(props.asset.name);
    }
  }, [props.isCreatingNewRecord, JSON.stringify(props.asset)]);

  // Check that the selected asset is ready to be rented.
  function lendAssetIsValid(): boolean {
    if (companyName.length === 0) {
      setErrorMessage("You must enter an email address that is associated with an RPM Squared account.");
      return false;
    }

    if (companyName === RPM_COMPANY) {
      setErrorMessage("You cannot rent assets to users in the RPM Squared company.");
      return false;
    }

    if (asset === null) {
      setErrorMessage("You must select an asset to rent to a company.");
      return false;
    }

    return true;
  }

  // Lend an asset to another company.
  async function lendAsset(): Promise<void> {
    if (lendAssetIsValid() && asset !== null) {
      const requestBody = {
        assetId: asset.assetId,
        emailAddress: email,
        operateAccessPermission: allowRemoteOperation,
        alertThresholdPermission: allowAlertModification,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/rental`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const lentAsset = {
          assetId: asset.assetId,
          name: asset.name,
          renteeCompanyId: responseBody.renteeCompanyId,
          renteeCompanyName: responseBody.renteeCompanyName,
          operateAccessPermission: allowRemoteOperation,
          alertThresholdPermission: allowAlertModification,
          isPendingApproval: true,
        };

        props.onAction({
          type: LENT_ASSET_TYPES.CREATE_ASSET,
          payload: {
            asset: lentAsset,
          },
        });

        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to rent asset."));
      }
    }
  }

  // Cancel / revoke a lent asset.
  async function deleteLentAsset(assetId: number, isPendingApproval: boolean): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/rental/${isPendingApproval ? "cancel" : "revoke"}/${assetId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: LENT_ASSET_TYPES.DELETE_ASSET,
        payload: {
          assetId: assetId,
          companyId: props.asset.renteeCompanyId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to revoke asset."));
    }
  }

  // Search for company by email.
  async function searchCompaniesByEmail(email: string): Promise<void> {
    setLoading(true);
    const [response, responseBody] = (await apiRequest(`${API}/userexists/${email}`, "GET", null)) as [
      Response,
      EmailResponseBody
    ];
    setLoading(false);
    setErrorMessage("");
    if (response.ok && responseBody) {
      if (responseBody.companyName === RPM_COMPANY) {
        setCompanyName("");
        setEmailMessage(`Assets cannot be rented out to accounts in the '${RPM_COMPANY}' company.`);
      } else {
        if (responseBody.isHidden) {
          setEmailMessage(`This email address is valid. This asset will be rented to the owner of this email address`);
          setCompanyName(responseBody.companyName);
        } else {
          setEmailMessage(
            `This email address is valid. This asset will be rented to the '${responseBody.companyName}' company`
          );
          setCompanyName(responseBody.companyName);
        }
      }
    } else {
      setCompanyName("");
      setEmailMessage(
        "This email address either is not associated with an RPM Squared account or is not authorized to receive asset rentals."
      );
    }
  }

  // Search for a matching asset.
  async function searchAssets(filter: string): Promise<void> {
    setFilter(filter);
    setErrorMessage("");
    const foundAsset = props.assets.find((asset) => asset.name === filter);

    if (foundAsset === undefined) {
      setAsset(null);
    } else {
      // Get asset information.
      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${foundAsset.assetId}/configuration`,
        "GET",
        null
      )) as [Response, GetResponseBody];
      setLoading(false);
      setErrorMessage("");
      if (response.ok && responseBody) {
        setAsset(responseBody);
        if (!responseBody.operateAccessPermission) {
          setAllowRemoteOperation(false);
        }
        if (!responseBody.alertThresholdPermission) {
          setAllowAlertModification(false);
        }
      } else {
        setAsset(null);
      }
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {
    if (!props.isCreatingNewRecord || (asset === null && email.length === 0)) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges() {
    lendAsset();
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function discardChanges() {
    setShowConfirmExit(false);
    setShowConfirmDelete(false);
    setAsset(null);
    setCompanyName("");
    setEmail("");
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.isCreatingNewRecord ? "Rent Out Asset" : "View Asset"}</h5>
        </ModalHeader>

        <ModalBody>
          <div className={`${styles.switch} my-4`}>
            {props.isCreatingNewRecord && (
              <div className="form-check form-switch ms-2">
                <input
                  data-test="rent-out-remote-operation-checkbox"
                  className="form-check-input me-3"
                  type="checkbox"
                  checked={allowRemoteOperation}
                  disabled={!props.isCreatingNewRecord || (asset !== null && !asset.operateAccessPermission)}
                  onChange={() => setAllowRemoteOperation((prev) => !prev)}
                />
                {allowRemoteOperation ? (
                  <label onClick={() => setAllowRemoteOperation((prev) => !prev)}>
                    Remote operation is enabled for rentee.
                  </label>
                ) : (
                  <label onClick={() => setAllowRemoteOperation((prev) => !prev)}>
                    Remote operation is disabled for rentee.
                  </label>
                )}
              </div>
            )}
          </div>

          {TESTING_NEW_FEATURE && (
            <div className={`${styles.switch} my-4`}>
              {props.isCreatingNewRecord && (
                <div className="form-check form-switch ms-2">
                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    checked={allowAlertModification}
                    disabled={!props.isCreatingNewRecord || (asset !== null && !asset.operateAccessPermission)}
                    onChange={() => setAllowAlertModification((prev) => !prev)}
                  />
                  {allowAlertModification ? (
                    <label>Alert threshold configuration is enabled for rentee.</label>
                  ) : (
                    <label>Alert threshold configuration is disabled for rentee.</label>
                  )}
                </div>
              )}
            </div>
          )}

          {props.isCreatingNewRecord && (
            <div className="mx-2 mb-2">
              <label className="mb-3 font-weight-bold">Enter borrowers email address</label>
              <input
                data-test="rent-out-email-input"
                className="form-control mb-1 mx-auto"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => searchCompaniesByEmail(email)}
              />
              <p className={`${companyName.length === 0 ? styles.error : styles.success} mb-4`}>{emailMessage}</p>

              <label className="mb-3 font-weight-bold">Search for asset to rent</label>
              <input
                data-test="rent-out-asset-input"
                className="form-control mb-4 mx-auto"
                type="text"
                list="datalist-assets"
                autoComplete="off"
                value={filter}
                onChange={(e) => searchAssets(e.target.value)}
              />
              <datalist id="datalist-assets">
                {props.assets.map((asset) => (
                  <option key={asset.assetId} value={asset.name} />
                ))}
              </datalist>
            </div>
          )}

          <Fragment>
            {!props.isCreatingNewRecord && (
              <p className="text-center mt-3">
                Asset is rented to &apos;<b>{props.asset.renteeCompanyName}</b>&apos;.
                <br />
                {props.asset.operateAccessPermission
                  ? "Remote operation is enabled for rentee."
                  : "Remote operation is disabled for rentee."}
                <br />
                {TESTING_NEW_FEATURE && (
                  <Fragment>
                    {props.asset.alertThresholdPermission
                      ? "Alert threshold configuration is enabled for rentee."
                      : "Alert threshold configuration is disabled for rentee."}
                  </Fragment>
                )}
                {props.asset.isPendingApproval && <br />}
                {props.asset.isPendingApproval ? "This asset is pending approval from the rentee." : ""}
              </p>
            )}

            {asset !== null && (
              <AssetDetails
                name={asset.name}
                nickname={asset.nickname}
                productModel={asset.productModel}
                productIdentifier={asset.productIdentifier}
                productManufacturer={asset.productManufacturer}
                deviceType={asset.deviceTypeName}
                deviceIdentifier={asset.deviceIdentifier}
                controllerModel={asset.controllerName}
                loading={false}
                isRented={false}
              />
            )}
          </Fragment>

          {(errorMessage.length > 0 || (asset !== null && !asset.operateAccessPermission)) && (
            <div className="row">
              <div className="col mt-4 mx-2">
                {asset !== null && !asset.operateAccessPermission && (
                  <Warning
                    message={
                      "You are renting this asset without being provided remote operation access. You will not be able to provide remote operation access to others."
                    }
                  />
                )}
                {TESTING_NEW_FEATURE && (
                  <Fragment>
                    {asset !== null && !asset.alertThresholdPermission && (
                      <Warning
                        message={
                          "You are renting this asset without being provided alert threshold configuration. You will not be able to provide alert threshold configuration to others."
                        }
                      />
                    )}
                  </Fragment>
                )}
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.isCreatingNewRecord ? (
            <Fragment>
              <button
                data-test="rent-out-rent-asset-button"
                className={`${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => lendAsset()}
              >
                Rent Asset
              </button>

              <button
                data-test="rent-out-cancel-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {userHasPermission([[REVOKE_ASSET_RENTALS_PERMISSION]]) && (
                <button
                  data-test="rent-out-cancel-revoke-button"
                  className={`${styles.btn} btn btn-danger me-auto" type="button`}
                  onClick={() => setShowConfirmDelete(true)}
                >
                  {props.asset.isPendingApproval ? "Cancel Request" : "Revoke Asset"}
                </button>
              )}

              <button className={`${styles.btn} btn btn-secondary`} type="button" onClick={() => exitModal()}>
                Close
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title={props.asset.isPendingApproval ? "Cancel Request" : `Revoke '${props.asset.name}'`}
        content={
          props.asset.isPendingApproval
            ? "Are you sure that you want to cancel this request?"
            : `Are you sure that you want to revoke '${props.asset.name}'? This will prevent '${props.asset.renteeCompanyName}' from viewing this asset.`
        }
        yesText={props.asset.isPendingApproval ? "Cancel Request" : "Revoke Asset"}
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteLentAsset(props.asset.assetId, props.asset.isPendingApproval)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

RentOutAssetModal.propTypes = {
  isCreatingNewRecord: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  isCreatingNewRecord: boolean;
  asset: RentedOutAsset;
  assets: OwnedAsset[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface RentedOutAsset {
  assetId: number;
  name: string;
  renteeCompanyId: number;
  renteeCompanyName: string;
  operateAccessPermission: boolean;
  alertThresholdPermission: boolean;
  isPendingApproval: boolean;
}

interface OwnedAsset {
  assetId: number;
  name: string;
}

interface DetailedAsset {
  assetId: number;
  name: string;
  nickname: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  deviceType: string;
  deviceTypeName: string;
  deviceIdentifier: string;
  controllerModel: string;
  controllerName: string;
  alertThresholdPermission: boolean;
  operateAccessPermission: boolean;
}

interface GetResponseBody {
  assetId: number;
  name: string;
  nickname: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  deviceType: string;
  deviceTypeName: string;
  deviceIdentifier: string;
  controllerModel: string;
  controllerName: string;
  operateAccessPermission: boolean;
  alertThresholdPermission: boolean;
}

interface PostResponseBody {
  renteeCompanyId: number;
  renteeCompanyName: string;
  error?: string;
}

interface DeleteResponseBody {
  error?: string;
}

interface EmailResponseBody {
  isHidden: string;
  companyName: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assets?: RentedOutAsset[];
  asset?: RentedOutAsset;
  assetId?: number;
  companyId?: number;
}
