// --------------------------------------------------------------
// Created On: 2022-05-25
// Author: Zachary Thomas
//
// Last Modified: 2024-05-14
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import styles from "./IconTooltip.module.scss";

// An icon that displays a message when hovered over.
export default function IconTooltip(props: Props): Component {
  return (
    <Fragment>
      <i className={`fa fa-fw fa-${props.icon}`} style={{ color: props.color }} data-for={`tt-${props.id}`} data-tip />

      <ReactTooltip id={`tt-${props.id}`} place="top" effect="solid" className={styles.body}>
        {props.message}
      </ReactTooltip>
    </Fragment>
  );
}

IconTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

interface Props {
  id: string;
  icon: string;
  message: string;
  color: string;
}
