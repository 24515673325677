// --------------------------------------------------------------
// Created On: 2023-06-23
// Author: Zachary Thomas
//
// Last Modified: 2023-08-29
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PUMP_MAX_FRAMES, PUMP_MS_BETWEEN_FRAMES } from "../../../../constants/productVisuals";
import {
  RPM_ATTRIBUTE,
  SUCTION_PRESSURE_ATTRIBUTE,
  DISCHARGE_PRESSURE_ATTRIBUTE,
} from "../../../../constants/attributes";
import styles from "./CentrifugalPumpVisual.module.scss";

// A data visualization for a centrifugal pump.
export default function CentrifugalPumpVisual(props: Props): Component {
  const [currentFrame, setCurrentFrame] = useState<number>(1);

  // Loop through all frames of animation as time passes.
  useEffect(() => {
    // Get the next frame of animation.
    function getNextFrame(currentFrame: number, pumpRunning: boolean): number {
      if (pumpRunning) {
        if (currentFrame + 1 > PUMP_MAX_FRAMES) {
          return 1;
        } else {
          return currentFrame + 1;
        }
      } else {
        // If the pump is not running, don't bother getting the next frame of animation.
        return currentFrame;
      }
    }

    const newTimerId = setTimeout(
      () =>
        setCurrentFrame((prev) =>
          getNextFrame(prev, getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes))
        ),
      PUMP_MS_BETWEEN_FRAMES
    );

    return () => {
      clearTimeout(newTimerId);
    };
  }, [currentFrame, JSON.stringify(props.attributes), JSON.stringify(props.staleAttributeCodes)]);

  // Get the state of relevant attributes and decide if the pump is running.
  function getPumpIsRunningStatus(attributes: Attribute[], staleAttributeCodes: string[]): boolean {
    // Check if listed attributes exist.
    const rpmAttribute = attributes.find((attribute) => attribute.attributeCode === RPM_ATTRIBUTE);
    if (
      rpmAttribute !== undefined &&
      parseInt(rpmAttribute.currentValue, 10) > 0 &&
      !staleAttributeCodes.includes(RPM_ATTRIBUTE)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="row g-0">
      <div className={`${styles.svgContent} col-12 col-lg-6`}>
        <img
          className={styles.base}
          src="/productVisuals/centrifugalPump/centrifugalPump_base.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.impellerOn
              : styles.impellerOff
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_impeller.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={styles.suction}
          src="/productVisuals/centrifugalPump/centrifugalPump_suction.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={styles.topHide}
          src="/productVisuals/centrifugalPump/centrifugalPump_hide_ta.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={styles.bottomHide}
          src="/productVisuals/centrifugalPump/centrifugalPump_hide_ba.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={styles.eye}
          src="/productVisuals/centrifugalPump/centrifugalPump_eye.svg"
          alt="Pump Data Visualization"
        />
        {/* Hover effects. */}
        <img
          className={
            props.hoverCode === DISCHARGE_PRESSURE_ATTRIBUTE
              ? styles.dischargeHover
              : styles.hiddenHover
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_hoverDischarge.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            props.hoverCode === SUCTION_PRESSURE_ATTRIBUTE
              ? styles.suctionHover
              : styles.hiddenHover
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_hoverSuction.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={props.hoverCode === RPM_ATTRIBUTE ? styles.rpmHover : styles.hiddenHover}
          src="/productVisuals/centrifugalPump/centrifugalPump_hoverRpm.svg"
          alt="Pump Data Visualization"
        />
        {/* Suction animation. */}
        <img
          className={
            currentFrame === 1 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta1.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 2 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta2.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 3 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta3.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 4 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta4.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 5 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta5.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 6 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta6.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 7 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta7.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 8 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta8.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 9 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta9.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 10 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.topArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ta10.svg"
          alt="Pump Data Visualization"
        />
        {/* Discharge animation. */}
        <img
          className={
            currentFrame === 1 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba1.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 2 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba2.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 3 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba3.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 4 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba4.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 5 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba5.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 6 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba6.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 7 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba7.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 8 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba8.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 9 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba9.svg"
          alt="Pump Data Visualization"
        />
        <img
          className={
            currentFrame === 10 &&
            getPumpIsRunningStatus(props.attributes, props.staleAttributeCodes)
              ? styles.bottomArrowVisible
              : styles.arrowHidden
          }
          src="/productVisuals/centrifugalPump/centrifugalPump_ba10.svg"
          alt="Pump Data Visualization"
        />
      </div>
    </div>
  );
}

CentrifugalPumpVisual.propTypes = {
  attributes: PropTypes.array.isRequired,
  hoverCode: PropTypes.string,
  staleAttributeCodes: PropTypes.array.isRequired,
};

interface Props {
  attributes: Attribute[];
  hoverCode: string | null;
  staleAttributeCodes: string[];
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  latitude?: number;
  longitude?: number;
  icon?: string;
  isHistorical?: boolean;
}
