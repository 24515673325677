// --------------------------------------------------------------
// Created On: 2021-05-18
// Author: Zachary Thomas
//
// Last Modified: 2024-02-02
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import isoUtcIsStale from "../../../../utilities/time/isoUtcIsStale";
import isoToTimePassed from "../../../../utilities/time/isoToTimePassed";
import styles from "./AssetListItem.module.scss";

// A single asset link in a collection.
export default function AssetListItem(props: Props): Component {
  const isStale = useMemo(() => isoUtcIsStale(props.lastComm), [props.lastComm]);
  const navigate = useNavigate();

  return (
    <tr
      className={`${styles.item} ${isStale ? styles.stale : ""}`}
      onClick={() => navigate(`/asset/${props.assetId}/data`)}
    >
      <td>
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.name}{" "}
        </Link>
      </td>

      <td>
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.deviceType === null ? <span>N/A</span> : <span>{props.deviceType}</span>}
        </Link>
      </td>

      <td>
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.deviceIdentifier === null ? <span>N/A</span> : <span>{props.deviceIdentifier}</span>}
        </Link>
      </td>

      {props.attributes.map((attribute, i) => (
        <td
          key={attribute.attributeId}
          style={attribute.colorHexCode !== undefined ? { backgroundColor: attribute.colorHexCode } : {}}
        >
          <Link to={`/asset/${props.assetId}/data`} className="router-link">
            {attribute.value === null ? (
              <span>N/A</span>
            ) : (
              <span>
                {attribute.value} {props.units[i]}
              </span>
            )}
          </Link>
        </td>
      ))}

      <td>
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.lastComm === null ? <span>N/A</span> : <span>{isoToTimePassed(props.lastComm)} ago</span>}
        </Link>
      </td>

      <td className={styles.printCell}>
        <Link to={`/asset/${props.assetId}/data`} className="router-link" />
      </td>
    </tr>
  );
}

AssetListItem.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string,
  deviceType: PropTypes.string,
  deviceIdentifier: PropTypes.string,
  attributes: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,
  lastComm: PropTypes.string,
};

interface Props {
  assetId: number;
  name: string;
  nickname: string | null;
  deviceType: string | null;
  deviceIdentifier: string | null;
  attributes: ViewAssetAttribute[];
  units: string[];
  lastComm: string | null;
}

interface ViewAssetAttribute {
  attributeId: number;
  value: string | boolean | number;
  colorHexCode?: string;
}
