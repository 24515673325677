// --------------------------------------------------------------
// Created On: 2021-11-23
// Author: Zachary Thomas
//
// Last Modified: 2024-07-01
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import isoUtcToIsoLocal from "../../../utilities/time/isoUtcToIsoLocal";
import PropTypes from "prop-types";
import Widget from "../../../components/Widget/Widget";
import DataHistoryModal from "./DataHistoryModal/DataHistoryModal";
import DataPoint from "./DataPoint/DataPoint";

// Detailed display of asset data.
export default function AssetDataContainer(props: Props): Component {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [attributeName, setAttributeName] = useState<string>("");
  const [attributeCode, setAttributeCode] = useState<string>("");
  const [attributeDate, setAttributeDate] = useState<string | null>(null);
  const [isGraphable, setIsGraphable] = useState<boolean>(true);

  // Show historical data for the individual data attribute.
  function showHistoricalData(
    name: string,
    code: string,
    isHistorical: boolean,
    isGraphable: boolean,
    mostRecentUtc?: string
  ): void {
    if (isHistorical) {
      setShowModal(true);
      setAttributeName(name);
      setAttributeCode(code);
      setIsGraphable(isGraphable);
      // We will want to get the date, without the time of day, in the local time zone.
      if (typeof mostRecentUtc === "string") {
        const mostRecentLocal = isoUtcToIsoLocal(mostRecentUtc);
        const mostRecentSections = mostRecentLocal.split("T");
        setAttributeDate(mostRecentSections[0]);
      } else {
        setAttributeDate(null);
      }
    }
  }

  return (
    <div data-test="asset-data-container" className="px-4 pb-4">
      <div className="row justify-content-center">
        <div className="col-12 col-xl-8">
          <div className="row">
            {props.attributes.map((attribute) => (
              <Fragment key={attribute.attributeCode}>
                <div className={props.attributes.length > 1 ? "col-12 col-lg-6 p-2" : "col-12 p-2"}>
                  <Widget extendClass="w-100">
                    <DataPoint
                      icon={attribute.icon || "question"}
                      name={attribute.attributeName}
                      code={attribute.attributeCode}
                      value={attribute.currentValue}
                      unit={attribute.unitShortName || ""}
                      mostRecentUtc={attribute.currentValueUtc}
                      isHistorical={attribute.isHistorical || false}
                      isGraphable={attribute.isGraphable || false}
                      isStale={props.staleAttributeCodes.includes(attribute.attributeCode)}
                      onClick={(name, code, isHistorical, isGraphable, mostRecentUtc) =>
                        showHistoricalData(name, code, isHistorical, isGraphable, mostRecentUtc)
                      }
                    />
                  </Widget>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>

      <DataHistoryModal
        showModal={showModal}
        isGraphable={isGraphable}
        name={attributeName}
        code={attributeCode}
        date={attributeDate}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

AssetDataContainer.propTypes = {
  attributes: PropTypes.array.isRequired,
  staleAttributeCodes: PropTypes.array.isRequired,
};

interface Props {
  attributes: Attribute[];
  staleAttributeCodes: string[];
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  latitude?: number;
  longitude?: number;
  icon?: string;
  isHistorical?: boolean;
  isGraphable?: boolean;
}
