// --------------------------------------------------------------
// Created On: 2021-12-29
// Author: Zachary Thomas
//
// Last Modified: 2024-05-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState, useEffect } from "react";
import {
  API,
  CUSTOMER_SERVICE_EMAIL,
  CUSTOMER_SERVICE_PHONE,
  CUSTOMER_SERVICE_OVERRIDE_MESSAGE,
  CREATE_MODE,
} from "../../constants/miscellaneous";
import DeviceSubscriptionRow from "./DeviceSubscriptionRow/DeviceSubscriptionRow";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import Card from "../../components/Card/Card";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import useApi from "../../hooks/useApi";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import AssetModal from "../../components/AssetModal/AssetModal";
import FilteredTableContainer from "../../components/FilteredTableContainer/FilteredTableContainer";
import SortedTableHeader from "../../components/SortedTableHeader/SortedTableHeader";
import deepCopy from "../../utilities/deepCopy";
import styles from "./DeviceSubscriptionPage.module.scss";

// Page for viewing device subscription settings.
export default function DeviceSubscriptionPage(): Component {
  const currentUser = useSelector(getCurrentUser);
  const initialAsset: AssetConfiguration = {
    assetId: 0,
    name: "",
    nickname: "",
    productType: "",
    productModel: "",
    productIdentifier: "",
    productManufacturer: "",
    controllerModel: null,
    controllerManufacturer: null,
    deviceType: null,
    deviceIdentifier: null,
    isRented: false,
    analogSensors: [],
    digitalSensors: [],
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [controllers, setControllers] = useState<Controller[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [productManufacturers, setProductManufacturers] = useState<string[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [subscriptions, setSubscriptions] = useState<MonitoringDeviceSubscription[]>([]);
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(1);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<MonitoringDeviceSubscription[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [analogSensors, setAnalogSensors] = useState<AnalogSensor[]>([]);
  const [digitalSensors, setDigitalSensors] = useState<DigitalSensor[]>([]);
  const [newAsset, setNewAsset] = useState<AssetConfiguration>(initialAsset);

  // Get device subscription info.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/device`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setSubscriptions(sortSubscriptions(responseBody.monitoringDevices));
        setControllers(responseBody.controllers);
        setDeviceTypes(responseBody.deviceTypes);
        setProductManufacturers(responseBody.productManufacturers);
        setProductTypes(responseBody.productTypes);
        setAnalogSensors(responseBody.analogSensors);
        setDigitalSensors(responseBody.digitalSensors);
        setFilter("");
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Apply the filter to the list of subscriptions.
  useEffect(() => {
    if (filter.length > 0) {
      const matchedSubscriptions = subscriptions.filter(
        (subscription) => subscription.deviceIdentifier.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      );

      setFilteredSubscriptions(matchedSubscriptions);
    } else {
      setFilteredSubscriptions(subscriptions);
    }
  }, [JSON.stringify(subscriptions), filter]);

  // Open an asset creation modal and assign the selected monitoring device.
  function openCreationModal(deviceIdentifier: string, deviceType: string): void {
    const initialAssetDeepCopy = deepCopy(initialAsset);
    initialAssetDeepCopy.deviceType = deviceType;
    initialAssetDeepCopy.deviceIdentifier = deviceIdentifier;
    setNewAsset(initialAssetDeepCopy);
    setShowModal(true);
  }

  // Create the new asset and display it as part of the subscription.
  function createAsset(action: Action): void {
    const newAsset = action.payload.asset;
    if (newAsset === undefined) {
      return;
    }

    // Get each monitoring device that belongs to the asset and update them in the list.
    const subscriptionsDeepCopy = deepCopy(subscriptions);

    const index = subscriptionsDeepCopy.findIndex(
      (subscription) =>
        subscription.deviceType === newAsset.deviceType && subscription.deviceIdentifier === newAsset.deviceIdentifier
    );

    if (index > -1) {
      subscriptionsDeepCopy[index].assetId = newAsset.assetId;
      subscriptionsDeepCopy[index].assetName = newAsset.name;
    }

    setSubscriptions(subscriptionsDeepCopy);
  }

  // Update sorting order.
  function updateSort(newIndex: number): void {
    if (newIndex === sortColumnIndex) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(false);
      setSortColumnIndex(newIndex);
    }
  }

  // When the sorting rules change, re-sort all attributes.
  useEffect(() => {
    const subscriptionsDeepCopy = deepCopy(subscriptions);
    setSubscriptions(sortSubscriptions(subscriptionsDeepCopy));
  }, [sortAscending, sortColumnIndex]);

  // Sort subscriptions based on the current column and ascension rule.
  function sortSubscriptions(attributes: MonitoringDeviceSubscription[]): MonitoringDeviceSubscription[] {
    return attributes.sort((a, b) => {
      let aValue = "";
      let bValue = "";

      switch (sortColumnIndex) {
        case 1:
          aValue = a.deviceIdentifier;
          bValue = b.deviceIdentifier;
          break;
        case 2:
          aValue = a.deviceType;
          bValue = b.deviceType;
          break;
        case 3:
          aValue = a.assetName || "";
          bValue = b.assetName || "";
          break;
        case 4:
          aValue = a.subscriptionType;
          bValue = b.subscriptionType;
          break;
        case 5:
          aValue = a.subscriptionNumber || "";
          bValue = b.subscriptionNumber || "";
          break;
        case 6:
          aValue = a.subscriptionStartDate || "";
          bValue = b.subscriptionStartDate || "";
          break;
        case 7:
          aValue = a.subscriptionExpiry || "";
          bValue = b.subscriptionExpiry || "";
          break;
        default:
          aValue = a.deviceIdentifier;
          bValue = b.deviceIdentifier;
      }

      if (aValue < bValue) {
        if (sortAscending) {
          return -1;
        } else {
          return 1;
        }
      } else if (aValue > bValue) {
        if (sortAscending) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    });
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      {subscriptions.length > 0 ? (
        <FilteredTableContainer
          title={`Monitoring Device Subscriptions (${filteredSubscriptions.length})`}
          filterPrompt="Filter by device identifier..."
          filter={filter}
          hasContent={subscriptions.length > 0}
          hasFilteredContent={filteredSubscriptions.length > 0}
          pluralContentType="devices"
          onChangeFilter={(filter) => setFilter(filter)}
        >
          <table className="table table-hover mb-0 pb-0">
            <thead className={styles.header}>
              <tr>
                <SortedTableHeader
                  name="Device Identifier"
                  index={1}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Device Type"
                  index={2}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Asset"
                  index={3}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Subscription Type"
                  index={4}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Subscription Number"
                  index={5}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Start Date"
                  index={6}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="End Date"
                  index={7}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
              </tr>
            </thead>
            <tbody>
              {filteredSubscriptions.map((subscription, i) => (
                <DeviceSubscriptionRow
                  key={i}
                  deviceIdentifier={subscription.deviceIdentifier}
                  deviceType={subscription.deviceType}
                  deviceTypeName={subscription.deviceTypeName}
                  subscriptionType={subscription.subscriptionType}
                  subscriptionNumber={subscription.subscriptionNumber}
                  subscriptionStartDate={subscription.subscriptionStartDate}
                  subscriptionExpiry={subscription.subscriptionExpiry}
                  assetId={subscription.assetId}
                  assetName={subscription.assetName}
                  onOpenModal={(deviceIdentifier, deviceType) => openCreationModal(deviceIdentifier, deviceType)}
                />
              ))}
            </tbody>
          </table>
        </FilteredTableContainer>
      ) : (
        <Fragment>
          {!loading && (
            <div className="col-12 col-xl-10 col-xxl-8 offset-xl-1 offset-xxl-2">
              <Card title="Devices Not Found">
                <div className="my-5">
                  <TextBlurb
                    title="No monitoring devices are registered with this company"
                    paragraph={
                      CUSTOMER_SERVICE_OVERRIDE_MESSAGE === null
                        ? `If you believe this is an error, please contact customer service by email at ${CUSTOMER_SERVICE_EMAIL}` +
                          ` or by phone at ${CUSTOMER_SERVICE_PHONE}.`
                        : `If you believe this is an error, ${CUSTOMER_SERVICE_OVERRIDE_MESSAGE}`
                    }
                    icon="exclamation-triangle"
                  />
                </div>
              </Card>
            </div>
          )}
        </Fragment>
      )}

      <AssetModal
        assetId={0}
        mode={CREATE_MODE}
        showModal={showModal}
        initialAsset={newAsset}
        deviceTypes={deviceTypes}
        controllers={controllers}
        productTypes={productTypes}
        productManufacturers={productManufacturers}
        analogSensors={analogSensors}
        digitalSensors={digitalSensors}
        onClose={() => setShowModal(false)}
        onAction={(action: Action) => createAsset(action)}
      />
    </div>
  );
}

interface ResponseBody {
  monitoringDevices: MonitoringDeviceSubscription[];
  controllers: Controller[];
  deviceTypes: DeviceType[];
  productManufacturers: string[];
  productTypes: ProductType[];
  analogSensors: AnalogSensor[];
  digitalSensors: DigitalSensor[];
}

interface DeviceType {
  deviceTypeId: number;
  model: string;
  name: string;
}

interface Controller {
  controllerId: number;
  controllerModel: string | null;
  manufacturerCompanyCode: string | null;
  name: string;
}

interface AnalogSensor {
  analogSensorId: number;
  name: string;
  applications: AnalogApplication[];
}

interface AnalogApplication {
  attributeId: number;
  attributeConnectedId: number;
  name: string;
}

interface DigitalSensor {
  digitalSensorId: number;
  name: string;
  applications: DigitalApplication[];
}

interface DigitalApplication {
  attributeStateId: number;
  attributeFlowId: number;
  attributeAccumulationId: number;
  reportingDescription: string;
}

interface AssetConfiguration {
  assetId: number;
  name: string;
  nickname: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  controllerModel: string | null;
  controllerManufacturer: string | null;
  deviceType: string | null;
  deviceIdentifier: string | null;
  isRented: boolean;
  analogSensors: AnalogSensorSelection[];
  digitalSensors: DigitalSensorSelection[];
}

interface AnalogSensorSelection {
  analogSensorId: number;
  attributeCode: string;
  attributeConnectedCode: string;
  attributeModeCode: string;
  attributeMinCode: string;
  attributeSpanCode: string;
  mappedAttributeId: number;
  mappedAttributeConnectedId: number;
}

interface DigitalSensorSelection {
  digitalSensorId: number;
  attributeStateCode: string;
  attributeFlowCode: string;
  attributeAccumulationCode: string;
  attributeModeCode: string;
  attributeKFactorCode: string;
  attributeOffsetCode: string;
  attributeDebounceCode: string;
  attributeUnitsPerPulseCode: string;
  mappedAttributeStateId: number;
  mappedAttributeFlowId: number;
  mappedAttributeAccumulationId: number;
  reportingDescription: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  asset?: Asset;
  assetId?: number;
}

interface MonitoringDeviceSubscription {
  deviceId: number;
  deviceType: string;
  deviceTypeName: string;
  deviceIdentifier: string;
  assetId: number;
  assetName: string;
  subscriptionType: string;
  subscriptionNumber: string | null;
  subscriptionStartDate: string | null;
  subscriptionExpiry: string | null;
}
