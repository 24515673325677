// --------------------------------------------------------------
// Created On: 2022-07-28
// Author: Zachary Thomas
//
// Last Modified: 2024-06-21
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/api/apiRequest";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import userHasPermission from "../../../utilities/userHasPermission";
import { API } from "../../../constants/miscellaneous";
import useApi from "../../../hooks/useApi";
import { TESTING_NEW_FEATURE } from "../../../constants/miscellaneous";
import { LENT_ASSET_TYPES } from "../../../constants/reducerActions";
import AssetDetails from "../../../components/AssetDetails/AssetDetails";
import getApiError from "../../../utilities/api/getApiError";
import {
  ACCEPT_ASSET_RENTALS_PERMISSION,
  DECLINE_ASSET_RENTALS_PERMISSION,
  RETURN_ASSET_RENTALS_PERMISSION,
} from "../../../constants/permissions";
import styles from "./RentingAssetModal.module.scss";

// Modal for viewing assets that the current company is renting, or for handling requests of rentals being passed to the current company.
export default function RentingAssetModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [asset, setAsset] = useState<DetailedAsset | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);

  // Get the current pending asset information.
  useApi(
    () => {
      if (props.asset !== undefined && props.asset.isPendingApproval && props.asset.assetId > 0) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/pending/asset/${props.asset.assetId}`,
    },
    async (response: Response, responseBody: AssetResponseBody) => {
      if (response.ok && responseBody) {
        const assetDetails = {
          assetId: responseBody.assetId,
          name: responseBody.name,
          nickname: responseBody.nickname,
          productModel: responseBody.productModel,
          productIdentifier: responseBody.productIdentifier,
          productManufacturer: responseBody.productManufacturer,
          deviceType: responseBody.deviceType,
          deviceTypeName: responseBody.deviceTypeName,
          deviceIdentifier: responseBody.productIdentifier,
          controllerModel: responseBody.controllerModel,
          controllerName: responseBody.controllerName,
          alertThresholdPermission: responseBody.alertThresholdPermission,
          operateAccessPermission: responseBody.operateAccessPermission,
        };
        setAsset(assetDetails);
      } else {
        setAsset(null);
        setErrorMessage("Internal server error. Unable to get asset information.");
      }
      setLoading(false);
    },
    [JSON.stringify(props.asset)]
  );

  // Get the current non-pending asset information.
  useApi(
    () => {
      if (props.asset !== undefined && !props.asset.isPendingApproval && props.asset.assetId > 0) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${props.asset.assetId}/configuration`,
    },
    async (response: Response, responseBody: AssetResponseBody) => {
      if (response.ok && responseBody) {
        const assetDetails = {
          assetId: responseBody.assetId,
          name: responseBody.name,
          nickname: responseBody.nickname,
          productModel: responseBody.productModel,
          productIdentifier: responseBody.productIdentifier,
          productManufacturer: responseBody.productManufacturer,
          deviceType: responseBody.deviceType,
          deviceTypeName: responseBody.deviceTypeName,
          deviceIdentifier: responseBody.productIdentifier,
          controllerModel: responseBody.controllerModel,
          controllerName: responseBody.controllerName,
          alertThresholdPermission: responseBody.alertThresholdPermission,
          operateAccessPermission: responseBody.operateAccessPermission,
        };
        setAsset(assetDetails);
      } else {
        setAsset(null);
        setErrorMessage("Internal server error. Unable to get asset information.");
      }
      setLoading(false);
    },
    [JSON.stringify(props.asset)]
  );

  // Accept or rejects the current asset.
  async function respondToRequest(isAccepted: boolean): Promise<void> {
    setShowConfirmDelete(false);
    const requestBody = {
      isAccepted: isAccepted,
    };

    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/rental/response/${props.asset.assetId}`,
      "POST",
      requestBody
    )) as [Response, ResponseBody];
    setLoading(false);

    if (response.ok && isAccepted) {
      props.onAction({
        type: LENT_ASSET_TYPES.APPROVE_ASSET,
        payload: {
          assetId: props.asset.assetId,
          companyId: props.asset.renterCompanyId,
        },
      });
    } else if (response.ok && !isAccepted) {
      props.onAction({
        type: LENT_ASSET_TYPES.DELETE_ASSET,
        payload: {
          assetId: props.asset.assetId,
          companyId: props.asset.renterCompanyId,
        },
      });
    } else {
      setErrorMessage(await getApiError(response, "Unable to respond to pending rental request."));
    }
  }

  // Returns the current asset to the company that was renting it out.
  async function returnAsset(): Promise<void> {
    setShowConfirmDelete(false);
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/rental/return/${props.asset.assetId}`,
      "DELETE",
      null
    )) as [Response, AssetResponseBody];
    setLoading(false);

    if (response.ok) {
      props.onAction({
        type: LENT_ASSET_TYPES.DELETE_ASSET,
        payload: {
          assetId: props.asset.assetId,
          companyId: props.asset.renterCompanyId,
        },
      });
    }
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.asset.isPendingApproval ? "Review Pending Asset" : "View Asset"}</h5>
        </ModalHeader>

        <ModalBody>
          <div className="text-center">
            <span className="mt-3">Asset &apos;</span>
            <b>{props.asset.name}</b>
            <span>
              {props.asset.isPendingApproval ? "' is being sent from company '" : "' is rented from company '"}
            </span>
            <b>{props.asset.renterCompanyName}</b>
            <span>&apos;.</span>
            <br />
            {props.asset.operateAccessPermission ? (
              <label>Remote operation is enabled for rentee.</label>
            ) : (
              <label>Remote operation is disabled for rentee.</label>
            )}
            <br />
            {TESTING_NEW_FEATURE && (
              <Fragment>
                {props.asset.alertThresholdPermission ? (
                  <label>Alert threshold configuration is enabled for rentee.</label>
                ) : (
                  <label>Alert threshold configuration is disabled for rentee.</label>
                )}
              </Fragment>
            )}

            {asset !== null && (
              <AssetDetails
                name={asset.name}
                nickname={asset.nickname}
                productModel={asset.productModel}
                productIdentifier={asset.productIdentifier}
                productManufacturer={asset.productManufacturer}
                deviceType={asset.deviceTypeName}
                deviceIdentifier={asset.deviceIdentifier}
                controllerModel={asset.controllerName}
                loading={false}
                isRented={false}
              />
            )}

            <div className="my-2">
              <Error message={errorMessage} />
            </div>
          </div>
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.asset.isPendingApproval ? (
            <Fragment>
              {userHasPermission([[DECLINE_ASSET_RENTALS_PERMISSION]]) && (
                <button
                  className={`${styles.btn} btn btn-danger me-auto" type="button`}
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Decline Asset
                </button>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {userHasPermission([[RETURN_ASSET_RENTALS_PERMISSION]]) && (
                <button
                  data-test="renting-asset-modal-return-asset-button"
                  className={`${styles.btn} btn btn-danger me-auto" type="button`}
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Return Asset
                </button>
              )}
            </Fragment>
          )}

          {props.asset.isPendingApproval && userHasPermission([[ACCEPT_ASSET_RENTALS_PERMISSION]]) && (
            <button
              data-test="renting-asset-modal-accept-asset-button"
              className={`${styles.btn} btn btn-success me-auto" type="button`}
              onClick={() => respondToRequest(true)}
            >
              Accept Asset
            </button>
          )}

          <button className={`${styles.btn} btn btn-secondary`} type="button" onClick={() => props.onClose()}>
            Close
          </button>
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title={props.asset.isPendingApproval ? `Decline '${props.asset.name}'` : `Return '${props.asset.name}'`}
        content={
          props.asset.isPendingApproval
            ? `Are you sure that you want to decline the asset '${props.asset.name}'?`
            : `Are you sure that you want to return the asset '${props.asset.name}'?`
        }
        yesText={props.asset.isPendingApproval ? "Decline Asset" : "Return Asset"}
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => {
          props.asset.isPendingApproval ? respondToRequest(false) : returnAsset();
        }}
        onNo={() => setShowConfirmDelete(false)}
      />
    </div>
  );
}

RentingAssetModal.propTypes = {
  asset: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  asset: RentalAsset;
  onClose: () => void;
  onAction: (action: RentalAction) => void;
}

interface AssetResponseBody {
  assetId: number;
  name: string;
  nickname: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  deviceType: string;
  deviceTypeName: string;
  deviceIdentifier: string;
  controllerModel: string;
  controllerName: string;
  alertThresholdPermission: boolean;
  operateAccessPermission: boolean;
  error?: string;
}

interface ResponseBody {
  error?: string;
}

interface RentalAsset {
  assetId: number;
  name: string;
  renterCompanyId: number;
  renterCompanyName: string;
  operateAccessPermission: boolean;
  alertThresholdPermission: boolean;
  isPendingApproval: boolean;
}

interface RentalAction {
  type: string;
  payload: RentalPayload;
}

interface RentalPayload {
  assets?: RentalAsset[];
  asset?: RentalAsset;
  assetId?: number;
  companyId?: number;
}

interface DetailedAsset {
  assetId: number;
  name: string;
  nickname: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  deviceType: string;
  deviceTypeName: string;
  deviceIdentifier: string;
  controllerModel: string;
  controllerName: string;
  alertThresholdPermission: boolean;
  operateAccessPermission: boolean;
}
