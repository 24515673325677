// --------------------------------------------------------------
// Created On: 2021-12-29
// Author: Zachary Thomas
//
// Last Modified: 2024-07-18
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

/// <reference types="google.maps" />
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LocationHistoryWarning from "./LocationHistoryWarning/LocationHistoryWarning";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import calculateCenterZoom from "../../../../utilities/calculateCenterZoom";
import isoToTimePassed from "../../../../utilities/time/isoToTimePassed";
import formatDateShortLocal from "../../../../utilities/time/formatDateShortLocal";
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_DEFAULT_ZOOM,
  GOOGLE_MAPS_DEFAULT_LAT,
  GOOGLE_MAPS_DEFAULT_LNG,
  GOOGLE_MAPS_BOTTOM_LEFT,
  GOOGLE_MAPS_HORIZONTAL_BAR,
  GOOGLE_MAPS_ROAD_MAP,
  GOOGLE_MAPS_SATELLITE,
  GOOGLE_MAPS_MIN_ZOOM,
} from "../../../../constants/googleMaps";
import { MS_PER_MINUTE } from "../../../../constants/miscellaneous";
import styles from "./LocationMap.module.scss";

// Map that displays asset location history.
export default function LocationMap(props: Props): Component {
  const [center, setCenter] = useState<ShortPoint>({
    lat: GOOGLE_MAPS_DEFAULT_LAT,
    lng: GOOGLE_MAPS_DEFAULT_LNG,
  });
  const [zoom, setZoom] = useState<number>(GOOGLE_MAPS_DEFAULT_ZOOM);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [filteredLocations, setFilteredLocations] = useState<LocationHistoryPoint[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationHistoryPoint | null>(null);
  const [markerMap, setMarkerMap] = useState<MarkerMap>({});
  const [satelliteViewEnabled, setSatelliteViewEnabled] = useState<boolean>(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  // Draw location history polyline to the map.
  useEffect(() => {
    let locationHistoryPolyline: google.maps.Polyline | null = null;
    let locationHistoryPolylineBorder: google.maps.Polyline | null = null;

    // Filter out location history records that are outside of the selected frequency.
    let mostRecentTime: string | null = null;
    const filteredLocations: LocationHistoryPoint[] = [];
    props.locations.forEach((location) => {
      if (mostRecentTime === null) {
        mostRecentTime = location.changeDate;
        filteredLocations.push(location);
      } else {
        const mostRecentDate = new Date(`${mostRecentTime}.000Z`);
        const currentLocationDate = new Date(`${location.changeDate}.000Z`);
        const timeDifferenceMinutes = (mostRecentDate.getTime() - currentLocationDate.getTime()) / MS_PER_MINUTE;
        if (timeDifferenceMinutes >= props.frequencyMinutes) {
          mostRecentTime = location.changeDate;
          filteredLocations.push(location);
        }
      }
    });

    if (map !== null && filteredLocations.length > 0) {
      // Get the complete location history path.
      const locationsPath = filteredLocations.map((location) => {
        return { lat: location.latitude, lng: location.longitude };
      });

      // Get the correct line settings based on if in satellite view or not.
      let lineSettings = {
        path: locationsPath,
        strokeColor: "#00b0ff",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        zIndex: 5,
      };

      if (satelliteViewEnabled) {
        lineSettings = {
          path: locationsPath,
          strokeColor: "#3957d9",
          strokeOpacity: 0.75,
          strokeWeight: 4,
          zIndex: 5,
        };
      }

      // Draw the new location history polyline.
      if (locationsPath.length > 1) {
        locationHistoryPolyline = new google.maps.Polyline(lineSettings);
        if (!satelliteViewEnabled) {
          locationHistoryPolylineBorder = new google.maps.Polyline({
            path: locationsPath,
            strokeColor: "#3957d9",
            strokeOpacity: 0.8,
            strokeWeight: 9,
            zIndex: 1,
          });
          locationHistoryPolylineBorder.setMap(map);
        }
        locationHistoryPolyline.setMap(map);
      }
    }

    setFilteredLocations(filteredLocations);
    setSelectedLocation(null);

    // Clean up old polyline off the map.
    return () => {
      if (locationHistoryPolyline) {
        locationHistoryPolyline.setMap(null);
      }
      if (locationHistoryPolylineBorder) {
        locationHistoryPolylineBorder.setMap(null);
      }
    };
  }, [
    props.startDate,
    props.endDate,
    map,
    JSON.stringify(props.locations),
    props.frequencyMinutes,
    satelliteViewEnabled,
  ]);

  // Get the required center and zoom level to fit all markers on the map.
  useEffect(() => {
    const points: GeofencePoint[] = [];

    // Get location data from location history points.
    filteredLocations.forEach((location, i) =>
      points.push({
        geofencePointId: i + 1,
        latitude: location.latitude,
        longitude: location.longitude,
      })
    );

    // Find the new bounding box that fits all markers.
    if (map !== null) {
      const newBounds = calculateCenterZoom(points, map);

      if (newBounds !== undefined) {
        setCenter(newBounds.center);
        setZoom(newBounds.zoom - 1);
      } else {
        setCenter({
          lat: GOOGLE_MAPS_DEFAULT_LAT,
          lng: GOOGLE_MAPS_DEFAULT_LNG,
        });
        setZoom(GOOGLE_MAPS_DEFAULT_ZOOM);
      }
    } else {
      setCenter({
        lat: GOOGLE_MAPS_DEFAULT_LAT,
        lng: GOOGLE_MAPS_DEFAULT_LNG,
      });
      setZoom(GOOGLE_MAPS_DEFAULT_ZOOM);
    }
  }, [JSON.stringify(filteredLocations), props.frequencyMinutes]);

  // Handle selection of location.
  function selectLocation(location: LocationHistoryPoint): void {
    // Set the selected location to null before setting it to a specific value.
    // This prevents an issue with the InfoWindow where the anchor is not updated
    // correctly.
    setSelectedLocation(null);
    setSelectedLocation(location);
  }

  // Handle load event of marker so that it aligns correctly with info windows.
  function handleMarkerLoad(marker: google.maps.Marker, location: LocationHistoryPoint): void {
    setMarkerMap((prevState) => {
      return { ...prevState, [location.changeDate]: marker };
    });
  }

  // Handel load map event.
  function handleLoadMap(map: google.maps.Map): void {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }

  // Handel unmount map event.
  function handleUnmountMap(): void {
    setMap(null);
  }

  return (
    <div>
      <div className={styles.mapWrapper}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "85vh" }}
            center={center}
            zoom={zoom}
            onLoad={(map) => handleLoadMap(map)}
            onUnmount={() => handleUnmountMap()}
            onMapTypeIdChanged={() => setSatelliteViewEnabled((prev) => !prev)}
            options={{
              fullscreenControl: false,
              scaleControl: true,
              mapTypeControl: true,
              tilt: 0,
              minZoom: GOOGLE_MAPS_MIN_ZOOM,
              styles: [
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
              mapTypeControlOptions: {
                position: GOOGLE_MAPS_BOTTOM_LEFT,
                style: GOOGLE_MAPS_HORIZONTAL_BAR,
                mapTypeIds: [GOOGLE_MAPS_ROAD_MAP, GOOGLE_MAPS_SATELLITE],
              },
              restriction: {
                latLngBounds: {
                  east: 180,
                  north: 85,
                  south: -85,
                  west: -180,
                },
                strictBounds: true,
              },
            }}
          >
            {filteredLocations.length > 0 && (
              <Marker
                position={{ lat: filteredLocations[0].latitude, lng: filteredLocations[0].longitude }}
                title={props.assetName}
                onClick={() => selectLocation(filteredLocations[0])}
                onLoad={(marker) => handleMarkerLoad(marker, filteredLocations[0])}
                icon={{
                  url: "/mapMarkers/mapMarkerGearActive.png",
                  size: new google.maps.Size(40, 48),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(20, 48),
                  scaledSize: new google.maps.Size(40, 48),
                }}
              />
            )}
            {filteredLocations.slice(1).map((location, i) => (
              <Marker
                key={i}
                position={{ lat: location.latitude, lng: location.longitude }}
                title={props.assetName}
                onClick={() => selectLocation(location)}
                onLoad={(marker) => handleMarkerLoad(marker, location)}
                icon={{
                  url: "/mapMarkers/mapMarkerGeofenceEnabled.png",
                  size: new google.maps.Size(15, 15),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(8, 8),
                  scaledSize: new google.maps.Size(15, 15),
                }}
              />
            ))}
            {selectedLocation !== null && (
              <InfoWindow
                anchor={markerMap[selectedLocation.changeDate]}
                onCloseClick={() => setSelectedLocation(null)}
              >
                <div className={styles.infoBody}>
                  <div className={styles.infoHeader}>{props.assetName}</div>
                  <div className="mb-2">
                    ({selectedLocation.latitude}, {selectedLocation.longitude})
                  </div>
                  <div>Time Reported: {formatDateShortLocal(selectedLocation.changeDate)}</div>
                  <div>Time Since Reported: {isoToTimePassed(selectedLocation.changeDate)}</div>
                  {selectedLocation.speed !== undefined && (
                    <div>
                      Speed: {selectedLocation.speed} {props.speedUnitName}
                    </div>
                  )}
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
        <LocationHistoryWarning showWarning={props.assetId <= 0} failedToLoad={props.failedToLoad} />
      </div>
    </div>
  );
}

LocationMap.propTypes = {
  assetId: PropTypes.number.isRequired,
  assetName: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  speedUnitName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  frequencyMinutes: PropTypes.number.isRequired,
  failedToLoad: PropTypes.bool,
};

interface Props {
  assetId: number;
  assetName: string;
  locations: LocationHistoryPoint[];
  speedUnitName: string;
  startDate: string;
  endDate: string;
  frequencyMinutes: number;
  failedToLoad?: boolean;
}

interface GeofencePoint {
  geofencePointId: number;
  latitude: number;
  longitude: number;
  draggable?: boolean;
}

interface ShortPoint {
  lat: number;
  lng: number;
}

interface MarkerMap {
  [key: string]: google.maps.Marker;
}
