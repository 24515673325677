// --------------------------------------------------------------
// Created On: 2022-01-04
// Author: Zachary Thomas
//
// Last Modified: 2024-01-15
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MaintenanceList from "./MaintenanceList/MaintenanceList";
import {
  RUNTIME_ASSET_ATTRIBUTE,
  RUNTIME_SERVICE_ATTRIBUTE,
  RUNTIME_VIBRATION_ATTRIBUTE,
} from "../../../constants/attributes";
import MaintenanceModal from "./MaintenanceModal/MaintenanceModal.jsx";

// Complete list of maintenance logs, with CRUD function support.
export default function MaintenanceContainer(props: Props): Component {
  const initialMaintenanceLog = {
    assetMaintenanceLogId: 0,
    datePerformed: "",
    workPerformedBy: "",
    workPerformed: "",
    currentServiceRuntime: "",
    engineRuntime: "",
    vibrationBasedRuntime: "",
    cost: "",
    note: "",
  };
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedLog, setSelectedLog] = useState<MaintenanceLog>(initialMaintenanceLog);

  // If the selected ID changes, update the selected maintenance log, and mode.
  useEffect(() => {
    const index = props.maintenanceLogs.findIndex(
      (maintenanceLog) => maintenanceLog.assetMaintenanceLogId === selectedId
    );

    if (index === -1) {
      setSelectedLog({
        assetMaintenanceLogId: 0,
        datePerformed: "",
        workPerformedBy: "",
        workPerformed: "",
        currentServiceRuntime: getRuntimeValue(RUNTIME_SERVICE_ATTRIBUTE, props.attributes),
        engineRuntime: getRuntimeValue(RUNTIME_ASSET_ATTRIBUTE, props.attributes),
        vibrationBasedRuntime: getRuntimeValue(RUNTIME_VIBRATION_ATTRIBUTE, props.attributes),
        cost: "",
        note: "",
      });
    } else {
      setSelectedLog(props.maintenanceLogs[index]);
    }

    if (selectedId === 0) {
      setMode("create");
    } else {
      setMode("edit");
    }
  }, [selectedId, JSON.stringify(props.maintenanceLogs), JSON.stringify(props.attributes)]);

  // Get the current hours from a specific attribute, or an empty string.
  function getRuntimeValue(attributeCode: string, attributes: Attribute[]): string {
    const runtimeAttribute = attributes.find((attribute) => attribute.attributeCode === attributeCode);
    if (runtimeAttribute === undefined) {
      return "";
    } else {
      return runtimeAttribute.currentValue;
    }
  }

  return (
    <div className="maintenance-container p-4">
      <MaintenanceList
        assetName={props.assetName}
        maintenanceLogs={props.maintenanceLogs}
        onSelect={(selectedId) => setSelectedId(selectedId)}
      />

      <MaintenanceModal
        mode={mode}
        showModal={selectedId !== -1}
        isRented={props.isRented}
        selectedLog={selectedLog}
        attributes={props.attributes}
        onClose={() => setSelectedId(-1)}
        onAction={(action) => props.onAction(action)}
      />
    </div>
  );
}

MaintenanceContainer.propTypes = {
  assetName: PropTypes.string.isRequired,
  isRented: PropTypes.bool.isRequired,
  maintenanceLogs: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  assetName: string;
  isRented: boolean;
  maintenanceLogs: MaintenanceLog[];
  attributes: Attribute[];
  onAction: (action: Action) => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  isHistorical: boolean;
}

interface MaintenanceLog {
  assetMaintenanceLogId: number;
  workPerformed: string;
  workPerformedBy: string;
  datePerformed: string;
  currentServiceRuntime: string | null;
  vibrationBasedRuntime: string | null;
  engineRuntime: string | null;
  cost: string;
  note: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assetMaintenanceLogId?: number;
  maintenanceLog?: MaintenanceLog;
  maintenanceLogs?: MaintenanceLog[];
}
