// --------------------------------------------------------------
// Created On: 2022-06-20
// Author: Zachary Thomas
//
// Last Modified: 2024-08-21
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import useApi from "../../../../hooks/useApi";
import nowToLocalIso from "../../../../utilities/time/nowToLocalIso";
import isoToDate from "../../../../utilities/time/isoToDate";
import isoToLocalDate from "../../../../utilities/time/isoToLocalDate";
import isoAddDays from "../../../../utilities/time/isoAddDays";
import Spinner from "../../../../components/Spinner/Spinner";
import Modal from "../../../../components/Modal/Modal";
import ModalHeader from "../../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../../components/ModalFooter/ModalFooter";
import LineChart from "../../../../components/LineChart/LineChart";
import HistoryTable from "./HistoryTable/HistoryTable";
import isoToTimestamp from "../../../../utilities/time/isoToTimestamp";
import Error from "../../../../components/Error/Error";
import Warning from "../../../../components/Warning/Warning";
import DataHistoryControls from "../../../../components/DataHistoryControls/DataHistoryControls";
import getApiError from "../../../../utilities/api/getApiError";
import smoothTimeDataSeries from "../../../../utilities/smoothTimeDataSeries";
import stringIsValidNumber from "../../../../utilities/stringIsValidNumber";
import { useParams } from "react-router-dom";
import { API, DATA_STARTING_DATE_OFFSET, DATA_ENDING_DATE_OFFSET } from "../../../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/selectors";
import styles from "./DataHistoryModal.module.scss";

// Modal for displaying generic history data.
export default function DataHistoryModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(calculateStartDate(props.date));
  const [endDate, setEndDate] = useState<string>(nowToLocalIso(DATA_ENDING_DATE_OFFSET).split("T")[0]);
  const [startDateOffset, setStartDateOffset] = useState<string | null>(null);
  const [endDateOffset, setEndDateOffset] = useState<string | null>(null);
  const [historicalSeries, setHistoricalSeries] = useState<FlexibleTimeDataSeries[] | null>(null);
  const [cachedHistoricalSeries, setCachedHistoricalSeries] = useState<FlexibleTimeDataSeries[] | null>(null);
  const [cachedQuery, setCachedQuery] = useState<HistoryQuery | null>(null);
  const [graphingMode, setGraphingMode] = useState<string>("STANDARD");
  const [dataIsGraphable, setDataIsGraphable] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);
  const isCachedData = useMemo(() => getExistenceOfCachedData(historicalSeries), [historicalSeries]);
  const { assetId } = useParams();

  // When opened, get the current type of historical data.
  useApi(
    () => {
      if (
        props.showModal &&
        assetId !== undefined &&
        startDateOffset !== null &&
        endDateOffset !== null &&
        queryIsValid()
      ) {
        if (newQueryRequired(startDate, endDate, props.code, cachedQuery)) {
          // Query new data.
          setLoading(true);
          setHistoricalSeries(null);
          setErrorMessage("");
          return true;
        } else {
          // Use the cached result.
          setLoading(false);
          setHistoricalSeries([getCachedResult(props.name, startDate, endDate)]);
          return false;
        }
      } else {
        // Data should not be queried.
        setLoading(false);
        setHistoricalSeries(null);
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/history`,
      body: {
        startDate: startDateOffset,
        endDate: endDateOffset,
        attributeCode: props.code,
      },
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        if (queryIsValid()) {
          // Get unit information.
          const historicalAttribute = responseBody;
          if (historicalAttribute !== null && historicalAttribute !== undefined) {
            let unitLongName = "";
            let unitShortName = "";

            if (unitLongName !== null && unitLongName !== undefined) {
              unitLongName = historicalAttribute.unitLongName;
            }
            if (unitShortName !== null && unitShortName !== undefined) {
              unitShortName = historicalAttribute.unitShortName;
            }

            // Convert incoming data into a series of tuples that represent value and time.
            const series: FlexibleTimeDataSeries = {
              name: props.name,
              unitName: unitLongName,
              unitShortName: unitShortName,
              data: [],
              dataMarkers: [],
            };

            const startDateObject = isoToDate(`${startDate}T00:00:00`);
            const endDateObject = isoToDate(isoAddDays(`${endDate}T00:00:00`, 1));

            let isGraphableTimeSeries = true;
            historicalAttribute.dataPoints.forEach((dataPoint: HistoricalDataPoint) => {
              const dataPointDateObject = isoToLocalDate(dataPoint.dateTimeUtc);
              if (dataPointDateObject >= startDateObject && dataPointDateObject < endDateObject) {
                let dataPointValue = null;
                const stringContainsComma = dataPoint.value.indexOf(",") > -1; // Avoid parsing GPS cords as a float.
                if (stringIsValidNumber(dataPoint.value) && !stringContainsComma) {
                  dataPointValue = parseFloat(dataPoint.value);
                } else if (dataPoint.value.toLowerCase() === "true") {
                  dataPointValue = 1;
                } else if (dataPoint.value.toLowerCase() === "false") {
                  dataPointValue = 0;
                } else {
                  isGraphableTimeSeries = false;
                  dataPointValue = dataPoint.value;
                }

                if (dataPointValue !== null) {
                  series.data.push([isoToTimestamp(dataPoint.dateTimeUtc), dataPointValue]);

                  // Check if new data point is a cached upload.
                  if (dataPoint.isCachedUpload) {
                    const newMarker = {
                      seriesIndex: 0,
                      dataPointIndex: series.data.length - 1,
                    };
                    series.dataMarkers.push(newMarker);
                  }
                }
              }
            });
            setDataIsGraphable(isGraphableTimeSeries);

            if (graphingMode === "MOVING_AVERAGE" && isGraphableTimeSeries) {
              setHistoricalSeries([smoothTimeDataSeries(series as TimeDataSeries) as FlexibleTimeDataSeries]);
            } else {
              setHistoricalSeries([series]);
            }
            setErrorMessage("");

            // Cache the query and results to reduce the number of required future queries.
            const query = {
              startDate: startDate,
              endDate: endDate,
              attributeCode: props.code,
            };
            setCachedQuery(query);
            setCachedHistoricalSeries([series]);
          }
        } else {
          const series: FlexibleTimeDataSeries = {
            name: props.name,
            unitName: "",
            unitShortName: "",
            data: [],
            dataMarkers: [],
          };
          setHistoricalSeries([series]);
        }
      } else {
        setHistoricalSeries(null);
        setErrorMessage(await getApiError(response, "Unable to get data."));
      }
      setLoading(false);
    },
    [
      props.showModal,
      assetId,
      props.name,
      props.code,
      props.date,
      startDate,
      endDate,
      startDateOffset,
      endDateOffset,
      currentUser.companyId,
      graphingMode,
    ]
  );

  // When a new asset is selected, recalculate the start and end dates.
  useEffect(() => {
    if (props.showModal) {
      setStartDate(calculateStartDate(props.date));
      setEndDate(nowToLocalIso(DATA_ENDING_DATE_OFFSET).split("T")[0]);
    }
  }, [assetId, props.showModal]);

  // When start or end date is changed, update the offset date that is used by the API to account for timezones.
  useEffect(() => {
    if (queryIsValid()) {
      const currentStartDate = startDate.split("T")[0];
      const currentEndDate = endDate.split("T")[0];
      setStartDateOffset(isoAddDays(`${currentStartDate}T00:00:00`, -1).split(".")[0]);
      setEndDateOffset(isoAddDays(`${currentEndDate}T00:00:00`, 1).split(".")[0]);
    }
  }, [startDate, endDate]);

  // Compare the last time a reading was taken and the default starting date, select the one that is oldest as the start date.
  function calculateStartDate(mostRecentSampleDate: string | null): string {
    const defaultStartDate = nowToLocalIso(DATA_STARTING_DATE_OFFSET).split("T")[0];
    if (mostRecentSampleDate === null || mostRecentSampleDate > defaultStartDate) {
      return defaultStartDate;
    } else {
      return mostRecentSampleDate;
    }
  }

  // Check if the current query is valid.
  function queryIsValid(): boolean {
    if (startDate.length === 0) {
      setErrorMessage("A valid start date must be selected.");
      return false;
    } else if (endDate.length === 0) {
      setErrorMessage("A valid end date must be selected.");
      return false;
    } else {
      return true;
    }
  }

  // Compare the cached query to a new query to see if a new query is required.
  function newQueryRequired(
    startDate: string,
    endDate: string,
    attributeCode: string,
    cachedQuery: HistoryQuery | null
  ): boolean {
    if (cachedQuery === null) {
      return true;
    }

    if (attributeCode !== cachedQuery.attributeCode) {
      return true;
    }

    const startDateObject = isoToDate(`${startDate}T00:00:00`);
    const endDateObject = isoToDate(isoAddDays(`${endDate}T00:00:00`, 1));

    const cachedStartDateObject = new Date(cachedQuery.startDate);
    const cachedEndDateObject = new Date(cachedQuery.endDate);
    if (startDateObject < cachedStartDateObject || endDateObject > cachedEndDateObject) {
      return true;
    }

    return false;
  }

  // Given a date range, get the results from a previously cached query.
  function getCachedResult(name: string, startDate: string, endDate: string): FlexibleTimeDataSeries {
    let series: FlexibleTimeDataSeries = {
      name: name,
      unitName: "",
      unitShortName: "",
      data: [],
      dataMarkers: [],
    };

    const startDateObject = isoToDate(`${startDate}T00:00:00`);
    const endDateObject = isoToDate(isoAddDays(`${endDate}T00:00:00`, 1));

    if (cachedHistoricalSeries !== null && cachedHistoricalSeries.length > 0) {
      series.unitName = cachedHistoricalSeries[0].unitName;
      series.unitShortName = cachedHistoricalSeries[0].unitShortName;
      cachedHistoricalSeries[0].data.forEach((row, i) => {
        const rowDateObject = new Date(row[0]);
        if (rowDateObject >= startDateObject && rowDateObject < endDateObject) {
          series.data.push([row[0], row[1]]);
          // Accurately map the cached markers to our new series.
          const cachedRowHasMarker = cachedHistoricalSeries[0].dataMarkers.some(
            (dataMarker) => dataMarker.dataPointIndex === i
          );
          if (cachedRowHasMarker) {
            const newMarker = {
              seriesIndex: 0,
              dataPointIndex: series.data.length - 1,
            };
            series.dataMarkers.push(newMarker);
          }
        }
      });
    }

    if (graphingMode === "MOVING_AVERAGE" && dataIsGraphable) {
      series = smoothTimeDataSeries(series as TimeDataSeries) as FlexibleTimeDataSeries;
    }

    return series;
  }

  // Check if there is any data to display on a graph.
  function dataIsDisplayable(seriesArray: FlexibleTimeDataSeries[] | null): boolean {
    let foundData = false;
    if (seriesArray !== null) {
      seriesArray.forEach((series) => {
        series.data.forEach(() => (foundData = true));
      });
    }
    return foundData;
  }

  // Checks if cached data exists in the current returned data.
  function getExistenceOfCachedData(seriesArray: FlexibleTimeDataSeries[] | null): boolean {
    if (seriesArray !== null && seriesArray.length > 0 && seriesArray[0].dataMarkers.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      <Spinner loading={loading && props.showModal} />

      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.name} History</h5>
        </ModalHeader>

        <ModalBody>
          <DataHistoryControls
            startDate={startDate}
            endDate={endDate}
            mode={graphingMode}
            isGraphable={props.isGraphable}
            onChangeStartDate={(startDate) => setStartDate(startDate)}
            onChangeEndDate={(endDate) => setEndDate(endDate)}
            onChangeMode={(graphingMode) => setGraphingMode(graphingMode)}
          />

          {errorMessage.length === 0 ? (
            <div className={styles.chartBox}>
              {!loading && !dataIsDisplayable(historicalSeries) && (
                <h4 className={styles.statusText}>No readings were found within this date range.</h4>
              )}

              {!loading &&
                dataIsDisplayable(historicalSeries) &&
                historicalSeries !== null &&
                dataIsGraphable &&
                props.isGraphable && <LineChart series={historicalSeries as TimeDataSeries[]} />}

              {!loading &&
                dataIsDisplayable(historicalSeries) &&
                historicalSeries !== null &&
                (!dataIsGraphable || !props.isGraphable) && <HistoryTable series={historicalSeries} />}
            </div>
          ) : (
            <div className="mt-4 mx-2">
              <Error message={errorMessage} />
            </div>
          )}

          {isCachedData && (
            <div className="mt-4 mx-2" data-test="cached-data-present-message">
              <Warning
                message={
                  "Yellow markers indicate data that was recorded by a device while it was offline." +
                  " The data was collected while the device was unable to contact the server, and uploaded after network communication was restored."
                }
              />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" type="button" onClick={() => props.onClose()}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

DataHistoryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  isGraphable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  date: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  isGraphable: boolean;
  name: string;
  code: string;
  date: string | null;
  onClose: () => void;
}

interface HistoryQuery {
  startDate: string;
  endDate: string;
  attributeCode: string;
}

interface ResponseBody {
  attributeName: string;
  unitLongName: string;
  unitShortName: string;
  dataPoints: HistoricalDataPoint[];
}

interface FlexibleTimeDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: [number, number | string][];
  dataMarkers: DataMarker[];
}

interface DataMarker {
  seriesIndex: number;
  dataPointIndex: number;
}
