// --------------------------------------------------------------
// Created On: 2023-06-23
// Author: Zachary Thomas
//
// Last Modified: 2023-11-17
// Modified By: Lilly Kane
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import { PRODUCT_TYPE_PUMP } from "../../../constants/products";
import CentrifugalPumpVisual from "./CentrifugalPumpVisual/CentrifugalPumpVisual";
import PropTypes from "prop-types";
import ProductDataTable from "./ProductDataTable/ProductDataTable";
import styles from "./ProductDataVisual.module.scss";

// A data visualization for a specific type of product.
export default function ProductDataVisual(props: Props): Component {
  const [attributeHoverList, setAttributeHoverList] = useState<boolean[]>([]);

  // See if the current product type should be displayed.
  function productTypeIsDisplayable(productType: string): boolean {
    return [PRODUCT_TYPE_PUMP].includes(productType);
  }

  // Initialize the attribute hover list to show no attributes being hovered over.
  useEffect(() => {
    setAttributeHoverList(props.attributes.map(() => false));
  }, [JSON.stringify(props.attributes)]);

  // Set the current hover status of an attribute in the hover list.
  function updateHoverList(index: number, isHovering: boolean): void {
    setAttributeHoverList((prev) =>
      prev.map((hoverValue, i) => {
        if (i === index) {
          return isHovering;
        } else return hoverValue;
      })
    );
  }

  // Get the current attribute being hovered over, if any.
  function getHoverAttributeCode(): string | null {
    if (props.attributes.length === attributeHoverList.length) {
      const hoverIndex = attributeHoverList.findIndex((isHovering) => isHovering);
      if (hoverIndex > -1) {
        return props.attributes[hoverIndex].attributeCode;
      }
    }
    return null;
  }

  return (
    <div data-test="product-data-visual-container" className="my-3 py-3">
      <div className="row justify-content-center mx-0">
        <div className="col-12 col-xl-8">
          <div className={styles.container}>
            {props.assetName.length > 0 && (
              <div className={styles.header}>{props.assetName} Highlights</div>
            )}
            <div className="row g-0">
              <div className={`${styles.body} col-12 col-lg-6`}>
                {props.productType === PRODUCT_TYPE_PUMP && (
                  <CentrifugalPumpVisual
                    attributes={props.attributes}
                    hoverCode={getHoverAttributeCode()}
                    staleAttributeCodes={props.staleAttributeCodes}
                  />
                )}
                {props.productType !== null &&
                  props.productType !== "" &&
                  props.productType !== undefined &&
                  !productTypeIsDisplayable(props.productType) && (
                    <div className="row justify-content-center py-4">
                      <i className={`${styles.noImage} fa fa-fw fa-question-circle-o`} />
                      <div className={styles.noImageLabel}>No product image available.</div>
                    </div>
                  )}
              </div>

              <ProductDataTable
                attributes={props.attributes}
                staleAttributeCodes={props.staleAttributeCodes}
                onUpdateHoverList={(index, isHovering) => updateHoverList(index, isHovering)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductDataVisual.propTypes = {
  assetName: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  staleAttributeCodes: PropTypes.array.isRequired,
};

interface Props {
  assetName: string;
  productType: string;
  attributes: Attribute[];
  staleAttributeCodes: string[];
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  icon?: string;
  isHistorical?: boolean;
}
