// --------------------------------------------------------------
// Created On: 2022-01-04
// Author: Zachary Thomas
//
// Last Modified: 2023-12-18
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import { API } from "../../../constants/miscellaneous";
import useApi from "../../../hooks/useApi";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { useParams } from "react-router-dom";
import ResourceList from "../../../components/ResourceList/ResourceList";
import HelpAlertLog from "../../../components/HelpAlertLog/HelpAlertLog";
import AlertLogListRow from "./AlertLogListRow/AlertLogListRow";
import AlertLogModal from "./AlertLogModal/AlertLogModal";
import Error500Page from "../../Error500Page/Error500Page";
import Spinner from "../../../components/Spinner/Spinner";
import PropTypes from "prop-types";

// Page for viewing alert logs for a specific asset.
export default function AlertLogContainer(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [alertLogs, setAlertLogs] = useState<AlertLog[]>([]);
  const [selectedAlertLogId, setSelectedAlertLogId] = useState<number>(0);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();

  // Handle the selection of an alert log.
  function handleAlertLogSelection(alertLogId: number) {
    setSelectedAlertLogId(alertLogId);
    setShowModal(true);
  }

  // Get alert log data from API.
  useApi(
    () => {
      if (assetId !== undefined) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/alertlog`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAlertLogs(responseBody.alertLogs);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [assetId]
  );

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <ResourceList
        resourceNameSingular="Alert Log"
        resourceNamePlural={`${props.assetName} Alert Logs`}
        resourceArticle="an"
        resourceIdKey="alertLogId"
        resourcePriorityKey="createdUtc"
        resources={alertLogs}
        resourceRow={AlertLogListRow}
        onClickHeaderButton={() => {
          /* Do nothing. */
        }}
        helpModal={<HelpAlertLog />}
        loading={loading}
        onSelect={(alertLogId) => handleAlertLogSelection(alertLogId)}
      />

      <AlertLogModal showModal={showModal} alertLogId={selectedAlertLogId} onClose={() => setShowModal(false)} />
    </div>
  );
}

AlertLogContainer.propTypes = {
  assetName: PropTypes.string.isRequired,
};

interface Props {
  assetName: string;
}

interface AlertLog {
  alertLogId: number;
  message: string;
  isAcknowledged: boolean;
  isTimedOut: boolean;
  createdUtc: string;
}

interface ResponseBody {
  alertLogs: AlertLog[];
}
