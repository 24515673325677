// --------------------------------------------------------------
// Created On: 2023-07-20
// Author: Zachary Thomas
//
// Last Modified: 2024-08-09
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { POWER_TO_PANEL_ATTRIBUTE, COMMON_ALARM_ATTRIBUTE } from "../../../constants/attributes";
import {
  COPILOT_DEVICE_TYPE,
  COPILOT_EDGE_DEVICE_TYPE,
  COPILOT_PMG_DEVICE_TYPE,
} from "../../../constants/miscellaneous";
import isoUtcToIsoLocal from "../../../utilities/time/isoUtcToIsoLocal";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";
import DataHistoryModal from "../AssetDataContainer/DataHistoryModal/DataHistoryModal";
import SensorConnectedIcon from "../../../components/SensorConnectedIcon/SensorConnectedIcon";
import LightIcon from "../../../components/LightIcon/LightIcon";
import isValueBooleanString from "../../../utilities/isValueBooleanString";
import styles from "./AttributeHighlights.module.scss";
import SampleDeviceDataButton from "../../../components/SampleDeviceDataButton/SampleDeviceDataButton";

// Panels of highlighted attributes divided into controller and sensor attributes.
export default function AttributeHighlights(props: Props): Component {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [attributeName, setAttributeName] = useState<string>("");
  const [attributeCode, setAttributeCode] = useState<string>("");
  const [attributeDate, setAttributeDate] = useState<string | null>(null);
  const userCanOperate = checkIfUserCanOperate();

  // Show historical data for the individual data attribute.
  function showHistoricalData(name: string, code: string, isHistorical: boolean, mostRecentUtc?: string): void {
    if (isHistorical) {
      setShowModal(true);
      setAttributeName(name);
      setAttributeCode(code);
      if (typeof mostRecentUtc === "string") {
        const mostRecentLocal = isoUtcToIsoLocal(mostRecentUtc);
        const mostRecentSections = mostRecentLocal.split("T");
        setAttributeDate(mostRecentSections[0]);
      } else {
        setAttributeDate(null);
      }
    }
  }

  // Determines if a light should be shown as on or off based on the attribute code and value.
  function lightIsOn(attributeCode: string, value: string): boolean {
    if (props.staleAttributeCodes.includes(attributeCode)) {
      return false;
    } else if (attributeCode === POWER_TO_PANEL_ATTRIBUTE) {
      return true;
    } else if (isValueBooleanString(value) && value.toLowerCase() === "true") {
      return true;
    } else {
      return false;
    }
  }

  // Determines what color a light should be based on the attribute code and value.
  function lightColor(attributeCode: string, value: string): "red" | "green" {
    if (attributeCode === POWER_TO_PANEL_ATTRIBUTE && isValueBooleanString(value) && value.toLowerCase() === "false") {
      return "red";
    } else if (
      attributeCode === COMMON_ALARM_ATTRIBUTE &&
      isValueBooleanString(value) &&
      value.toLowerCase() === "true"
    ) {
      return "red";
    } else {
      return "green";
    }
  }

  // Check if the user should be able to operate the current device.
  function checkIfUserCanOperate(): boolean {
    return (
      [COPILOT_DEVICE_TYPE, COPILOT_PMG_DEVICE_TYPE, COPILOT_EDGE_DEVICE_TYPE].includes(props.deviceType || "") &&
      props.deviceId !== null &&
      props.deviceId !== undefined &&
      props.operationIsAllowed
    );
  }

  return (
    <Fragment>
      <div className="row justify-content-center my-2 gx-0 mx-0">
        {/* Show the controller panel when there is at least one controller attribute to display. */}
        {props.controllerAttributes.length > 0 && (
          <div
            data-test="controller-highlights"
            className={props.deviceType === null ? "col-12" : "col-12 col-lg-6 mb-4 mb-lg-0"}
          >
            <div className={styles.body}>
              <div className={styles.titleBar}>
                <label className={styles.title}>
                  {props.controllerType !== null ? `${props.controllerType} Highlights` : "Controller Highlights"}
                </label>
              </div>
              <div>
                <table className={`${styles.table} table mb-0 pb-0`}>
                  <thead className={styles.thead}>
                    <tr>
                      <th className={styles.th}>Name</th>
                      <th className={styles.th}>Value</th>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.controllerAttributes.map((attribute) => (
                      <tr
                        key={attribute.regAttributeId}
                        className={
                          props.staleAttributeCodes.includes(attribute.attributeCode)
                            ? styles.staleData
                            : styles.activeData
                        }
                        onClick={() =>
                          showHistoricalData(
                            attribute.attributeName,
                            attribute.attributeCode,
                            attribute.isHistorical,
                            attribute.currentValueUtc
                          )
                        }
                      >
                        <td>{attribute.attributeName}</td>
                        <td>
                          {attribute.currentValue === null
                            ? "N/A"
                            : `${attribute.currentValue} ${
                                attribute.unitShortName === null ? "" : attribute.unitShortName
                              }`}
                        </td>
                        <td>
                          {isValueBooleanString(attribute.currentValue) && (
                            <LightIcon
                              isOn={lightIsOn(attribute.attributeCode, attribute.currentValue)}
                              color={lightColor(attribute.attributeCode, attribute.currentValue)}
                            />
                          )}
                        </td>
                        <td>
                          {attribute.currentValueUtc === null ? "N/A" : formatDateShortLocal(attribute.currentValueUtc)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {/* Show the sensor panel if the asset has a monitoring device. */}
        {props.deviceType !== null && (
          <div
            data-test="sensor-highlights"
            className={props.controllerAttributes.length > 0 ? "col-12 col-lg-6" : "col-12"}
          >
            <div className={styles.body}>
              <div className={styles.titleBar}>
                <div className="row justify-content-center">
                  <div className={userCanOperate ? "col-8" : "col-12"}>
                    <label className={`${styles.title} me-2`}>
                      {props.deviceType !== null && props.deviceIdentifier !== null
                        ? `${props.deviceTypeName} ${props.deviceIdentifier} Highlights`
                        : "Sensor Highlights"}
                    </label>
                  </div>

                  {userCanOperate && (
                    <div className="col-4">
                      <SampleDeviceDataButton
                        deviceId={props.deviceId || 0}
                        onDeviceRead={() => props.onDeviceRead()}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                {props.genericSensorAttributes.length > 0 ||
                props.analogSensorAttributes.length > 0 ||
                props.digitalSensorAttributes.length > 0 ? (
                  <table className={`${styles.table} table mb-0 pb-0`}>
                    <thead className={styles.thead}>
                      <tr>
                        <th className={styles.th}>Name</th>
                        <th className={styles.th}>Value</th>
                        <th className={styles.th}></th>
                        <th className={styles.th}>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.genericSensorAttributes.map((attribute) => (
                        <tr
                          key={attribute.regAttributeId}
                          className={
                            props.staleAttributeCodes.includes(attribute.attributeCode)
                              ? styles.staleData
                              : styles.activeData
                          }
                          onClick={() =>
                            showHistoricalData(
                              attribute.attributeName,
                              attribute.attributeCode,
                              attribute.isHistorical,
                              attribute.currentValueUtc
                            )
                          }
                        >
                          <td>{attribute.attributeName}</td>
                          <td>
                            {attribute.currentValue === null
                              ? "N/A"
                              : `${attribute.currentValue} ${
                                  attribute.unitShortName === null ? "" : attribute.unitShortName
                                }`}
                          </td>
                          <td />
                          <td>
                            {attribute.currentValueUtc === null
                              ? "N/A"
                              : formatDateShortLocal(attribute.currentValueUtc)}
                          </td>
                        </tr>
                      ))}
                      {props.digitalSensorAttributes.map((attribute) => (
                        <tr
                          key={attribute.regAttributeId}
                          className={
                            props.staleAttributeCodes.includes(attribute.attributeCode)
                              ? styles.staleData
                              : styles.activeData
                          }
                          onClick={() =>
                            showHistoricalData(
                              attribute.attributeName,
                              attribute.attributeCode,
                              attribute.isHistorical,
                              attribute.currentValueUtc
                            )
                          }
                        >
                          <td>{attribute.attributeName}</td>
                          <td>
                            {attribute.currentValue === null
                              ? "N/A"
                              : `${attribute.currentValue} ${
                                  attribute.unitShortName === null ? "" : attribute.unitShortName
                                }`}
                          </td>
                          <td />
                          <td>
                            {attribute.currentValueUtc === null
                              ? "N/A"
                              : formatDateShortLocal(attribute.currentValueUtc)}
                          </td>
                        </tr>
                      ))}
                      {props.analogSensorAttributes.map((attribute) => (
                        <tr
                          key={attribute.regAttributeId}
                          className={
                            props.staleAttributeCodes.includes(attribute.attributeCode)
                              ? styles.staleData
                              : styles.activeData
                          }
                          onClick={() =>
                            showHistoricalData(
                              attribute.attributeName,
                              attribute.attributeCode,
                              attribute.isHistorical,
                              attribute.currentValueUtc
                            )
                          }
                        >
                          <td>{attribute.attributeName}</td>
                          <td>
                            {attribute.currentValue === null
                              ? "N/A"
                              : `${attribute.currentValue} ${
                                  attribute.unitShortName === null ? "" : attribute.unitShortName
                                }`}
                          </td>
                          <td>
                            <SensorConnectedIcon
                              attributeCode={attribute.attributeCode}
                              isConnected={attribute.isConnected}
                            />
                          </td>
                          <td>
                            {attribute.currentValueUtc === null
                              ? "N/A"
                              : formatDateShortLocal(attribute.currentValueUtc)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="mx-auto my-5 text-center">No monitoring device values reported yet.</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <DataHistoryModal
        showModal={showModal}
        isGraphable={true}
        name={attributeName}
        code={attributeCode}
        date={attributeDate}
        onClose={() => setShowModal(false)}
      />
    </Fragment>
  );
}

AttributeHighlights.propTypes = {
  controllerType: PropTypes.string,
  deviceType: PropTypes.string,
  deviceTypeName: PropTypes.string,
  deviceIdentifier: PropTypes.string,
  deviceId: PropTypes.number,
  controllerAttributes: PropTypes.array.isRequired,
  genericSensorAttributes: PropTypes.array.isRequired,
  digitalSensorAttributes: PropTypes.array.isRequired,
  analogSensorAttributes: PropTypes.array.isRequired,
  staleAttributeCodes: PropTypes.array.isRequired,
  operationIsAllowed: PropTypes.bool.isRequired,
  onDeviceRead: PropTypes.func.isRequired,
};

interface Props {
  controllerType: string | null;
  deviceType: string | null;
  deviceTypeName: string | null;
  deviceIdentifier: string | null;
  deviceId: number | null;
  controllerAttributes: Attribute[];
  genericSensorAttributes: Attribute[];
  digitalSensorAttributes: Attribute[];
  analogSensorAttributes: AnalogAttribute[];
  staleAttributeCodes: string[];
  operationIsAllowed: boolean;
  onDeviceRead: () => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  isHistorical: boolean;
}

interface AnalogAttribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  isHistorical: boolean;
  isConnected: boolean | null;
}
