// --------------------------------------------------------------
// Created On: 2023-09-29
// Author: Zachary Thomas
//
// Last Modified: 2024-05-21
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import formatDateLocal from "../../../utilities/time/formatDateLocal";
import styles from "./DeviceStatusRow.module.scss";

// A single row in a table that represents a monitoring device's status information.
export default function DeviceStatusRow(props: Props): Component {
  return (
    <tr className={`${styles.body} selectable-container`} onClick={() => props.onClick()}>
      <td>{props.deviceIdentifier}</td>
      <td>{props.deviceType}</td>
      <td>{props.activationStatus === null ? "N/A" : props.activationStatus}</td>
      <td>{props.connected === null ? "N/A" : props.connected}</td>
      <td>{props.modbusError === null ? "N/A" : props.modbusError}</td>
      <td>{props.rssi === null ? "N/A" : `${props.rssi} dBm`}</td>
      <td>{props.lastComm === null ? "N/A" : formatDateLocal(props.lastComm)}</td>
    </tr>
  );
}

DeviceStatusRow.propTypes = {
  deviceIdentifier: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  activationStatus: PropTypes.string,
  connected: PropTypes.string,
  modbusError: PropTypes.string,
  rssi: PropTypes.string,
  lastComm: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  deviceIdentifier: string;
  deviceType: string;
  activationStatus: string | null;
  connected: string | null;
  modbusError: string | null;
  rssi: string | null;
  lastComm: string | null;
  onClick: () => void;
}
