// --------------------------------------------------------------
// Created On: 2021-10-12
// Author: Zachary Thomas
//
// Last Modified: 2023-12-27
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CompanyListItem from "./CompanyListItem/CompanyListItem";
import styles from "./CompanyList.module.scss";

// A list of companies that can be interacted with.
export default function CompanyList(props: Props): Component {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filter, setFilter] = useState<string>("");

  // Apply the filter to the list of companies.
  useEffect(() => {
    if (filter.length > 0) {
      const matchedCompanies = props.companies.filter(
        (company) => company.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      );

      setCompanies(matchedCompanies);
    } else {
      setCompanies(props.companies);
    }
  }, [JSON.stringify(props.companies), filter]);

  return (
    <div className={styles.body}>
      <div className={`${styles.header} p-4`}>
        <div className="row align-items-top">
          <div className={`${styles.title} mb-4 col`}>
            <span className="align-top">Companies</span>
          </div>

          {/* Filter bar. */}
          <div className="input-group rounded">
            <input
              type="search"
              className="form-control rounded"
              placeholder="Filter results..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={styles.list}>
        {/* Visible list of interactive companies. */}
        {companies.map((company) => (
          <div key={company.companyId} className={`${styles.item} selectable-container`} data-test="company-list-item">
            <CompanyListItem companyId={company.companyId} name={company.name} isPackager={company.isPackager} />
          </div>
        ))}
      </div>
    </div>
  );
}

CompanyList.propTypes = {
  companies: PropTypes.array.isRequired,
};

interface Company {
  companyId: number;
  name: string;
  code: string;
  isPackager: boolean;
}

interface Props {
  companies: Company[];
}
