// --------------------------------------------------------------
// Created On: 2021-10-11
// Author: Zachary Thomas
//
// Last Modified: 2024-05-08
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment, useMemo } from "react";
import Card from "../../../components/Card/Card";
import PropTypes from "prop-types";
import AssetListItem from "./AssetListItem/AssetListItem";
import AssetListColumnHeader from "./AssetListColumnHeader/AssetListColumnHeader";
import deepCopy from "../../../utilities/deepCopy";
import calculateHighlightRuleValue from "../../../utilities/calculateHighlightRuleValue";
import calculateHighlightColor from "../../../utilities/calculateHighlightColor";
import stringIsValidNumber from "../../../utilities/stringIsValidNumber";
import isoUtcToIsoLocal from "../../../utilities/time/isoUtcToIsoLocal";
import CsvDownloadButton from "../../../components/CsvDownloadButton/CsvDownloadButton";
import styles from "./GroupListPage.module.scss";

// List of assets in current asset group with a view applied.
export default function GroupListPage(props: Props): Component {
  const [lastCommFilterOptions, setLastCommFilterOptions] = useState<FilterOption[]>([
    { title: "Has Communicated", checked: true },
    { title: "Has Not Communicated", checked: true },
  ]);
  const [deviceTypeFilterOptions, setDeviceTypeFilterOptions] = useState<FilterOption[]>([
    { title: "Cornell Co-Pilot", checked: true },
    { title: "Cornell Pulse", checked: true },
    { title: "Seeker C", checked: true },
    { title: "Seeker Solar", checked: true },
    { title: "No Device", checked: true },
  ]);
  const [deviceIdentifierFilterOptions, setDeviceIdentifierFilterOptions] = useState<FilterOption[]>([
    { title: "Has Device", checked: true },
    { title: "No Device", checked: true },
  ]);
  const [attributeOptionsMatrix, setAttributeOptionsMatrix] = useState<FilterOption[][]>([]);
  const [assets, setAssets] = useState<ViewAsset[]>([]);
  const [units, setUnits] = useState<string[]>([]);
  const [sortColumnNumber, setSortColumnNumber] = useState<number>(1);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const csvData = useMemo(() => getCsvData(), [JSON.stringify(assets), JSON.stringify(props.attributes)]);

  // Setup initial filter options for columns.
  useEffect(() => {
    const attributeOptionsMatrix: FilterOption[][] = [];
    const initialFilterOptions = [
      { title: "Highlighted", checked: true },
      { title: "Not Highlighted", checked: true },
      { title: "No Data", checked: true },
    ];
    props.attributes.forEach(() => attributeOptionsMatrix.push(initialFilterOptions));
    setAttributeOptionsMatrix(attributeOptionsMatrix);
  }, [JSON.stringify(props.attributes)]);

  // Perform filtering, sorting, and applying colors to assets.
  useEffect(() => {
    const units: string[] = [];
    let assetsDeepCopy = deepCopy(props.assets);

    // Sort assets.
    assetsDeepCopy = sortAssets(sortColumnNumber, sortAscending, assetsDeepCopy);

    // Get unit and highlighting information.
    props.attributes.forEach((attribute, i) => {
      if (attribute.units !== null) {
        units.push(attribute.units);
      } else {
        units.push("");
      }

      // Apply highlighting rules to asset attributes.
      const highlightingRulesDeepCopy = deepCopy(attribute.highlightingRules).reverse();
      calculateRuleValues(highlightingRulesDeepCopy, assetsDeepCopy, i);
      applyRuleColors(highlightingRulesDeepCopy, assetsDeepCopy, i);
    });

    // Filter assets.
    assetsDeepCopy = filterAssets(
      assetsDeepCopy,
      lastCommFilterOptions,
      deviceTypeFilterOptions,
      deviceIdentifierFilterOptions,
      attributeOptionsMatrix
    );

    setUnits(units);
    setAssets(assetsDeepCopy);
  }, [
    JSON.stringify(props.assets),
    JSON.stringify(props.attributes),
    JSON.stringify(lastCommFilterOptions),
    JSON.stringify(deviceTypeFilterOptions),
    JSON.stringify(deviceIdentifierFilterOptions),
    JSON.stringify(attributeOptionsMatrix),
    sortColumnNumber,
    sortAscending,
  ]);

  // Filter assets.
  function filterAssets(
    assets: ViewAsset[],
    lastCommFilters: FilterOption[],
    deviceTypeFilters: FilterOption[],
    deviceIdentifierFilters: FilterOption[],
    attributeMatrix: FilterOption[][]
  ): ViewAsset[] {
    let assetsShallowCopy = assets;
    // Filter based on last comm.
    lastCommFilters.forEach((filter) => {
      if (!filter.checked) {
        switch (filter.title) {
          case "Has Communicated":
            assetsShallowCopy = assetsShallowCopy.filter(
              (asset) => asset.lastComm === null || asset.lastComm.length === 0
            );
            break;
          case "Has Not Communicated":
            assetsShallowCopy = assetsShallowCopy.filter(
              (asset) => asset.lastComm !== null && asset.lastComm.length > 0
            );
            break;
        }
      }
    });
    // Filter based on device type.
    deviceTypeFilters.forEach((filter) => {
      if (!filter.checked) {
        switch (filter.title) {
          case "No Device":
            assetsShallowCopy = assetsShallowCopy.filter((asset) => asset.deviceType !== null);
            break;
          default:
            assetsShallowCopy = assetsShallowCopy.filter((asset) => asset.deviceType !== filter.title);
            break;
        }
      }
    });
    // Filter based on device identifier.
    deviceIdentifierFilters.forEach((filter) => {
      if (!filter.checked) {
        switch (filter.title) {
          case "Has Device":
            assetsShallowCopy = assetsShallowCopy.filter((asset) => asset.deviceIdentifier === null);
            break;
          case "No Device":
            assetsShallowCopy = assetsShallowCopy.filter((asset) => asset.deviceIdentifier !== null);
            break;
        }
      }
    });
    // Filter based on attributes.
    attributeMatrix.forEach((filterOptions, i) => {
      filterOptions.forEach((filter) => {
        if (!filter.checked) {
          switch (filter.title) {
            case "Highlighted":
              assetsShallowCopy = assetsShallowCopy.filter((asset) => {
                if (asset.attributes[i] !== undefined) {
                  return asset.attributes[i].colorHexCode === undefined;
                }
              });
              break;
            case "Not Highlighted":
              assetsShallowCopy = assetsShallowCopy.filter((asset) => {
                if (asset.attributes[i] !== undefined) {
                  return asset.attributes[i].colorHexCode !== undefined;
                }
              });
              break;
            case "No Data":
              assetsShallowCopy = assetsShallowCopy.filter((asset) => {
                if (asset.attributes[i] !== undefined) {
                  return asset.attributes[i].value !== null;
                }
              });
              break;
          }
        }
      });
    });
    return assetsShallowCopy;
  }

  // Sort assets.
  function sortAssets(columnNumber: number, isAscending: boolean, assets: ViewAsset[]): ViewAsset[] {
    let sortingOrder = -1;
    if (isAscending) {
      sortingOrder = 1;
    }
    switch (columnNumber) {
      case 1: {
        // Name column.
        assets.sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
          if (nameA < nameB) {
            return -1 * sortingOrder;
          } else if (nameA > nameB) {
            return 1 * sortingOrder;
          } else {
            return 0;
          }
        });
        break;
      }
      case 2: {
        // Last communications column.
        assets.sort((a, b) => {
          const lastCommA = a.lastComm;
          const lastCommB = b.lastComm;
          if (lastCommA === null) {
            return -1 * sortingOrder;
          } else if (lastCommB === null) {
            return 1 * sortingOrder;
          } else if (lastCommA < lastCommB) {
            return -1 * sortingOrder;
          } else if (lastCommA > lastCommB) {
            return 1 * sortingOrder;
          } else {
            return 0;
          }
        });
        break;
      }
      case 3: {
        // Device type column.
        assets.sort((a, b) => {
          const deviceTypeA = a.deviceType;
          const deviceTypeB = b.deviceType;
          if (deviceTypeA === null) {
            return -1 * sortingOrder;
          } else if (deviceTypeB === null) {
            return 1 * sortingOrder;
          } else if (deviceTypeA < deviceTypeB) {
            return -1 * sortingOrder;
          } else if (deviceTypeA > deviceTypeB) {
            return 1 * sortingOrder;
          } else {
            return 0;
          }
        });
        break;
      }
      case 4: {
        // Device identifier column.
        assets.sort((a, b) => {
          const deviceIdentifierA = a.deviceIdentifier;
          const deviceIdentifierB = b.deviceIdentifier;
          if (deviceIdentifierA === null) {
            return -1 * sortingOrder;
          } else if (deviceIdentifierB === null) {
            return 1 * sortingOrder;
          } else if (deviceIdentifierA < deviceIdentifierB) {
            return -1 * sortingOrder;
          } else if (deviceIdentifierA > deviceIdentifierB) {
            return 1 * sortingOrder;
          } else {
            return 0;
          }
        });
        break;
      }
      default: {
        // Attribute columns.
        if (columnNumber >= 5) {
          const attributeIndex = columnNumber - 5;
          assets.sort((a, b) => {
            if (a.attributes.length > attributeIndex && b.attributes.length > attributeIndex) {
              let attributeA;
              if (stringIsValidNumber(a.attributes[attributeIndex].value)) {
                attributeA = parseFloat(a.attributes[attributeIndex].value);
              } else {
                attributeA = a.attributes[attributeIndex].value;
              }
              let attributeB;
              if (stringIsValidNumber(b.attributes[attributeIndex].value)) {
                attributeB = parseFloat(b.attributes[attributeIndex].value);
              } else {
                attributeB = b.attributes[attributeIndex].value;
              }
              if (attributeA === null) {
                return -1 * sortingOrder;
              } else if (attributeB === null) {
                return 1 * sortingOrder;
              } else if (attributeA < attributeB) {
                return -1 * sortingOrder;
              } else if (attributeA > attributeB) {
                return 1 * sortingOrder;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });
        }
        break;
      }
    }
    return assets;
  }

  // Calculate highlighting values for dynamic rules that require viewing a set of data.
  function calculateRuleValues(
    highlightingRules: ViewHighlightingRule[],
    assets: ViewAsset[],
    attributeIndex: number
  ): void {
    // Don't bother calculating anything if there are no highlighting rules for this attribute.
    if (highlightingRules.length === 0) {
      return;
    }

    // Get data points from each asset for the current attribute.
    const dataPoints: number[] = [];
    assets.forEach((asset) => {
      if (asset.attributes.length > attributeIndex && stringIsValidNumber(asset.attributes[attributeIndex].value)) {
        dataPoints.push(parseFloat(asset.attributes[attributeIndex].value));
      }
    });
    dataPoints.sort((a, b) => a - b);

    // Use data points to calculate dynamic highlighting rules.
    highlightingRules.forEach((highlightingRule) => {
      highlightingRule.value = calculateHighlightRuleValue(highlightingRule, dataPoints);
    });
  }

  // Apply highlighting colors to attribute values.
  function applyRuleColors(
    highlightingRules: ViewHighlightingRule[],
    assets: ViewAsset[],
    attributeIndex: number
  ): void {
    // Don't bother applying colors to anything if there are no highlighting rules for this attribute.
    if (highlightingRules.length === 0) {
      return;
    }

    // Apply colors to each asset for the current attribute.
    assets.forEach((asset) => {
      if (
        asset.attributes.length > attributeIndex &&
        asset.attributes[attributeIndex] !== null &&
        asset.attributes[attributeIndex].value !== null
      ) {
        // Handle boolean values.
        let stringValue: string = asset.attributes[attributeIndex].value;
        if (stringValue.toLowerCase() === "true") {
          stringValue = "1";
        } else if (stringValue.toLowerCase() === "false") {
          stringValue = "0";
        }

        if (stringIsValidNumber(stringValue)) {
          const color = calculateHighlightColor(highlightingRules, parseFloat(stringValue));
          if (color !== null) {
            asset.attributes[attributeIndex].colorHexCode = color;
          }
        }
      }
    });
  }

  // Print the current view as a table.
  function printViewTable(): void {
    const viewTable = document.getElementById("printable-view");
    if (viewTable !== null && viewTable.firstChild !== null) {
      const firstChild = viewTable.firstChild;
      const cloned = firstChild.cloneNode(true) as Element;
      document.body.appendChild(cloned);
      cloned.classList.add(styles.printable);
      window.print();
      document.body.removeChild(cloned);
    }
  }

  // Update what column and direction to sort on.
  function updateSortSettings(columnNumber: number, isAscending: boolean) {
    setSortColumnNumber(columnNumber);
    setSortAscending(isAscending);
  }

  // Updates a filter rule to be checked or unchecked.
  function updateFilterOption(
    columnNumber: number,
    title: string,
    checked: boolean,
    mouseEvent: React.MouseEvent<HTMLElement>
  ): void {
    mouseEvent.stopPropagation();
    switch (columnNumber) {
      case 2: {
        // Last comm column.
        const lastCommFilterOptionsDeepCopy = deepCopy(lastCommFilterOptions);
        const filterOption = lastCommFilterOptionsDeepCopy.find((filterOption) => filterOption.title === title);
        if (filterOption !== undefined) {
          filterOption.checked = checked;
          setLastCommFilterOptions(lastCommFilterOptionsDeepCopy);
        }
        break;
      }
      case 3: {
        // Device type column.
        const deviceTypeFilterOptionsDeepCopy = deepCopy(deviceTypeFilterOptions);
        const filterOption = deviceTypeFilterOptionsDeepCopy.find((filterOption) => filterOption.title === title);
        if (filterOption !== undefined) {
          filterOption.checked = checked;
          setDeviceTypeFilterOptions(deviceTypeFilterOptionsDeepCopy);
        }
        break;
      }
      case 4: {
        // Device identifier column.
        const deviceIdentifierFilterOptionsDeepCopy = deepCopy(deviceIdentifierFilterOptions);
        const filterOption = deviceIdentifierFilterOptionsDeepCopy.find((filterOption) => filterOption.title === title);
        if (filterOption !== undefined) {
          filterOption.checked = checked;
          setDeviceIdentifierFilterOptions(deviceIdentifierFilterOptionsDeepCopy);
        }
        break;
      }
      default: {
        // Attribute columns.
        if (columnNumber >= 5) {
          const matrixIndex = columnNumber - 5;
          const attributeOptionsMatrixDeepCopy = deepCopy(attributeOptionsMatrix);
          if (attributeOptionsMatrixDeepCopy.length > matrixIndex) {
            const filterOption = attributeOptionsMatrixDeepCopy[matrixIndex].find(
              (filterOption) => filterOption.title === title
            );
            if (filterOption !== undefined) {
              filterOption.checked = checked;
              setAttributeOptionsMatrix(attributeOptionsMatrixDeepCopy);
            }
          }
        }
        break;
      }
    }
  }

  // Returns the data from the current view in a CSV format.
  function getCsvData(): string[][] {
    let csvData: string[][] = [];
    // Get header row.
    const headers = ["Name", "Device Type", "Device Identifier"];
    props.attributes.forEach((attribute) => {
      headers.push(attribute.name);
      headers.push(`${attribute.name} Units`);
    });
    headers.push(`Last Comm - ${Intl.DateTimeFormat().resolvedOptions().timeZone}`);
    csvData.push(headers);
    // Get asset data rows.
    const assetDataRows: string[][] = [];
    assets.forEach((asset) => {
      const assetDataRow: string[] = [];
      assetDataRow.push(asset.name);
      assetDataRow.push(asset.deviceType || "");
      assetDataRow.push(asset.deviceIdentifier || "");
      asset.attributes.forEach((attribute, i) => {
        assetDataRow.push(attribute.value);
        if (props.attributes.length > i) {
          assetDataRow.push(props.attributes[i].units);
        } else {
          assetDataRow.push("");
        }
      });
      if (asset.lastComm === null) {
        assetDataRow.push("");
      } else {
        assetDataRow.push(isoUtcToIsoLocal(asset.lastComm.replace(" ", "T")));
      }
      assetDataRows.push(assetDataRow);
    });
    csvData = [...csvData, ...assetDataRows];
    return csvData;
  }

  return (
    <div className="shadow-sm mx-4 my-2">
      {props.assets.length ? (
        <div className="table-responsive" id="printable-view">
          <table className={`${styles.table} table mb-0 pb-0`}>
            <thead className={styles.thead}>
              <tr>
                <AssetListColumnHeader
                  title="Name"
                  filterOptions={[]}
                  sortAscendingText="Sort A-Z"
                  sortDescendingText="Sort Z-A"
                  onChangeFilter={() => {
                    /* Do nothing */
                  }}
                  onSort={(isAscending) => updateSortSettings(1, isAscending)}
                />
                <AssetListColumnHeader
                  title="Device Type"
                  filterOptions={deviceTypeFilterOptions}
                  sortAscendingText="Sort A-Z"
                  sortDescendingText="Sort Z-A"
                  onChangeFilter={(title, checked, e) => {
                    updateFilterOption(3, title, checked, e);
                  }}
                  onSort={(isAscending) => updateSortSettings(3, isAscending)}
                />
                <AssetListColumnHeader
                  title="Device Identifier"
                  filterOptions={deviceIdentifierFilterOptions}
                  sortAscendingText="Sort A-Z"
                  sortDescendingText="Sort Z-A"
                  onChangeFilter={(title, checked, e) => {
                    updateFilterOption(4, title, checked, e);
                  }}
                  onSort={(isAscending) => updateSortSettings(4, isAscending)}
                />
                {props.attributes.map((attribute, i) => (
                  <AssetListColumnHeader
                    key={attribute.attributeId}
                    title={attribute.name}
                    filterOptions={attributeOptionsMatrix.length > i ? attributeOptionsMatrix[i] : []}
                    onChangeFilter={(title, checked, e) => {
                      updateFilterOption(5 + i, title, checked, e);
                    }}
                    onSort={(isAscending) => updateSortSettings(5 + i, isAscending)}
                  />
                ))}
                <AssetListColumnHeader
                  title="Last Comm"
                  filterOptions={lastCommFilterOptions}
                  sortAscendingText="Sort Oldest to Newest"
                  sortDescendingText="Sort Newest to Oldest"
                  onChangeFilter={(title, checked, e) => {
                    updateFilterOption(2, title, checked, e);
                  }}
                  onSort={(isAscending) => updateSortSettings(2, isAscending)}
                />

                {/* Dropdown for printing table or exporting CSV. */}
                <th className={styles.printHeader}>
                  <div className={`${styles.dropdownContainer} dropdown`}>
                    <button
                      className={`${styles.printButton} btn btn-secondary float-end`}
                      type="button"
                      data-boundary="viewport"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-fw fa-bars" />
                    </button>

                    {/* Drop down options. */}
                    <div className={`${styles.dropdownMenu} dropdown-menu dropdown-menu-end`}>
                      <button className={`${styles.option} dropdown-item`} onClick={() => printViewTable()}>
                        Print Data
                      </button>
                      <button className={`${styles.option} dropdown-item`} onClick={() => console.log(csvData)}>
                        <CsvDownloadButton fileName="Asset List Data" data={csvData}>
                          Export CSV Data
                        </CsvDownloadButton>
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {assets.map((asset) => (
                <AssetListItem
                  key={asset.assetId}
                  assetId={asset.assetId}
                  name={asset.name}
                  nickname={asset.nickname}
                  deviceType={asset.deviceType}
                  deviceIdentifier={asset.deviceIdentifier}
                  attributes={asset.attributes}
                  units={units}
                  lastComm={asset.lastComm}
                />
              ))}
            </tbody>
          </table>
          {assets.length === 0 && <div className={styles.tableEmptyList}>No assets to display.</div>}
        </div>
      ) : (
        <Fragment>
          {!props.loading && (
            <Card title="Assets">
              <div className={`${styles.emptyList} my-5`}>
                {props.errorMessage.length > 0 && <span>{props.errorMessage}</span>}
                {props.errorMessage.length === 0 && props.viewId > 0 && (
                  <span>There are no assets in this asset group.</span>
                )}
                {props.errorMessage.length === 0 && props.viewId <= 0 && (
                  <span>No view selected. Create a new view to see asset data.</span>
                )}
              </div>
            </Card>
          )}
        </Fragment>
      )}
    </div>
  );
}

GroupListPage.propTypes = {
  assets: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  viewId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

interface Props {
  assets: ViewAsset[];
  attributes: ViewAttribute[];
  viewId: number;
  loading: boolean;
  errorMessage: string;
}

interface ViewAsset {
  assetId: number;
  name: string;
  nickname: string;
  deviceType: string;
  deviceIdentifier: string;
  attributes: ViewAssetAttribute[];
  lastComm: string | null;
}

interface ViewAssetAttribute {
  attributeId: number;
  value: string;
  colorHexCode?: string;
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  units: string;
  highlightingRules: ViewHighlightingRule[];
}

interface ViewHighlightingRule {
  comparator: string;
  valueType: string;
  value: string;
  colorHexCode: string;
}

interface FilterOption {
  title: string;
  checked: boolean;
}
