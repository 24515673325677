// --------------------------------------------------------------
// Created On: 2023-07-20
// Author: Zachary Thomas
//
// Last Modified: 2024-06-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import PropTypes from "prop-types";
import {
  BREAKPOINT_EXTRA_EXTRA_LARGE,
  BREAKPOINT_EXTRA_LARGE,
  BREAKPOINT_SMALL,
} from "../../../../constants/breakpoints";
import { CONTROLLER_APP_VFD, COPILOT_DEVICE_TYPE } from "../../../../constants/miscellaneous";
import { LOCK_REMOTE_CONTROL_PERMISSION } from "../../../../constants/permissions";
import userHasPermission from "../../../../utilities/userHasPermission";
import SpeedChangeButton from "./SpeedChangeButton/SpeedChangeButton";
import InfoModal from "../../../../components/InfoModal/InfoModal";
import RemoteOperationDisclaimer from "./RemoteOperationDisclaimer/RemoteOperationDisclaimer";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import styles from "./RemoteOperationPanel.module.scss";

// Panel for remotely operating an asset.
export default function RemoteOperationPanel(props: Props): Component {
  const windowDimensions = useWindowDimensions();
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [manualRpm, setManualRpm] = useState<string>("");
  const [showClearModal, setShowClearModal] = useState<boolean>(false);
  const [showRestartModal, setShowRestartModal] = useState<boolean>(false);

  // Apply the manual RPM value if it is a valid number.
  function applyManualRpm(rpm: string): void {
    rpm = rpm.trim();
    if (rpm.length === 0) {
      props.onError("Invalid RPM entered.");
      return;
    }
    const rpmNumber = parseInt(rpm, 10);
    if (Number.isNaN(rpmNumber)) {
      props.onError("Entered RPM is not an integer.");
      return;
    } else if (rpmNumber < 0) {
      props.onError("Entered RPM must be a positive number.");
      return;
    }
    props.onChangeSpeed(rpmNumber);
  }

  return (
    <div className={styles.body}>
      <div className={styles.titleBar}>
        <label className={styles.title}>{props.assetName} Remote Operation</label>
      </div>

      {/* A controller is hooked up and there is not a lock in place. */}
      {props.controllerModel !== null && (
        <Fragment>
          {["ACS580", "ACS550"].includes(props.controllerModel) && (
            <div className="container">
              <label className={styles.controlLabel}>Communication Mode</label>
              <div className="row align-items-center">
                <div className={`col ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary me-5"
                    type="button"
                    onClick={() => props.onChangeCommunicationMode("Modbus Control")}
                  >
                    Modbus
                  </button>
                </div>
                <div className={`col ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary me-5"
                    type="button"
                    onClick={() => props.onChangeCommunicationMode("Analog/Digital Control")}
                  >
                    Analog/Digital
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <label className={styles.controlLabel}>Running Status</label>
            <div className="row align-items-center">
              <div className={`col px-3 ${styles.controlColumn}`}>
                <button
                  className="btn btn-success w-100"
                  type="button"
                  disabled={props.loading}
                  onClick={() => props.onChangeRunningStatus("ON")}
                >
                  ON
                </button>
              </div>
              <div className={`col px-3 ${styles.controlColumn}`}>
                <button
                  className="btn btn-danger w-100"
                  type="button"
                  disabled={props.loading}
                  onClick={() => props.onChangeRunningStatus("OFF")}
                >
                  OFF
                </button>
              </div>
            </div>
          </div>

          <div className="container">
            <label className={styles.controlLabel}>Speed Change</label>
            {props.controllerApplicationType === CONTROLLER_APP_VFD ? (
              <div className="row align-items-center">
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1000}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1200}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1300}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1400}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1500}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1600}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1800}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={2000}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
              </div>
            ) : (
              <div className="row align-items-center">
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={800}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1000}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1200}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1300}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1400}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1500}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1600}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
                <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                  <SpeedChangeButton
                    rpmValue={1800}
                    showHertz={false}
                    disabled={props.loading}
                    onClick={(rpm) => props.onChangeSpeed(rpm)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="container">
            <div className="row">
              <div className="col-9 g-0 ps-3">
                <input
                  className={`${styles.manualRpm} align-self-center form-control`}
                  type="text"
                  maxLength={10}
                  value={manualRpm}
                  onChange={(e) => setManualRpm(e.target.value)}
                />
              </div>
              <div className="col-3 g-0 pe-3">
                <button
                  className={`${styles.manualRpmButton} align-self-center btn btn-primary`}
                  type="button"
                  disabled={props.loading}
                  onClick={() => applyManualRpm(manualRpm)}
                >
                  {windowDimensions.width > BREAKPOINT_SMALL &&
                  (windowDimensions.width < BREAKPOINT_EXTRA_LARGE ||
                    windowDimensions.width > BREAKPOINT_EXTRA_EXTRA_LARGE)
                    ? "Set RPM"
                    : "RPM"}
                </button>
              </div>
            </div>
          </div>

          {props.deviceType === COPILOT_DEVICE_TYPE && (
            <div className="container">
              <label className={styles.controlLabel}>Monitoring Device Operations</label>
              <div className="row align-items-center">
                <div className={`col px-3 ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    disabled={props.loading}
                    onClick={() => setShowClearModal(true)}
                  >
                    {windowDimensions.width > BREAKPOINT_SMALL ? "Clear Device" : "Clear"}
                  </button>
                </div>

                <div className={`col px-3 ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    disabled={props.loading}
                    onClick={() => setShowRestartModal(true)}
                  >
                    {windowDimensions.width > BREAKPOINT_SMALL ? "Restart Device" : "Restart"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {userHasPermission([[LOCK_REMOTE_CONTROL_PERMISSION]]) && (
            <div className="container">
              <label className={styles.controlLabel}>Remote Lock</label>
              <div className="row align-items-center">
                <div className={`col px-3 ${styles.controlColumn}`}>
                  {!props.isLockedOut && (
                    <button
                      className="btn btn-danger w-100"
                      type="button"
                      disabled={props.loading}
                      onClick={() => props.onChangeLock(true)}
                    >
                      {windowDimensions.width > BREAKPOINT_SMALL ? "Lock Remote Control" : "Lock"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          <ConfirmModal
            showModal={showClearModal}
            title={`Clear failed commands?`}
            content={
              "Clearing failed commands on the monitoring device may resolve issues where new commands are failing to be received. " +
              "Do you want to clear failed commands?"
            }
            yesText="Clear Failed Commands"
            noText="Cancel"
            danger={true}
            onClose={() => setShowClearModal(false)}
            onYes={() => {
              props.onClearFailedCommands();
              setShowClearModal(false);
            }}
            onNo={() => setShowClearModal(false)}
          />

          <ConfirmModal
            showModal={showRestartModal}
            title={`Restart monitoring device?`}
            content={
              "Restarting the monitoring device may resolve issues where new commands are failing to be received. " +
              "The monitoring device will not be accessible and will not report data while it is restarting. " +
              "Do you want to restart the monitoring device?"
            }
            yesText="Restart Monitoring Device"
            noText="Cancel"
            danger={true}
            onClose={() => setShowRestartModal(false)}
            onYes={() => {
              props.onRestartMonitoringDevice();
              setShowRestartModal(false);
            }}
            onNo={() => setShowRestartModal(false)}
          />

          <label className={styles.controlLabel}>
            <InfoModal
              showModal={showDisclaimer}
              title="Remote Operation Disclaimer"
              linkMessage="(Remote Operation Disclaimer)"
              darkBackground={true}
              onClick={() => setShowDisclaimer(true)}
              onClose={() => setShowDisclaimer(false)}
            >
              <RemoteOperationDisclaimer />
            </InfoModal>
          </label>
        </Fragment>
      )}

      {props.controllerModel === null && (
        <div className={`${styles.info} py-5 px-4`}>
          <h5 className="mb-4">A controller must be attached to this asset to perform remote operations.</h5>
        </div>
      )}
    </div>
  );
}

RemoteOperationPanel.propTypes = {
  assetName: PropTypes.string.isRequired,
  controllerApplicationType: PropTypes.string,
  controllerModel: PropTypes.string,
  deviceType: PropTypes.string,
  isLockedOut: PropTypes.bool.isRequired,
  canUnlock: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onChangeCommunicationMode: PropTypes.func.isRequired,
  onChangeSpeed: PropTypes.func.isRequired,
  onChangeRunningStatus: PropTypes.func.isRequired,
  onClearFailedCommands: PropTypes.func.isRequired,
  onRestartMonitoringDevice: PropTypes.func.isRequired,
  onChangeLock: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

interface Props {
  assetName: string;
  controllerApplicationType: string | null;
  controllerModel: string | null;
  deviceType: string | null;
  isLockedOut: boolean;
  canUnlock: boolean;
  loading: boolean;
  onChangeCommunicationMode: (command: string) => void;
  onChangeSpeed: (command: number) => void;
  onChangeRunningStatus: (command: string) => void;
  onClearFailedCommands: () => void;
  onRestartMonitoringDevice: () => void;
  onChangeLock: (isLocked: boolean) => void;
  onError: (error: string) => void;
}
