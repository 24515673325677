// --------------------------------------------------------------
// Created On: 2022-06-16
// Author: Zachary Thomas
//
// Last Modified: 2024-04-11
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import useApi from "../../../hooks/useApi";
import LocationDateSelector from "./LocationDateSelector/LocationDateSelector";
import LocationMap from "./LocationMap/LocationMap";
import nowToLocalIso from "../../../utilities/time/nowToLocalIso";
import Spinner from "../../../components/Spinner/Spinner";
import {
  LOCATION_STARTING_DATE_OFFSET,
  LOCATION_ENDING_DATE_OFFSET,
  API,
  MS_PER_MINUTE,
} from "../../../constants/miscellaneous";
import { LOCATION_ATTRIBUTE, GPS_TRAVEL_SPEED_ATTRIBUTE } from "../../../constants/attributes";
import isoAddDays from "../../../utilities/time/isoAddDays";
import isoUtcToIsoLocal from "../../../utilities/time/isoUtcToIsoLocal";
import cleanLocationHistory from "../../../api/cleanLocationHistory";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import deepCopy from "../../../utilities/deepCopy";

// Container for viewing location history data for a single asset.
export default function LocationMapContainer(props: Props): Component {
  const [startDate, setStartDate] = useState<string>(calculateStartDate(props.locationAttribute));
  const [endDate, setEndDate] = useState<string>(nowToLocalIso(LOCATION_ENDING_DATE_OFFSET).split("T")[0]);
  const [startDateOffset, setStartDateOffset] = useState<string>("");
  const [endDateOffset, setEndDateOffset] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [frequencyMinutes, setFrequencyMinutes] = useState<number>(0);
  const [locations, setLocations] = useState<LocationHistoryPoint[]>([]);
  const [speedDataPoints, setSpeedDataPoints] = useState<HistoricalDataPoint[]>([]);
  const [speedUnitName, setSpeedUnitName] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);

  // Get location data from API.
  useApi(
    () => {
      if (props.assetId > 0 && startDateOffset !== "" && endDateOffset !== "") {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/${props.assetId}/history`,
      body: {
        startDate: startDateOffset,
        endDate: endDateOffset,
        attributeCode: LOCATION_ATTRIBUTE,
      },
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        const cleanedResponseBody = cleanLocationHistory(responseBody);
        setLocations(cleanedResponseBody.dataPoints);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [props.assetId, startDateOffset, endDateOffset]
  );

  // Get travel speed data from API.
  useApi(
    () => {
      if (props.assetId > 0 && startDateOffset !== "" && endDateOffset !== "") {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/${props.assetId}/history`,
      body: {
        startDate: startDateOffset,
        endDate: endDateOffset,
        attributeCode: GPS_TRAVEL_SPEED_ATTRIBUTE,
      },
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setSpeedDataPoints(responseBody.dataPoints);
        setSpeedUnitName(responseBody.unitShortName);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [props.assetId, startDateOffset, endDateOffset]
  );

  // Combine location data and GPS speed into one array of objects.
  function getCombinedLocationAndSpeedDataPoints(
    locations: LocationHistoryPoint[],
    speedDataPoints: HistoricalDataPoint[]
  ): LocationHistoryPoint[] {
    const locationsWithSpeed: LocationHistoryPoint[] = [];
    const locationSpeedMap: LocationTimeMap = {};

    // Use a hash table to match times between location timestamps and speed.
    locations.forEach((location) => {
      locationSpeedMap[location.changeDate] = deepCopy(location);
      const speedDataPoint = speedDataPoints.find((speedDataPoint) => {
        const timeDifference = Math.abs(
          new Date(location.changeDate).getTime() - new Date(speedDataPoint.dateTimeUtc).getTime()
        );
        return timeDifference < MS_PER_MINUTE;
      });
      if (speedDataPoint !== undefined) {
        locationSpeedMap[location.changeDate].speed = speedDataPoint.value;
      }
    });

    // Now that all of the values are mapped to the hash table, create an array of the resulting objects.
    Object.keys(locationSpeedMap).forEach((locationSpeedMapKey) =>
      locationsWithSpeed.push(locationSpeedMap[locationSpeedMapKey])
    );

    return locationsWithSpeed;
  }

  // When start or end date is changed, update the offset date that is used by the API.
  useEffect(() => {
    if (startDate !== null && startDate.length > 0 && endDate !== null && endDate.length > 0) {
      setStartDateOffset(isoAddDays(`${startDate}T00:00:00`, -1).split(".")[0]);
      setEndDateOffset(isoAddDays(`${endDate}T00:00:00`, 1).split(".")[0]);
    }
  }, [startDate, endDate]);

  // Compare the last time a reading was taken and the default starting date, select the one that is oldest.
  function calculateStartDate(locationAttribute: Attribute | null): string {
    const defaultStartDate = nowToLocalIso(LOCATION_STARTING_DATE_OFFSET).split("T")[0];
    if (locationAttribute !== null) {
      const locationDateTime = isoUtcToIsoLocal(locationAttribute.currentValueUtc);
      const locationDate = locationDateTime.split("T")[0];
      if (locationDate < defaultStartDate) {
        return locationDate;
      }
    }
    return defaultStartDate;
  }

  return (
    <div>
      <Spinner loading={loading} />

      <LocationDateSelector
        start={startDate}
        end={endDate}
        frequencyMinutes={frequencyMinutes}
        locationAttribute={props.locationAttribute}
        onChangeStart={(startDate) => setStartDate(startDate)}
        onChangeEnd={(endDate) => setEndDate(endDate)}
        onChangeFrequencyMinutes={(frequencyMinutes) => setFrequencyMinutes(frequencyMinutes)}
      />
      <LocationMap
        locations={getCombinedLocationAndSpeedDataPoints(locations, speedDataPoints)}
        speedUnitName={speedUnitName}
        startDate={startDate}
        endDate={endDate}
        frequencyMinutes={frequencyMinutes}
        assetId={props.assetId}
        assetName={props.assetName}
        failedToLoad={failedToLoad}
      />
    </div>
  );
}

LocationMapContainer.propTypes = {
  assetId: PropTypes.number.isRequired,
  assetName: PropTypes.string.isRequired,
  locationDate: PropTypes.string,
};

interface Props {
  assetId: number;
  assetName: string;
  locationAttribute: Attribute | null;
}

interface ResponseBody {
  dataPoints: HistoricalDataPoint[];
  unitShortName: string;
}

interface LocationTimeMap {
  [key: string]: LocationHistoryPoint;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
}
