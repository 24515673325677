// --------------------------------------------------------------
// Created On: 2023-06-01
// Author: Zachary Thomas
//
// Last Modified: 2023-06-27
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants related to the product visual component.
export const PUMP_MAX_FRAMES = 10;
export const PUMP_MS_BETWEEN_FRAMES = 100;
