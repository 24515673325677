// --------------------------------------------------------------
// Created On: 2023-08-29
// Author: Zachary Thomas
//
// Last Modified: 2024-07-01
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import { ENGINE_RUNNING_ATTRIBUTE } from "../../../../constants/attributes";
import PropTypes from "prop-types";
import DataHistoryModal from "../../AssetDataContainer/DataHistoryModal/DataHistoryModal";
import isoUtcToIsoLocal from "../../../../utilities/time/isoUtcToIsoLocal";
import formatDateShortLocal from "../../../../utilities/time/formatDateShortLocal";
import styles from "./ProductDataTable.module.scss";

// A table with highlighted attributes that are relevant for the highlighted product type.
export default function ProductDataTable(props: Props): Component {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [attributeName, setAttributeName] = useState<string>("");
  const [attributeCode, setAttributeCode] = useState<string>("");
  const [attributeDate, setAttributeDate] = useState<string | null>(null);

  // Show historical data for the individual data attribute.
  function showHistoricalData(name: string, code: string, isHistorical: boolean, mostRecentUtc?: string): void {
    if (isHistorical) {
      setShowModal(true);
      setAttributeName(name);
      setAttributeCode(code);
      if (typeof mostRecentUtc === "string") {
        const mostRecentLocal = isoUtcToIsoLocal(mostRecentUtc);
        const mostRecentSections = mostRecentLocal.split("T");
        setAttributeDate(mostRecentSections[0]);
      } else {
        setAttributeDate(null);
      }
    }
  }

  return (
    <Fragment>
      <div data-test="product-data-table-container" className="col-12 col-lg-6">
        {props.attributes.length > 0 && (
          <div className={styles.tableContent}>
            <table className={`${styles.table} table mb-0 pb-0`}>
              <thead className={styles.thead}>
                <tr>
                  <th className={styles.th}>Name</th>
                  <th className={styles.th}>Value</th>
                  <th className={styles.th}>Time</th>
                </tr>
              </thead>
              <tbody>
                {/* Show attributes. If engine running is returned, hide it if a value is not included. */}
                {props.attributes
                  .filter(
                    (attribute) =>
                      attribute.attributeCode !== ENGINE_RUNNING_ATTRIBUTE || attribute.currentValue !== null
                  )
                  .map((attribute, i) => (
                    <tr
                      key={attribute.regAttributeId}
                      className={
                        props.staleAttributeCodes.includes(attribute.attributeCode)
                          ? styles.staleData
                          : styles.activeData
                      }
                      onMouseOver={() => props.onUpdateHoverList(i, true)}
                      onMouseOut={() => props.onUpdateHoverList(i, false)}
                      onClick={() =>
                        showHistoricalData(
                          attribute.attributeName,
                          attribute.attributeCode,
                          attribute.isHistorical || false,
                          attribute.currentValueUtc
                        )
                      }
                    >
                      <td>{attribute.attributeName}</td>
                      <td>
                        {attribute.currentValue === null
                          ? "N/A"
                          : `${attribute.currentValue} ${
                              attribute.unitShortName === null ? "" : attribute.unitShortName
                            }`}
                      </td>
                      <td>
                        {attribute.currentValueUtc === null ? "N/A" : formatDateShortLocal(attribute.currentValueUtc)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DataHistoryModal
        showModal={showModal}
        isGraphable={true}
        name={attributeName}
        code={attributeCode}
        date={attributeDate}
        onClose={() => setShowModal(false)}
      />
    </Fragment>
  );
}

ProductDataTable.propTypes = {
  attributes: PropTypes.array.isRequired,
  staleAttributeCodes: PropTypes.array.isRequired,
  onUpdateHoverList: PropTypes.func.isRequired,
};

interface Props {
  attributes: Attribute[];
  staleAttributeCodes: string[];
  onUpdateHoverList: (index: number, isHovering: boolean) => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string | null;
  unitLongName: string | null;
  unitDisplaySymbol: string | null;
  currentValue: string;
  currentValueUtc: string;
  icon?: string;
  isHistorical?: boolean;
}
