// --------------------------------------------------------------
// Created On: 2023-12-04
// Author: Zachary Thomas
//
// Last Modified: 2024-08-22
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState, useReducer, useMemo, useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import useApi from "../../../hooks/useApi";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import AssetThresholdControl from "./AssetThresholdControl/AssetThresholdControl";
import AddAssetThreshold from "./AddAssetThreshold/AddAssetThreshold";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import deepCopy from "../../../utilities/deepCopy";
import SelectAssetsControl from "./SelectAssetsControl/SelectAssetsControl";
import SelectAssetgroupsControl from "./SelectAssetgroupsControl/SelectAssetgroupsControl";
import { API, MAX_SAME_ATTRIBUTE_COUNT_FOR_ALERT } from "../../../constants/miscellaneous";
import stringIsValidNumber from "../../../utilities/stringIsValidNumber";
import apiRequest from "../../../utilities/api/apiRequest";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import AlertEscalationModal from "../AlertEscalationModal/AlertEscalationModal";
import PropTypes from "prop-types";
import { THRESHOLD_TYPES } from "../../../constants/reducerActions";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import AssociationModal from "../../../components/AssociationModal/AssociationModal";
import AssociatedAssetItem from "./AssociatedAssetItem/AssociatedAssetItem";
import AssociatedAssetgroupItem from "./AssociatedAssetgroupItem/AssociatedAssetgroupItem";
import AssetThresholdProgressDisplay from "../AssetThresholdProgressDisplay/AssetThresholdProgressDisplay";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import DoubleConfirmModal from "../../../components/DoubleConfirmModal/DoubleConfirmModal";
import AlertThresholdModifyLogList from "../AlertThresholdModifyLogList/AlertThresholdModifyLogList";
import Warning from "../../../components/Warning/Warning";
import delayMilliseconds from "../../../utilities/delayMilliseconds";
import styles from "./AssetThresholdsModal.module.scss";

// Modal for updating asset threshold information.
export default function AssetThresholdsModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [formId, setFormId] = useState<number>(0);
  const [modificationLogs, setModificationLogs] = useState<ModificationLog[]>([]);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [showConfirmSaveChanges, setShowConfirmSaveChanges] = useState<boolean>(false);
  const [showClearAllThresholds, setShowClearAllThresholds] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [previousAlertThresholds, setPreviousAlertThresholds] = useState<FormThreshold[]>([]);
  const [selectedAssetgroups, setSelectedAssetgroups] = useState<AlertAssetgroup[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<AlertAsset[]>([]);
  const [showAssets, setShowAssets] = useState<boolean>(false);
  const [showAssetgroups, setShowAssetgroups] = useState<boolean>(false);
  const [selectedFormId, setSelectedFormId] = useState<number | null>(null);
  const [alertThresholds, dispatchThreshold] = useReducer(thresholdReducer, []);
  const [assetsProgress, setAssetsProgress] = useState<ProgressAsset[]>([]);
  const [inAssetgroupMode, setInAssetgroupMode] = useState<boolean>(true);
  const [massUpdateComplete, setMassUpdateComplete] = useState<boolean>(false);
  const [failuresDetected, setFailuresDetected] = useState<boolean>(false);
  const [completedCloudSave, setCompletedCloudSave] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);
  const inMultipleAssetMode = props.assetId === 0;
  const alertThresholdIsMissingUsers = useMemo(
    () => checkIfAlertThresholdIsMissingUsers(),
    [JSON.stringify(alertThresholds)]
  );
  const selectedAssetsAreMigrating = useMemo(
    () => checkIfAssetsAreMigrating(selectedAssets, props.assets, inMultipleAssetMode, props.assetId),
    [JSON.stringify(selectedAssets), JSON.stringify(props.assets), inMultipleAssetMode, props.assetId]
  );
  const ASSET_BATCH_SIZE = 5;
  const MILLISECOND_DELAY_FOR_POLLING = 5000;
  const PENDING_ASSET_MAX_RETRIES = 180;

  // Get alert threshold mapping data from API.
  useApi(
    () => {
      if (!inMultipleAssetMode && props.showModal) {
        setLoading(true);
        return true;
      } else {
        setModificationLogs([]);
        setLoading(false);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${props.assetId}/assetattributethreshold`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setModificationLogs(responseBody.userModificationLogs);

        // Add formIds to alert threshold data so that the order of the records is maintained in React after CRUD functions.
        let formId = 1;
        const formThresholds: FormThreshold[] = [];
        responseBody.alertThresholds.forEach((alertThreshold) => {
          formThresholds.push({
            formId: formId,
            assetAttributeThresholdId: alertThreshold.assetAttributeThresholdId,
            attributeId: alertThreshold.attributeId,
            alertThresholdComparatorId: alertThreshold.alertThresholdComparatorId,
            thresholdValue: String(alertThreshold.thresholdValue),
            escalationRetryCount: alertThreshold.escalationRetryCount,
            acknowledgementTimeoutIntervals: alertThreshold.acknowledgementTimeoutIntervals,
            message: alertThreshold.message,
            users: alertThreshold.users,
          });
          formId++;
        });
        dispatchThreshold({
          type: THRESHOLD_TYPES.SET_THRESHOLDS,
          payload: { thresholds: formThresholds },
        });
        setPreviousAlertThresholds(formThresholds);
        setFormId(formId);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to get asset thresholds.");
      }
      setLoading(false);
    },
    [props.assetId, props.showModal]
  );

  // If we change our selection mode, clear all current selections.
  useEffect(() => {
    setSelectedAssets([]);
    setSelectedAssetgroups([]);
  }, [inAssetgroupMode]);

  // Check if any of the selected assets are migrating.
  function checkIfAssetsAreMigrating(
    selectedAssets: AlertAsset[],
    allAssets: AlertAsset[],
    inMultipleAssetMode: boolean,
    assetId: number
  ): boolean {
    if (inMultipleAssetMode) {
      return selectedAssets.some((asset) => asset.isMigrating);
    } else {
      return allAssets.some((asset) => asset.assetId === assetId && asset.isMigrating);
    }
  }

  // Check if a valid alert threshold exists without any users.
  function checkIfAlertThresholdIsMissingUsers(): boolean {
    let noUsers = false;
    alertThresholds.forEach((alertThreshold) => {
      if (
        alertThreshold.attributeId > 0 &&
        alertThreshold.alertThresholdComparatorId > 0 &&
        stringIsValidNumber(alertThreshold.thresholdValue) &&
        alertThreshold.message !== "" &&
        alertThreshold.users.length === 0
      ) {
        noUsers = true;
      }
    });
    return noUsers;
  }

  // Reducer for thresholds.
  function thresholdReducer(state: FormThreshold[], action: Action): FormThreshold[] {
    switch (action.type) {
      case THRESHOLD_TYPES.SET_THRESHOLDS: {
        if (action.payload.thresholds !== undefined) {
          return action.payload.thresholds;
        } else {
          return state;
        }
      }

      case THRESHOLD_TYPES.CREATE_THRESHOLD: {
        let stateDeepCopy = deepCopy(state);
        const initialAlertThreshold = {
          formId: formId,
          assetAttributeThresholdId: 0,
          attributeId: 0,
          alertThresholdComparatorId: 0,
          thresholdValue: "",
          escalationRetryCount: 0,
          acknowledgementTimeoutIntervals: 1,
          message: "",
          users: [],
        };
        stateDeepCopy = [initialAlertThreshold, ...stateDeepCopy];
        setFormId((prev) => prev + 1);
        return stateDeepCopy;
      }

      case THRESHOLD_TYPES.UPDATE_THRESHOLD: {
        const stateDeepCopy = deepCopy(state);
        const updatedThresholdSettings = action.payload.thresholdSettings;
        const updatedEscalationSettings = action.payload.escalationSettings;
        const formId = action.payload.formId;

        if (formId !== undefined) {
          const thresholdIndex = stateDeepCopy.findIndex((threshold) => threshold.formId === formId);
          // Don't continue if we couldn't find the threshold.
          if (thresholdIndex === -1) {
            return state;
          }

          const updatedThreshold = stateDeepCopy[thresholdIndex];
          if (updatedThresholdSettings !== undefined) {
            updatedThreshold.attributeId = updatedThresholdSettings.attributeId;
            updatedThreshold.alertThresholdComparatorId = updatedThresholdSettings.alertThresholdComparatorId;
            updatedThreshold.thresholdValue = updatedThresholdSettings.thresholdValue;
            updatedThreshold.message = updatedThresholdSettings.message;
            stateDeepCopy.splice(thresholdIndex, 1, updatedThreshold);
          }

          if (updatedEscalationSettings !== undefined) {
            updatedThreshold.acknowledgementTimeoutIntervals =
              updatedEscalationSettings.acknowledgementTimeoutIntervals;
            updatedThreshold.escalationRetryCount = updatedEscalationSettings.escalationRetryCount;
            updatedThreshold.users = updatedEscalationSettings.users;
            stateDeepCopy.splice(thresholdIndex, 1, updatedThreshold);
          }
        }

        setSelectedFormId(null);
        return stateDeepCopy;
      }

      case THRESHOLD_TYPES.DELETE_THRESHOLD: {
        const stateDeepCopy = deepCopy(state);
        const formId = action.payload.formId;
        if (formId !== undefined) {
          const thresholdIndex = stateDeepCopy.findIndex((threshold) => threshold.formId === formId);
          // Don't continue if we couldn't find the threshold.
          if (thresholdIndex === -1) {
            return state;
          }
          stateDeepCopy.splice(thresholdIndex, 1);
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Get escalation settings by form ID.
  function getEscalationSettings(formId: number | null): EscalationSettings {
    if (formId !== null) {
      const thresholdIndex = alertThresholds.findIndex((alertThreshold) => alertThreshold.formId === formId);
      if (thresholdIndex > -1) {
        const selectedEscalationSettings = {
          escalationRetryCount: alertThresholds[thresholdIndex].escalationRetryCount,
          acknowledgementTimeoutIntervals: alertThresholds[thresholdIndex].acknowledgementTimeoutIntervals,
          users: alertThresholds[thresholdIndex].users,
        };
        return selectedEscalationSettings;
      }
    }

    // If we could not find a matching record, return the default escalation settings.
    return { escalationRetryCount: 0, acknowledgementTimeoutIntervals: 1, users: [] };
  }

  // Checks if the alert thresholds are valid.
  function thresholdsAreValid(): boolean {
    //Check if all attribute values are valid.
    let validAttributeId = true;
    let validComparatorId = true;
    let validValue = true;
    let validMessage = true;
    alertThresholds.forEach((alertThreshold) => {
      if (alertThreshold.attributeId === 0) {
        validAttributeId = false;
      } else if (alertThreshold.alertThresholdComparatorId === 0) {
        validComparatorId = false;
      } else if (!stringIsValidNumber(alertThreshold.thresholdValue)) {
        validValue = false;
      } else if (alertThreshold.message === "") {
        validMessage = false;
      }
    });

    if (inMultipleAssetMode && selectedAssets.length === 0) {
      setErrorMessage("At least one asset needs to be selected to apply thresholds.");
      return false;
    } else if (!validAttributeId) {
      setErrorMessage("All thresholds require an attribute to be selected.");
      return false;
    } else if (!validComparatorId) {
      setErrorMessage("All thresholds require a valid comparator to be selected.");
      return false;
    } else if (!validValue) {
      setErrorMessage("All thresholds require a valid value to be entered.");
      return false;
    } else if (!validMessage) {
      setErrorMessage("All thresholds require an alert message.");
      return false;
    } else if (attributeIsRepeated(alertThresholds, MAX_SAME_ATTRIBUTE_COUNT_FOR_ALERT, false)) {
      setErrorMessage(`Each attribute can support at most ${MAX_SAME_ATTRIBUTE_COUNT_FOR_ALERT} alert thresholds.`);
      return false;
    } else if (attributeIsRepeated(alertThresholds, 1, true)) {
      setErrorMessage(`Only one alert threshold can have the same attribute, comparator, and value.`);
      return false;
    } else if (inMultipleAssetMode && alertThresholds.length === 0) {
      setErrorMessage("At least one threshold must be selected to update alert thresholds.");
      return false;
    } else {
      return true;
    }
  }

  // Update the list of selected assets.
  function updateSelectedAssets(selectedAssets: AlertAsset[]): void {
    setSelectedAssets(selectedAssets);
    setShowAssetgroups(false);
    setShowAssets(false);
  }

  // Update the list of selected asset groups.
  async function updateSelectedAssetgroups(selectedAssetgroups: AlertAssetgroup[]): Promise<void> {
    setShowAssetgroups(false);
    setShowAssets(false);

    const assetgroupIds = selectedAssetgroups.map((assetgroup) => assetgroup.assetgroupId);
    const requestBody = {
      assetgroupIds: assetgroupIds,
    };

    // Figure out what assets belong to the selected asset groups.
    setLoading(true);
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/assetgroup/asset`,
      "POST",
      requestBody
    )) as [Response, AssetgroupResponseBody];
    setLoading(false);

    if (response.ok) {
      setSelectedAssetgroups(selectedAssetgroups);
      setSelectedAssets(responseBody.assets);
    } else {
      setSelectedAssetgroups([]);
      setSelectedAssets([]);
      setErrorMessage("Internal server error. Failed to look up selected asset groups.");
    }
  }

  // Clear all thresholds for single asset.
  async function clearAlertThresholdsForAsset(assetId: number): Promise<void> {
    setShowClearAllThresholds(false);
    setLoading(true);
    const requestBody = { assetIds: [assetId] };
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/alert-threshold`,
      "DELETE",
      requestBody
    )) as [Response, DeleteThresholdResponseBody];
    setLoading(false);

    if (response.ok) {
      props.onClose();
    } else {
      setErrorMessage("Internal server error. Failed to clear alert thresholds.");
    }
  }

  // Clear all thresholds for all selected assets.
  async function clearAlertThresholdsForAssets(assets: AlertAsset[]): Promise<void> {
    setShowClearAllThresholds(false);
    if (assets.length === 0) {
      props.onClose();
    } else {
      const assetIds = assets.map((asset) => asset.assetId);
      const requestBody = { assetIds: assetIds };
      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/alert-threshold`,
        "DELETE",
        requestBody
      )) as [Response, DeleteThresholdResponseBody];
      setLoading(false);

      if (response.ok) {
        props.onClose();
      } else {
        setErrorMessage("Internal server error. Failed to clear alert thresholds.");
      }
    }
  }

  // Check if any attribute appears more than n times in alert thresholds.
  function attributeIsRepeated(alertThresholds: FormThreshold[], maxRepeats: number, findExactMatch: boolean): boolean {
    let alertThresholdsDeepCopy = deepCopy(alertThresholds);
    alertThresholdsDeepCopy = alertThresholdsDeepCopy.sort((thresholdA, thresholdB) =>
      thresholdA.attributeId > thresholdB.attributeId ? 1 : -1
    );
    let topRepeatsFound = 0;
    let repeatsFound = 0;
    let currentAttributeId = 0;
    let currentAttribute: FormThreshold | null = null;

    if (findExactMatch) {
      // If we are looking for an exact match, check attribute, comparator, and value.
      alertThresholdsDeepCopy.forEach((alertThreshold) => {
        if (
          currentAttribute !== null &&
          alertThreshold.attributeId === currentAttribute.attributeId &&
          alertThreshold.alertThresholdComparatorId === currentAttribute.alertThresholdComparatorId &&
          alertThreshold.thresholdValue === currentAttribute.thresholdValue
        ) {
          repeatsFound++;
        } else {
          currentAttribute = alertThreshold;
          repeatsFound = 1;
        }
        if (repeatsFound > topRepeatsFound) {
          topRepeatsFound = repeatsFound;
        }
      });
    } else {
      // If we are NOT looking for an exact match, just check attribute.
      alertThresholdsDeepCopy.forEach((alertThreshold) => {
        if (alertThreshold.attributeId === currentAttributeId) {
          repeatsFound++;
        } else {
          currentAttributeId = alertThreshold.attributeId;
          repeatsFound = 1;
        }
        if (repeatsFound > topRepeatsFound) {
          topRepeatsFound = repeatsFound;
        }
      });
    }

    return topRepeatsFound > maxRepeats;
  }

  // Confirm save attempt with disclaimer before saving changes.
  function confirmSave(): void {
    if (thresholdsAreValid()) {
      setShowConfirmSaveChanges(true);
    }
  }

  // Save changes made by the user.
  async function saveChanges(): Promise<void> {
    if (thresholdsAreValid()) {
      setShowConfirmSaveChanges(false);
      setFailuresDetected(false);
      setErrorMessage("");
      if (inMultipleAssetMode) {
        saveChangesMultipleAssets();
      } else {
        saveChangesSingleAsset();
      }
    }
  }

  // Save changes for a single asset.
  async function saveChangesSingleAsset(): Promise<void> {
    const currentTime = new Date();
    const batchCode = `${Math.round(currentTime.getTime() / 1000)}-${currentUser.userId}-0`;
    const pendingAssetId = props.assetId;

    // Get the list of attributes that are no longer being alerted on.
    const deletedAttributeIds = getNoLongerAlertingAttributes();

    // Get only the alert thresholds that have been updated / added.
    const changedAlertThresholds = getChangedAttributeThresholds(deletedAttributeIds);

    // Find the unit ID for each attribute in use. This is to keep consistency if someone changes
    // the companies units for an attribute while someone is setting alert thresholds.
    const alertThresholdsWithUnits = getThresholdsWithUnits(changedAlertThresholds);

    setAssetsProgress([
      {
        assetId: pendingAssetId,
        name: props.name,
        loading: true,
        successful: false,
      },
    ]);

    const requestBody: RequestBody = {
      batchCode: batchCode,
      assetIds: [pendingAssetId],
      thresholds: convertThresholdValuesToNumbers(alertThresholdsWithUnits),
      deletedAttributeIds: deletedAttributeIds,
    };

    setErrorMessage("");
    setCompletedCloudSave(false);
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/assetattributethreshold/list`,
      "PUT",
      requestBody
    )) as [Response, PutResponseBody];

    if (response.ok) {
      setCompletedCloudSave(true);
      // Start the process of polling asset data to see if the asset has updated.
      let assetSuccessful = false;
      for (let i = 0; i < PENDING_ASSET_MAX_RETRIES; i++) {
        await delayMilliseconds(MILLISECOND_DELAY_FOR_POLLING);
        const [response, responseBody] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/assetattributethreshold/status/${batchCode}`,
          "GET",
          null
        )) as [Response, PolledResponseBody];

        if (response.ok) {
          const assetFoundInSuccessfulArray = responseBody.successfulAssetIds.some(
            (assetId) => assetId === pendingAssetId
          );

          const assetFoundInFailedArray = responseBody.failedAssetIds.some((assetId) => assetId === pendingAssetId);

          if (assetFoundInSuccessfulArray) {
            assetSuccessful = true;
            break;
          } else if (assetFoundInFailedArray) {
            break;
          }
        }
      }
      // Handle the results of the polled data.
      if (assetSuccessful) {
        setMassUpdateComplete(true);
      } else {
        setFailuresDetected(true);
      }
    } else {
      setFailuresDetected(true);
    }
    setLoading(false);
  }

  // Save changes for multiple assets.
  async function saveChangesMultipleAssets(): Promise<void> {
    const currentTime = new Date();
    setMassUpdateComplete(false);
    setFailuresDetected(false);
    setErrorMessage("");

    // Find the unit ID for each attribute in use. This is to keep consistency if someone changes
    // the companies units for an attribute while someone is setting alert thresholds.
    const alertThresholdsWithUnits = getThresholdsWithUnits(alertThresholds);

    const requestBody: RequestBody = {
      batchCode: "",
      assetIds: [],
      thresholds: convertThresholdValuesToNumbers(alertThresholdsWithUnits),
      deletedAttributeIds: [],
    };

    // Split assets into distinct batches so that we can stagger massive
    // alert threshold changes as they are being sent to hardware.
    const chunkedAssetsProgress: ProgressAsset[][] = [];
    const chunkedAssets = chunkAssetArrays(selectedAssets, ASSET_BATCH_SIZE);

    // Convert the assets to a new format that includes success status and loading.
    chunkedAssets.forEach((assetChunk) => {
      const currentAssetBatch: ProgressAsset[] = [];
      assetChunk.forEach((asset) => {
        const currentAsset: ProgressAsset = {
          assetId: asset.assetId,
          name: asset.name,
          loading: true,
          successful: false,
        };
        currentAssetBatch.push(currentAsset);
      });
      chunkedAssetsProgress.push(currentAssetBatch);
    });
    setAssetsProgress(chunkedAssetsProgress.flat());

    // Make sequential API calls with each chunk of asset thresholds.
    for (let i = 0; i < chunkedAssetsProgress.length; i++) {
      const batchCode = `${Math.round(currentTime.getTime() / 1000)}-${currentUser.userId}-${i}`;
      const currentAssetBatch = chunkedAssetsProgress[i];
      requestBody.batchCode = batchCode;

      // Update request body to use assets in this chunk.
      const currentAssetIds: number[] = [];
      currentAssetBatch.forEach((asset) => {
        currentAssetIds.push(asset.assetId);
      });
      requestBody.assetIds = currentAssetIds;

      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/assetattributethreshold/list`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];

      if (response.ok) {
        // Continue polling results until all of our current batch of assets have a result.
        for (let j = 0; j < PENDING_ASSET_MAX_RETRIES; j++) {
          await delayMilliseconds(MILLISECOND_DELAY_FOR_POLLING);
          const [response, responseBody] = (await apiRequest(
            `${API}/company/${currentUser.companyId}/assetattributethreshold/status/${batchCode}`,
            "GET",
            null
          )) as [Response, PolledResponseBody];

          if (response.ok) {
            // Check to see which assets were successful and which failed.
            currentAssetBatch.forEach((asset) => {
              console.log(`Checking asset '${asset.name}' (${asset.assetId}) to see if it is successful.`);
              if (responseBody.successfulAssetIds.includes(asset.assetId)) {
                asset.loading = false;
                asset.successful = true;
                console.log(`'${asset.name}' found in successful assets. ✅`);
              } else if (responseBody.failedAssetIds.includes(asset.assetId)) {
                asset.loading = false;
                asset.successful = false;
                console.log(`'${asset.name}' found in failed assets. ❌`);
              } else {
                asset.loading = true;
                asset.successful = false;
                console.log(`'${asset.name}' not found in successful or failed assets. ❓`);
              }
            });

            // Check to see if all assets in the current batch have completed.
            // If they have we don't need to continue polling.
            setAssetsProgress(chunkedAssetsProgress.flat());
            const assetIsWaitingResult = chunkedAssetsProgress[i].some((asset) => asset.loading);
            if (!assetIsWaitingResult) {
              break;
            }
          }
        }

        // If some assets in the current batch are still pending, set them to failed.
        let currentBatchFailureDetected = false;
        currentAssetBatch.forEach((asset) => {
          if (asset.loading) {
            asset.loading = false;
            asset.successful = false;
            currentBatchFailureDetected = true;
          }
        });

        if (responseBody.failedAssetIds.length > 0 || currentBatchFailureDetected) {
          setFailuresDetected(true);
          setErrorMessage("Some assets failed to update.");
        }
        setAssetsProgress(chunkedAssetsProgress.flat());
      } else {
        // If the lambda for creating thresholds fails to return an ok status, that indicated a deeper issue.
        // In that case end the process and mark the remaining asset thresholds as all failed.
        chunkedAssetsProgress.flat().forEach((asset) => {
          if (asset.loading) {
            asset.loading = false;
            asset.successful = false;
          }
        });
        setAssetsProgress(chunkedAssetsProgress.flat());
        setFailuresDetected(true);
        setErrorMessage("Failed to set thresholds. Please try again later.");
        break;
      }
    }

    setMassUpdateComplete(true);
  }

  // Chunk array of assets into an array of arrays with a specified max size.
  function chunkAssetArrays(assets: AlertAsset[], maxChunkSize: number): AlertAsset[][] {
    return Array.from({ length: Math.ceil(assets.length / maxChunkSize) }, (asset, i) =>
      assets.slice(i * maxChunkSize, i * maxChunkSize + maxChunkSize)
    );
  }

  // Convert an array of thresholds into an array of thresholds that include unit information. This is important for maintaining
  // consistency of the attributes units if it does change in middle of this update being made.
  function getThresholdsWithUnits(thresholds: FormThreshold[]): ThresholdWithUnit[] {
    const alertThresholdsWithUnits: ThresholdWithUnit[] = [];

    thresholds.forEach((alertThreshold) => {
      const foundAttribute = props.attributes.find(
        (attribute) => attribute.regAttributeId === alertThreshold.attributeId
      );
      if (foundAttribute !== undefined) {
        alertThresholdsWithUnits.push({
          assetAttributeThresholdId: alertThreshold.assetAttributeThresholdId,
          attributeId: alertThreshold.attributeId,
          thresholdValue: alertThreshold.thresholdValue,
          alertThresholdComparatorId: alertThreshold.alertThresholdComparatorId,
          escalationRetryCount: alertThreshold.escalationRetryCount,
          acknowledgementTimeoutIntervals: alertThreshold.acknowledgementTimeoutIntervals,
          message: alertThreshold.message,
          users: alertThreshold.users,
          unitId: foundAttribute.unitId,
        });
      }
    });

    return alertThresholdsWithUnits;
  }

  // Generate a status message for a single asset.
  function singleAssetStatusMessage(): string {
    if (loading && !failuresDetected) {
      if (completedCloudSave) {
        return "Applying thresholds to monitoring device hardware.";
      } else {
        return "Saving alert threshold settings to the cloud.";
      }
    } else if (!loading && !failuresDetected) {
      return "Alert thresholds applied successfully.";
    } else {
      return "Applying alert thresholds failed.";
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal(): void {
    if (thresholdsMatch(alertThresholds, previousAlertThresholds)) {
      discardChanges();
    } else {
      setShowConfirmExit(true);
    }
  }

  // Compare two arrays of threshold settings and confirm that they are the same.
  function thresholdsMatch(thresholdsA: FormThreshold[], thresholdsB: FormThreshold[]): boolean {
    if (thresholdsA.length !== thresholdsB.length) {
      return false;
    }

    for (let i = 0; i < thresholdsA.length; i++) {
      if (
        thresholdsA[i].formId !== thresholdsB[i].formId ||
        thresholdsA[i].attributeId !== thresholdsB[i].attributeId ||
        thresholdsA[i].alertThresholdComparatorId !== thresholdsB[i].alertThresholdComparatorId ||
        thresholdsA[i].thresholdValue !== thresholdsB[i].thresholdValue ||
        thresholdsA[i].escalationRetryCount !== thresholdsB[i].escalationRetryCount ||
        thresholdsA[i].acknowledgementTimeoutIntervals !== thresholdsB[i].acknowledgementTimeoutIntervals ||
        thresholdsA[i].message !== thresholdsB[i].message ||
        !userArraysMatch(thresholdsA[i].users, thresholdsB[i].users)
      ) {
        return false;
      }
    }

    return true;
  }

  // Compare two arrays of user escalation settings and confirm that they are the same.
  function userArraysMatch(usersA: AssignedUser[], usersB: AssignedUser[]): boolean {
    if (usersA.length !== usersB.length) {
      return false;
    }

    for (let i = 0; i < usersA.length; i++) {
      if (
        usersA[i].userId !== usersB[i].userId ||
        usersA[i].includeEmail !== usersB[i].includeEmail ||
        usersA[i].includeCall !== usersB[i].includeCall ||
        usersA[i].includeText !== usersB[i].includeText ||
        usersA[i].escalationLevel !== usersB[i].escalationLevel
      ) {
        return false;
      }
    }

    return true;
  }

  // Gets all thresholds of attributes where a change has occurred.
  function getChangedAttributeThresholds(deletedAttributeIds: number[]): FormThreshold[] {
    // Find all attributes that include a threshold that has changed in any way.
    const attributeIds: Set<number> = new Set([]);
    alertThresholds.forEach((alertThreshold) => {
      const foundMatch = previousAlertThresholds.some(
        (previousAlertThreshold) =>
          previousAlertThreshold.attributeId === alertThreshold.attributeId &&
          previousAlertThreshold.alertThresholdComparatorId === alertThreshold.alertThresholdComparatorId &&
          previousAlertThreshold.thresholdValue === alertThreshold.thresholdValue &&
          previousAlertThreshold.escalationRetryCount === alertThreshold.escalationRetryCount &&
          previousAlertThreshold.acknowledgementTimeoutIntervals === alertThreshold.acknowledgementTimeoutIntervals &&
          previousAlertThreshold.message === alertThreshold.message &&
          userArraysMatch(previousAlertThreshold.users, alertThreshold.users)
      );
      // If the current alert threshold does not already exist/ has changed, add it to the list to update.
      if (!foundMatch) {
        attributeIds.add(alertThreshold.attributeId);
      }
      // If we deleted an attribute, and our current thresholds still include the threshold, then add it after the delete.
      // This is for cases where you have to thresholds for the same attribute and only one is deleted.
      if (deletedAttributeIds.includes(alertThreshold.attributeId)) {
        attributeIds.add(alertThreshold.attributeId);
      }
    });

    // Get all current thresholds that are included in the list of attribute IDs.
    return alertThresholds.filter((alterThreshold) => attributeIds.has(alterThreshold.attributeId));
  }

  // Get all attribute IDs of attributes that no longer have thresholds.
  function getNoLongerAlertingAttributes(): number[] {
    // Get all previously active attribute IDs, so we can check what thresholds existed before changes were made.
    const previousAttributeIds: number[] = [];
    previousAlertThresholds.forEach((alertThreshold) => previousAttributeIds.push(alertThreshold.attributeId));

    // For each threshold that exists after the changes, look for the same attribute ID in the array and remove it.
    // This let's us filter out attributes that have not changed, meaning we won't have to reset their alerts.
    alertThresholds.forEach((alertThreshold) => {
      const attributeIndex = previousAttributeIds.findIndex(
        (previousAttributeId) => previousAttributeId === alertThreshold.attributeId
      );
      if (attributeIndex > -1) {
        previousAttributeIds.splice(attributeIndex, 1);
      }
    });

    // Convert the array to a set to remove duplicates. We don't want to do this earlier in this function in case there
    // are two alert thresholds that both have the same attribute and one is being deleted but the other is still active.
    const previousAttributeIdsSet = new Set(previousAttributeIds);
    return Array.from(previousAttributeIdsSet);
  }

  // Convert threshold values from strings to numbers.
  function convertThresholdValuesToNumbers(alertThresholds: ThresholdWithUnit[]): ThresholdWithNumbers[] {
    const alertThresholdsWithNumbers: ThresholdWithNumbers[] = [];
    alertThresholds.forEach((alertThreshold) => {
      alertThresholdsWithNumbers.push({
        assetAttributeThresholdId: alertThreshold.assetAttributeThresholdId,
        attributeId: alertThreshold.attributeId,
        alertThresholdComparatorId: alertThreshold.alertThresholdComparatorId,
        thresholdValue: parseFloat(alertThreshold.thresholdValue),
        escalationRetryCount: alertThreshold.escalationRetryCount,
        acknowledgementTimeoutIntervals: alertThreshold.acknowledgementTimeoutIntervals,
        message: alertThreshold.message,
        unitId: alertThreshold.unitId,
        users: alertThreshold.users,
      });
    });
    return alertThresholdsWithNumbers;
  }

  // Exit without saving changes.
  function discardChanges(): void {
    props.onClose();
    setShowConfirmSaveChanges(false);
    setShowConfirmExit(false);
    dispatchThreshold({
      type: THRESHOLD_TYPES.SET_THRESHOLDS,
      payload: { thresholds: [] },
    });
    setPreviousAlertThresholds([]);
    setAssetsProgress([]);
    setSelectedAssets([]);
    setSelectedAssetgroups([]);
    setMassUpdateComplete(false);
    setFailuresDetected(false);
    setInAssetgroupMode(true);
    setShowAssetgroups(false);
    setShowAssets(false);
    setErrorMessage("");
  }

  // Retry setting alert thresholds for assets that previously failed.
  function retryFailures() {
    // Get all the failed asset IDs from the progress list so that we can rerun them.
    const failedAssetIds: number[] = [];
    assetsProgress.forEach((asset) => {
      if (!asset.successful) {
        failedAssetIds.push(asset.assetId);
      }
    });
    const failedAssets = props.assets.filter((asset) => failedAssetIds.includes(asset.assetId));
    setSelectedAssets(failedAssets);
    saveChanges();
  }

  return (
    <Fragment>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          {props.assetId > 0 ? (
            <h5 className="font-weight-bold" data-test="asset-threshold-modal-header">
              {props.name} Thresholds
            </h5>
          ) : (
            <h5 className="font-weight-bold" data-test="asset-threshold-modal-header">
              Apply Thresholds to Multiple Assets
            </h5>
          )}
        </ModalHeader>

        <ModalBody>
          <Fragment>
            {assetsProgress.length === 0 && (
              <Fragment>
                {/* Only show the controls for selecting assets, if we are attempting to add thresholds to multiple assets. */}
                {inMultipleAssetMode && (
                  <div className="mb-3">
                    <select
                      className="form-select mb-3"
                      value={String(inAssetgroupMode)}
                      onChange={(e) => setInAssetgroupMode(e.target.value === "true")}
                    >
                      <option value="true">Apply alert thresholds to all assets in selected asset groups</option>
                      <option value="false">Apply alert thresholds to all selected assets</option>
                    </select>

                    {inAssetgroupMode ? (
                      <Fragment>
                        <SelectAssetgroupsControl onSelect={() => setShowAssetgroups(true)} />
                        <div className={styles.smallListWrapper}>
                          {selectedAssetgroups.map((selectedAssetgroup) => (
                            <AssociatedAssetgroupItem
                              key={selectedAssetgroup.assetgroupId}
                              assetgroupId={selectedAssetgroup.assetgroupId}
                              name={selectedAssetgroup.name}
                            />
                          ))}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <SelectAssetsControl onSelect={() => setShowAssets(true)} />
                        <div className={styles.smallListWrapper}>
                          {selectedAssets.map((selectedAsset) => (
                            <AssociatedAssetItem
                              key={selectedAsset.assetId}
                              assetId={selectedAsset.assetId}
                              name={selectedAsset.name}
                              nickname={selectedAsset.nickname}
                              isMigrating={selectedAsset.isMigrating}
                            />
                          ))}
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}

                {!props.isRental && (
                  <AddAssetThreshold
                    onAddThreshold={() => dispatchThreshold({ type: THRESHOLD_TYPES.CREATE_THRESHOLD, payload: {} })}
                  />
                )}

                <div className={inMultipleAssetMode ? styles.bigListWrapper : ""}>
                  {alertThresholds.map((alertThreshold) => (
                    <AssetThresholdControl
                      key={alertThreshold.formId}
                      attributeId={alertThreshold.attributeId}
                      alertThresholdComparatorId={alertThreshold.alertThresholdComparatorId}
                      thresholdValue={alertThreshold.thresholdValue}
                      message={alertThreshold.message}
                      attributes={props.attributes}
                      comparators={props.comparators}
                      isRental={props.isRental}
                      onUpdateThreshold={(
                        attributeId: number,
                        alertThresholdComparatorId: number,
                        thresholdValue: string,
                        message: string
                      ) =>
                        dispatchThreshold({
                          type: THRESHOLD_TYPES.UPDATE_THRESHOLD,
                          payload: {
                            formId: alertThreshold.formId,
                            thresholdSettings: {
                              attributeId: attributeId,
                              alertThresholdComparatorId: alertThresholdComparatorId,
                              thresholdValue: thresholdValue,
                              message: message,
                            },
                          },
                        })
                      }
                      onRemoveThreshold={() =>
                        dispatchThreshold({
                          type: THRESHOLD_TYPES.DELETE_THRESHOLD,
                          payload: { formId: alertThreshold.formId },
                        })
                      }
                      onSelectUsers={() => setSelectedFormId(alertThreshold.formId)}
                    />
                  ))}
                </div>
              </Fragment>
            )}

            {inMultipleAssetMode && assetsProgress.length > 0 && (
              <AssetThresholdProgressDisplay assets={assetsProgress} />
            )}

            {!inMultipleAssetMode && assetsProgress.length > 0 && (
              <p className={styles.singleAssetStatus}>{singleAssetStatusMessage()}</p>
            )}

            {assetsProgress.length === 0 && <AlertThresholdModifyLogList modificationLogs={modificationLogs} />}
          </Fragment>

          {selectedAssetsAreMigrating && (
            <div className="my-3">
              <Warning
                message={
                  inMultipleAssetMode
                    ? "Some of the selected asset are currently in the process of migrating. Alert calls, emails, and text messages will not be sent for these assets until migration is complete."
                    : "This asset is currently in the process of migrating. Alert calls, emails, and text messages will not be sent for this asset until migration is complete."
                }
              />
            </div>
          )}

          {alertThresholdIsMissingUsers && (
            <div className="my-3">
              <Warning
                message={
                  "Some alert thresholds do not have any subscribed users. This will mean that no one will be notified if the alert threshold is crossed."
                }
              />
            </div>
          )}

          {props.isRental && (
            <div className="my-3">
              <Warning message="This asset is being rented from another company. You are not able to modify alert thresholds for it." />
            </div>
          )}

          <Error message={errorMessage} />
        </ModalBody>

        <ModalFooter>
          {assetsProgress.length === 0 && !props.isRental && (
            <Fragment>
              <button
                data-test="clear-thresholds-button"
                className="btn btn-danger me-auto"
                type="button"
                disabled={selectedAssets.length === 0 && props.assetId === 0}
                onClick={() => setShowClearAllThresholds(true)}
              >
                Clear All Thresholds
              </button>

              <button
                data-test="update-thresholds-button"
                className="btn btn-primary"
                type="button"
                disabled={selectedAssets.length === 0 && props.assetId === 0}
                onClick={() => confirmSave()}
              >
                Update Thresholds
              </button>
            </Fragment>
          )}

          {!inMultipleAssetMode && failuresDetected && (
            <button
              data-test="update-thresholds-retry-button"
              className="btn btn-primary"
              type="button"
              onClick={() => saveChanges()}
            >
              Retry Applying Thresholds
            </button>
          )}

          {inMultipleAssetMode && massUpdateComplete && failuresDetected && (
            <button
              data-test="update-thresholds-retry-button-failed-assets-only"
              className="btn btn-primary"
              type="button"
              onClick={() => retryFailures()}
            >
              Retry Failed Assets
            </button>
          )}

          {(massUpdateComplete && !failuresDetected) || props.isRental ? (
            <button
              data-test="close-update-thresholds-modal-button"
              className="btn btn-secondary"
              type="button"
              onClick={() => discardChanges()}
            >
              Close
            </button>
          ) : (
            <button
              data-test="close-update-thresholds-modal-button"
              className="btn btn-secondary"
              type="button"
              onClick={() => exitModal()}
            >
              Cancel
            </button>
          )}
        </ModalFooter>
      </Modal>

      <AlertEscalationModal
        showModal={selectedFormId !== null}
        unassignedUsers={props.unassignedUsers}
        assignedUsers={getEscalationSettings(selectedFormId).users}
        retryCount={getEscalationSettings(selectedFormId).escalationRetryCount}
        timeoutInterval={getEscalationSettings(selectedFormId).acknowledgementTimeoutIntervals}
        isRental={props.isRental}
        onChange={(users, timeoutInterval, retryCount) =>
          dispatchThreshold({
            type: THRESHOLD_TYPES.UPDATE_THRESHOLD,
            payload: {
              formId: selectedFormId || 0,
              escalationSettings: {
                users: users,
                acknowledgementTimeoutIntervals: timeoutInterval,
                escalationRetryCount: retryCount,
              },
            },
          })
        }
        onClose={() => setSelectedFormId(null)}
      />

      <AssociationModal
        showModal={showAssets}
        title="Select Assets"
        type="asset"
        associatedItemsTitle="Selected Assets"
        unassociatedItemsTitle="Available Assets"
        items={props.assets}
        associatedItems={selectedAssets}
        itemIdKey="assetId"
        disabled={false}
        onClose={() => setShowAssets(false)}
        onChange={(selectedAssets) => updateSelectedAssets(selectedAssets)}
      />

      <AssociationModal
        showModal={showAssetgroups}
        title="Select Asset Groups"
        type="assetgroup"
        associatedItemsTitle="Selected Asset Groups"
        unassociatedItemsTitle="Available Asset Groups"
        items={props.assetgroups}
        associatedItems={selectedAssetgroups}
        itemIdKey="assetgroupId"
        disabled={false}
        onClose={() => setShowAssetgroups(false)}
        onChange={(selectedAssetgroups) => updateSelectedAssetgroups(selectedAssetgroups)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onNoSave={() => discardChanges()}
      />

      <ConfirmModal
        showModal={props.showModal && !inMultipleAssetMode && showClearAllThresholds}
        title="Clear Alert Thresholds?"
        content={
          "This will remove all alert thresholds that currently exist on this asset. " +
          "This action is not reversible! Are you sure that you want to continue?"
        }
        yesText="Clear all Thresholds"
        noText="Cancel"
        danger={true}
        onClose={() => setShowClearAllThresholds(false)}
        onYes={() => clearAlertThresholdsForAsset(props.assetId)}
        onNo={() => setShowClearAllThresholds(false)}
      />

      <DoubleConfirmModal
        showModal={props.showModal && inMultipleAssetMode && showClearAllThresholds}
        title="Clear Alert Thresholds?"
        content={
          "This will remove all alert thresholds that currently exist on ALL selected assets. " +
          "This action is not reversible! Are you sure that you want to continue?"
        }
        confirmPin="CLEAR ALL THRESHOLDS"
        confirmText="Clear All Thresholds"
        onConfirm={() => clearAlertThresholdsForAssets(selectedAssets)}
        onCancel={() => setShowClearAllThresholds(false)}
      />

      <ConfirmModal
        showModal={props.showModal && showConfirmSaveChanges}
        title="Save Changes?"
        content={
          inMultipleAssetMode
            ? [
                "While in multiple asset mode, alert thresholds you specify will replace thresholds on targeted " +
                  "assets that share the same attributes " +
                  "(ex: New 'Fuel Level' thresholds will replace existing 'Fuel Level' thresholds). ",
                "Any currently active alerts that are related to the " + "threshold(s) you have modified will timeout.",
                "If you are renting an asset and sending alerts to a user in a different company, they will only be able " +
                  "to receive alerts if that asset is rented to their company " +
                  "(ex: If you rent asset-A to company-A, you cannot send alerts for asset-A to users in company-B).",
                "Do you want to save your changes?",
              ]
            : [
                "Any currently active alerts that are related to the threshold(s) you have modified will timeout.",
                "If you are renting an asset and sending alerts to a user in a different company, they will only be able " +
                  "to receive alerts if that asset is rented to their company " +
                  "(ex: If you rent asset-A to company-A, you cannot send alerts for asset-A to users in company-B).",
                "Do you want to save your changes?",
              ]
        }
        yesText="Save Changes"
        noText="Cancel"
        onClose={() => setShowConfirmSaveChanges(false)}
        onYes={() => saveChanges()}
        onNo={() => setShowConfirmSaveChanges(false)}
      />
    </Fragment>
  );
}

AssetThresholdsModal.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isRental: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  unassignedUsers: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  comparators: PropTypes.array.isRequired,
  assets: PropTypes.array.isRequired,
  assetgroups: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  assetId: number;
  name: string;
  isRental: boolean;
  showModal: boolean;
  unassignedUsers: UnassignedUser[];
  attributes: Attribute[];
  comparators: Comparator[];
  assets: AlertAsset[];
  assetgroups: AlertAssetgroup[];
  onClose: () => void;
}

interface AlertAssetgroup {
  assetgroupId: number;
  name: string;
}

interface RequestBody {
  batchCode: string;
  assetIds: number[];
  thresholds: ThresholdWithNumbers[];
  deletedAttributeIds: number[];
}

interface GetResponseBody {
  alertThresholds: Threshold[];
  userModificationLogs: ModificationLog[];
}

interface AssetgroupResponseBody {
  assets: AlertAsset[];
}

interface DeleteThresholdResponseBody {
  message: string;
}

interface PolledResponseBody {
  successfulAssetIds: number[];
  failedAssetIds: number[];
}

interface ModificationLog {
  username: string;
  emailAddress: string;
  lastModifiedUtc: string;
}

interface PutResponseBody {
  successfulAssetIds: number[];
  failedAssetIds: number[];
}

interface Threshold {
  assetAttributeThresholdId: number;
  attributeId: number;
  alertThresholdComparatorId: number;
  thresholdValue: number;
  escalationRetryCount: number;
  acknowledgementTimeoutIntervals: number;
  message: string;
  users: AssignedUser[];
}

interface FormThreshold {
  formId: number;
  assetAttributeThresholdId: number;
  attributeId: number;
  alertThresholdComparatorId: number;
  thresholdValue: string;
  escalationRetryCount: number;
  acknowledgementTimeoutIntervals: number;
  message: string;
  users: AssignedUser[];
}

interface ThresholdWithUnit {
  assetAttributeThresholdId: number;
  attributeId: number;
  alertThresholdComparatorId: number;
  thresholdValue: string;
  escalationRetryCount: number;
  acknowledgementTimeoutIntervals: number;
  message: string;
  unitId: number | null;
  users: AssignedUser[];
}

interface ThresholdWithNumbers {
  assetAttributeThresholdId: number;
  attributeId: number;
  alertThresholdComparatorId: number;
  thresholdValue: number;
  escalationRetryCount: number;
  acknowledgementTimeoutIntervals: number;
  message: string;
  unitId: number | null;
  users: AssignedUser[];
}

interface ThresholdSettings {
  attributeId: number;
  alertThresholdComparatorId: number;
  thresholdValue: string;
  message: string;
}

interface EscalationSettings {
  escalationRetryCount: number;
  acknowledgementTimeoutIntervals: number;
  users: AssignedUser[];
}

interface UnassignedUser {
  userId: number;
  emailAddress: string;
  name: string;
  hasPhoneNumber: boolean;
}

interface AssignedUser {
  userId: number;
  emailAddress: string;
  name: string;
  includeEmail: boolean;
  includeText: boolean;
  includeCall: boolean;
  escalationLevel: number;
  hasPhoneNumber: boolean;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  isBoolean: boolean;
}

interface Comparator {
  alertThresholdComparatorId: number;
  name: string;
}

interface AlertAsset {
  assetId: number;
  name: string;
  nickname: string;
  isMigrating: boolean;
  isRental: boolean;
}

interface ProgressAsset {
  assetId: number;
  name: string;
  loading: boolean;
  successful: boolean;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  thresholds?: FormThreshold[];
  formId?: number;
  thresholdSettings?: ThresholdSettings;
  escalationSettings?: EscalationSettings;
}
