// --------------------------------------------------------------
// Created On: 2023-09-20
// Author: Zachary Thomas
//
// Last Modified: 2024-06-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, useMemo, Fragment } from "react";
import {
  API,
  MS_PER_SECOND,
  MAX_QUERIES_AFTER_REMOTE_OPERATION,
  SECONDS_BETWEEN_OPERATION_READ_QUERIES,
} from "../../../constants/miscellaneous";
import apiRequest from "../../../utilities/api/apiRequest";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import useApi from "../../../hooks/useApi";
import isoToLocalDate from "../../../utilities/time/isoToLocalDate";
import isValueBooleanString from "../../../utilities/isValueBooleanString";
import OperationDetailsPanel from "./OperationDetailsPanel/OperationDetailsPanel";
import Card from "../../../components/Card/Card";
import {
  COPILOT_DEVICE_TYPE,
  MS_PER_MINUTE,
  MAX_MINUTES_FOR_ACCURATE_READING,
  GATEWAY_TIMEOUT_STATUS,
} from "../../../constants/miscellaneous";
import { POWER_TO_PANEL_ATTRIBUTE, ENGINE_RUNNING_ATTRIBUTE, RPM_ATTRIBUTE } from "../../../constants/attributes";
import { LOCK_REMOTE_CONTROL_PERMISSION } from "../../../constants/permissions";
import userHasPermission from "../../../utilities/userHasPermission";
import getApiError from "../../../utilities/api/getApiError";
import Error from "../../../components/Error/Error";
import Warning from "../../../components/Warning/Warning";
import Gauge from "../../../components/Gauge/Gauge";
import PropTypes from "prop-types";
import formatDateLocal from "../../../utilities/time/formatDateLocal";
import RemoteOperationPanel from "./RemoteOperationPanel/RemoteOperationPanel";
import OperationLogList from "./OperationLogList/OperationLogList";
import InfoBanner from "../../../components/InfoBanner/InfoBanner";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import deepCopy from "../../../utilities/deepCopy";
import Spinner from "../../../components/Spinner/Spinner";
import styles from "./RemoteOperationContainer.module.scss";

// Supports remotely operating assets. Some possible actions are remote start/stop and speed change.
export default function RemoteOperationContainer(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSampling, setIsSampling] = useState<boolean>(false);
  const [showConfirmLock, setShowConfirmLock] = useState<boolean>(false);
  const [showConfirmUnlock, setShowConfirmUnlock] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [controllerApplicationType, setControllerApplicationType] = useState<string>("");
  const [operationLogs, setOperationLogs] = useState<OperationLog[]>([]);
  const [attributes, setAttributes] = useState<OperationAttribute[]>([]);
  const [gaugeAttributes, setGaugeAttributes] = useState<GaugeOperationAttribute[]>([]);
  const [queriesRemaining, setQueriesRemaining] = useState<number>(0);
  const [realTimeReadCounter, setRealTimeReadCounter] = useState<number>(0);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();
  const userCanOperate = props.deviceType === COPILOT_DEVICE_TYPE && props.operationIsAllowed;
  const staleAttributeCodes = useMemo(
    () => getStaleAttributeCodes([...attributes, ...gaugeAttributes]),
    [JSON.stringify(attributes), JSON.stringify(gaugeAttributes)]
  );

  // Get the controller app type, operation logs, and the current relevant attribute readings.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/operation`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setControllerApplicationType(responseBody.controllerApplicationType);
        setOperationLogs(responseBody.assetOperationLogs);
        const attributes: OperationAttribute[] = [];
        const gaugeAttributes: GaugeOperationAttribute[] = [];
        // Sort attributes based on whether they have an expected min and max value. This will determine how we render them.
        responseBody.attributes.forEach((attribute) => {
          if (attribute.minValue !== null && attribute.maxValue !== null && attribute.minValue < attribute.maxValue) {
            gaugeAttributes.push(attribute as GaugeOperationAttribute);
          } else {
            attributes.push(attribute);
          }
          setAttributes(attributes);
          setGaugeAttributes(gaugeAttributes);
        });
      } else {
        console.error("Internal server error. Unable to retrieve operation logs.");
      }
      setLoading(false);
    },
    [assetId, realTimeReadCounter]
  );

  // Temporarily poll RPM and engine status data after a remote operation to simulate live readings.
  useEffect(() => {
    if (queriesRemaining > 0) {
      const timerId = setTimeout(
        () => sampleDevice(assetId, queriesRemaining),
        MS_PER_SECOND * SECONDS_BETWEEN_OPERATION_READ_QUERIES
      );

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [queriesRemaining, assetId]);

  // Sample device to get the most current device data.
  async function sampleDevice(assetId: string | undefined, queriesRemaining: number): Promise<void> {
    if (assetId !== undefined && queriesRemaining > 0) {
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}/operation/live`,
        "GET",
        null
      )) as [Response, GetAttributesResponseBody];

      if (response.ok) {
        // Since we got new values for existing attributes, update the existing attributes.
        const attributesDeepCopy = deepCopy(attributes);
        const gaugeAttributesDeepCopy = deepCopy(gaugeAttributes);

        responseBody.attributes.forEach((updatedAttribute) => {
          const foundAttribute = [...attributesDeepCopy, ...gaugeAttributesDeepCopy].find(
            (attribute) => updatedAttribute.regAttributeId === attribute.regAttributeId
          );
          if (foundAttribute !== undefined) {
            foundAttribute.currentValue = updatedAttribute.currentValue;
            foundAttribute.currentValueUtc = updatedAttribute.currentValueUtc;
          }
        });
        setAttributes(attributesDeepCopy);
        setGaugeAttributes(gaugeAttributesDeepCopy);
        setQueriesRemaining(queriesRemaining - 1);
      } else {
        // If we get an error, stop trying to process a device update.
        setQueriesRemaining(0);
      }
    }
  }

  // Change communication mode.
  async function changeCommunicationMode(command: string): Promise<void> {
    setErrorMessage("");
    setWarningMessage("");
    const requestBody = { command: command };
    setQueriesRemaining(0);
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/operation/commsmode`,
      "POST",
      requestBody
    )) as [Response, PostResponseBody];
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      setWarningMessage("");
    } else if (response.status === GATEWAY_TIMEOUT_STATUS) {
      setWarningMessage("Request timeout. The command's status could not be confirmed. Please try again.");
    } else {
      setErrorMessage(await getApiError(response, "Failed while attempting to change communication mode."));
    }

    // Get the most recent operation logs since we just performed an operation.
    updateOperationLogs();
  }

  // Change running status.
  async function changeRunningStatus(command: string): Promise<void> {
    setErrorMessage("");
    setWarningMessage("");
    const requestBody = { command: command };
    setQueriesRemaining(0);
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/operation/runningstatus`,
      "POST",
      requestBody
    )) as [Response, PostResponseBody];
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      setWarningMessage("");
    } else if (response.status === GATEWAY_TIMEOUT_STATUS) {
      setWarningMessage("Request timeout. The command's status could not be confirmed. Please try again.");
    } else {
      setErrorMessage(await getApiError(response, "Failed while attempting to change running status."));
    }

    // Get the most recent operation logs since we just performed an operation.
    updateOperationLogs();

    // Perform live reads for a bit after this operation.
    setQueriesRemaining(MAX_QUERIES_AFTER_REMOTE_OPERATION);
  }

  // Change speed.
  async function changeSpeed(command: number): Promise<void> {
    setErrorMessage("");
    setWarningMessage("");
    const requestBody = { command: command };
    setQueriesRemaining(0);
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/operation/speed`,
      "POST",
      requestBody
    )) as [Response, PostResponseBody];
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      setWarningMessage("");
    } else if (response.status === GATEWAY_TIMEOUT_STATUS) {
      setWarningMessage("Request timeout. The command's status could not be confirmed. Please try again.");
    } else {
      setErrorMessage(await getApiError(response, "Failed while attempting to change speed."));
    }

    // Get the most recent operation logs since we just performed an operation.
    updateOperationLogs();

    // Perform live reads for a bit after this operation.
    setQueriesRemaining(MAX_QUERIES_AFTER_REMOTE_OPERATION);
  }

  // Clear failed commands from monitoring device.
  async function handleClearFailedCommands(): Promise<void> {
    setErrorMessage("");
    setWarningMessage("");
    setQueriesRemaining(0);
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/operation/clearalerts`,
      "POST",
      null
    )) as [Response, PostResponseBody];
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      setWarningMessage("");
    } else if (response.status === GATEWAY_TIMEOUT_STATUS) {
      setWarningMessage("Request timeout. The command's status could not be confirmed. Please try again.");
    } else {
      setErrorMessage(await getApiError(response, "Failed while attempting to clear failed commands."));
    }

    // Get the most recent operation logs since we just performed an operation.
    updateOperationLogs();
  }

  // Restart monitoring device.
  async function handleRestartMonitoringDevice(): Promise<void> {
    setErrorMessage("");
    setWarningMessage("");
    setQueriesRemaining(0);
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/operation/reset`,
      "POST",
      null
    )) as [Response, PostResponseBody];
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      setWarningMessage("");
    } else if (response.status === GATEWAY_TIMEOUT_STATUS) {
      setWarningMessage("Request timeout. The command's status could not be confirmed. Please try again.");
    } else {
      setErrorMessage(await getApiError(response, "Failed while attempting to restart monitoring device."));
    }

    // Get the most recent operation logs since we just performed an operation.
    updateOperationLogs();
  }

  // Get the operation logs for the current asset and save them to state.
  async function updateOperationLogs(): Promise<void> {
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/operatelog`,
      "GET",
      null
    )) as [Response, GetLogsResponseBody];
    if (response.ok) {
      setOperationLogs(responseBody.assetOperationLogs);
    }
  }

  // Handle attempts to update the lock status.
  function handleLockStatus(isLocked: boolean): void {
    if (isLocked) {
      setShowConfirmLock(true);
    } else {
      setShowConfirmUnlock(true);
    }
  }

  // Make API call to update lock status.
  async function updateLockStatus(isLocked: boolean): Promise<void> {
    setShowConfirmLock(false);
    setShowConfirmUnlock(false);
    const requestBody = { lockout: isLocked };
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/operation/lockout`,
      "PUT",
      requestBody
    )) as [Response, PostResponseBody];
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      props.onChangeLock(isLocked);
    } else {
      setErrorMessage("Internal server error. Failed while attempting to change lock for remote operations.");
    }
  }

  // Returns an array of attribute codes that represent attributes with stale data.
  function getStaleAttributeCodes(attributes: OperationAttribute[]): string[] {
    const staleAttributeCodes: string[] = [];
    const nowDate = new Date();

    // Mark attributes as stale if they haven't reported a new reading in a certain amount of time.
    // We do not mark boolean attributes as stale in this way, due to them only sending new readings on state change.
    attributes.forEach((attribute) => {
      if (attribute.currentValue === null || !isValueBooleanString(attribute.currentValue)) {
        if (attribute.currentValueUtc === null) {
          staleAttributeCodes.push(attribute.attributeCode);
        } else {
          const attributeDate = isoToLocalDate(attribute.currentValueUtc);
          const attributeIsStale =
            (nowDate.getTime() - attributeDate.getTime()) / MS_PER_MINUTE > MAX_MINUTES_FOR_ACCURATE_READING;
          if (attributeIsStale) {
            staleAttributeCodes.push(attribute.attributeCode);
          }
        }
      }
    });

    // Some attributes values indicate that other attributes may not have a reliable value.
    // In these cases we will mark attributes as stale if they cannot be trusted.
    const powerToPanelAttribute = attributes.find((attribute) => attribute.attributeCode === POWER_TO_PANEL_ATTRIBUTE);

    const engineRunningAttribute = attributes.find((attribute) => attribute.attributeCode === ENGINE_RUNNING_ATTRIBUTE);

    if (
      !staleAttributeCodes.includes(ENGINE_RUNNING_ATTRIBUTE) &&
      powerToPanelAttribute !== undefined &&
      powerToPanelAttribute.currentValue !== null &&
      isValueBooleanString(powerToPanelAttribute.currentValue) &&
      powerToPanelAttribute.currentValue.toLowerCase() === "false"
    ) {
      staleAttributeCodes.push(ENGINE_RUNNING_ATTRIBUTE);
    }

    if (
      !staleAttributeCodes.includes(RPM_ATTRIBUTE) &&
      ((powerToPanelAttribute !== undefined &&
        powerToPanelAttribute.currentValue !== null &&
        isValueBooleanString(powerToPanelAttribute.currentValue) &&
        powerToPanelAttribute.currentValue.toLowerCase() === "false") ||
        (engineRunningAttribute !== undefined &&
          engineRunningAttribute.currentValue !== null &&
          isValueBooleanString(engineRunningAttribute.currentValue) &&
          engineRunningAttribute.currentValue.toLowerCase() === "false"))
    ) {
      staleAttributeCodes.push(RPM_ATTRIBUTE);
    }

    return staleAttributeCodes;
  }

  return (
    <div className="p-4 mx-auto">
      {gaugeAttributes.length > 0 && (
        <div className="row align-items-center">
          {gaugeAttributes.map((attribute) => (
            <div className={`col text-center ${styles.gaugeColumn}`} key={attribute.attributeCode}>
              <Gauge
                code={attribute.attributeCode}
                label={attribute.attributeName}
                min={attribute.minValue}
                max={attribute.maxValue}
                value={attribute.currentValue}
                time={attribute.currentValueUtc}
                unitShortName={attribute.unitShortName}
                staleAttributeCodes={staleAttributeCodes}
                loading={queriesRemaining > 0}
              />
            </div>
          ))}
        </div>
      )}

      {errorMessage.length > 0 && (
        <div className={`${styles.stickyMessage} my-4 px-3`}>
          <Error message={errorMessage} />
        </div>
      )}

      {warningMessage.length > 0 && (
        <div className={`${styles.stickyMessage} my-4 px-3`}>
          <Warning message={warningMessage} />
        </div>
      )}

      {errorMessage.length === 0 && !userCanOperate && !props.isMigrating && !loading && (
        <div className={`${styles.stickyMessage} my-4 px-3`}>
          <InfoBanner message="You do not have permission to remotely operate this asset." />
        </div>
      )}

      {errorMessage.length === 0 && userCanOperate && !props.isMigrating && !props.lastConfigSuccessful && !loading && (
        <div className={`${styles.stickyMessage} my-4 px-3`}>
          <Warning
            message={`This asset failed to update its monitoring device configuration settings on ${formatDateLocal(
              props.lastConfigAttemptUtc
            )}. If you remotely operate this asset there may be unexpected behavior.`}
          />
        </div>
      )}

      <div className="row justify-content-center mt-2 mb-4 gx-0 mx-0">
        {attributes.length > 0 && !props.isLockedOut && !props.isMigrating && (
          <Fragment>
            <div className={userCanOperate ? "col-12 col-lg-6 col-xl-8 mb-4 mb-lg-0" : "col-12"}>
              <OperationDetailsPanel
                assetId={assetId === undefined ? 0 : parseInt(assetId, 10)}
                attributes={attributes}
                staleAttributeCodes={staleAttributeCodes}
                deviceId={props.deviceId}
                deviceType={props.deviceType}
                queriesRemaining={queriesRemaining}
                onDeviceRead={() => setRealTimeReadCounter((prev) => prev + 1)}
                onIsSamplingChange={(isSampling) => setIsSampling(isSampling)}
              />
            </div>

            {userCanOperate && !props.isLockedOut && (
              <div className={attributes.length > 0 ? "col-12 col-lg-6 col-xl-4 mb-4 mb-lg-0" : "col-12"}>
                <RemoteOperationPanel
                  assetName={props.assetName}
                  controllerApplicationType={controllerApplicationType}
                  controllerModel={props.controllerModel}
                  deviceType={props.deviceType}
                  isLockedOut={props.isLockedOut}
                  canUnlock={props.canUnlock}
                  loading={loading || isSampling}
                  onChangeCommunicationMode={(command) => changeCommunicationMode(command)}
                  onChangeSpeed={(command) => changeSpeed(command)}
                  onChangeRunningStatus={(command) => changeRunningStatus(command)}
                  onClearFailedCommands={() => handleClearFailedCommands()}
                  onRestartMonitoringDevice={() => handleRestartMonitoringDevice()}
                  onError={(error) => setErrorMessage(error)}
                  onChangeLock={(isLocked) => handleLockStatus(isLocked)}
                />
              </div>
            )}
          </Fragment>
        )}

        {!loading && props.isLockedOut && !props.isMigrating && (
          <div className={attributes.length > 0 ? "col-12 col-lg-6 col-xl-4 mb-4 mb-lg-0" : "col-12"}>
            <Card title={`${props.assetName} Remote Operations`}>
              <p className="h4 text-center py-5 px-4">
                Remote control access is locked for this asset.
                {props.canUnlock
                  ? ""
                  : " The lock was applied by the company you are renting this asset from, only they can unlock this asset."}
              </p>
              <Fragment>
                {props.canUnlock && userHasPermission([[LOCK_REMOTE_CONTROL_PERMISSION]]) && (
                  <div className="text-center">
                    <button
                      className="btn btn-primary mx-auto mb-5"
                      type="button"
                      onClick={() => handleLockStatus(false)}
                    >
                      Unlock Remote Control
                    </button>
                  </div>
                )}
              </Fragment>
            </Card>
          </div>
        )}

        {!loading && !props.isLockedOut && props.isMigrating && (
          <Card title={`${props.assetName} Remote Operations`}>
            <p className="h4 text-center py-5 px-4">
              This asset has not fully been migrated and cannot be remotely operated.
            </p>
          </Card>
        )}

        {!loading && attributes.length === 0 && !props.isLockedOut && !props.isMigrating && (
          <Card title={`${props.assetName} Remote Operations`}>
            <p className="h4 text-center py-5 px-4">This asset currently does not support remote operation.</p>
          </Card>
        )}
      </div>

      {operationLogs.length > 0 && <OperationLogList assetName={props.assetName} operationLogs={operationLogs} />}

      <ConfirmModal
        showModal={showConfirmLock}
        title="Apply Remote Lock?"
        content={
          "Are you sure that you want to apply a remote lock on this asset? " +
          "This will prevent all remote operations for this asset until the lock is removed."
        }
        yesText="Apply Remote Lock"
        noText="Cancel"
        danger={false}
        onClose={() => setShowConfirmLock(false)}
        onYes={() => updateLockStatus(true)}
        onNo={() => setShowConfirmLock(false)}
      />

      <ConfirmModal
        showModal={showConfirmUnlock}
        title="Remove Remote Lock?"
        content="Are you sure that you want to remove the remote lock on this asset?"
        yesText="Remove Remote Lock"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmUnlock(false)}
        onYes={() => updateLockStatus(false)}
        onNo={() => setShowConfirmUnlock(false)}
      />

      <Spinner loading={loading} />
    </div>
  );
}

RemoteOperationContainer.propTypes = {
  assetName: PropTypes.string.isRequired,
  operationIsAllowed: PropTypes.bool.isRequired,
  controllerModel: PropTypes.string,
  deviceId: PropTypes.number,
  deviceType: PropTypes.string,
  isLockedOut: PropTypes.bool.isRequired,
  canUnlock: PropTypes.bool.isRequired,
  isMigrating: PropTypes.bool.isRequired,
  lastConfigAttemptUtc: PropTypes.string.isRequired,
  lastConfigSuccessful: PropTypes.bool.isRequired,
  onChangeLock: PropTypes.func,
};

interface Props {
  assetName: string;
  operationIsAllowed: boolean;
  controllerModel: string | null;
  deviceId: number | null;
  deviceType: string | null;
  isLockedOut: boolean;
  canUnlock: boolean;
  isMigrating: boolean;
  lastConfigAttemptUtc: string;
  lastConfigSuccessful: boolean;
  onChangeLock: (isLocked: boolean) => void;
}

interface PostResponseBody {
  message: string;
}

interface GetResponseBody {
  controllerApplicationType: string;
  assetOperationLogs: OperationLog[];
  attributes: OperationAttribute[];
}

interface GetLogsResponseBody {
  assetOperationLogs: OperationLog[];
}

interface GetAttributesResponseBody {
  attributes: OperationAttribute[];
}

interface OperationLog {
  assetOperationLogId: number;
  commandType: string;
  value: string;
  commandSucceeded: boolean;
  username: string;
  emailAddress: string;
  operationUtc: string;
}

interface OperationAttribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  minValue: number | null;
  maxValue: number | null;
}

interface GaugeOperationAttribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  minValue: number;
  maxValue: number;
}
