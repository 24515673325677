// --------------------------------------------------------------
// Created On: 2023-10-10
// Author: Zachary Thomas
//
// Last Modified: 2024-03-12
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import formatDateShortLocal from "../../../../utilities/time/formatDateShortLocal";
import styles from "./OperationLogList.module.scss";

// A list of operation logs for the current asset.
export default function OperationLogList(props: Props): Component {
  // Format the operation message.
  function formatOperationMessage(
    username: string,
    emailAddress: string,
    commandType: string,
    value: string,
    operationUtc: string,
    commandSucceeded: boolean
  ): string {
    const localTimestamp = formatDateShortLocal(operationUtc);
    switch (commandType) {
      case "device status": {
        let action = "UNKNOWN";
        switch (value) {
          case "CLEAR MESSAGES":
            action = "clear messages on the monitoring device";
            break;
          case "RESET":
            action = "restart the monitoring device";
            break;
          default:
            break;
        }
        return `${username} (${emailAddress}) ${
          commandSucceeded ? "sent command" : "attempted/failed"
        } to ${action} on ${localTimestamp}.`;
      }
      default:
        return `${username} (${emailAddress}) ${
          commandSucceeded ? "" : "attempted/failed"
        } set ${commandType} to '${value}' on ${localTimestamp}.`;
    }
  }

  return (
    <div className="mx-3">
      <div className={styles.titleBar}>
        <label className={styles.title}>{props.assetName} Operation Logs</label>
      </div>
      <div className={styles.body}>
        {props.operationLogs.map((operationLog) => (
          <div key={operationLog.assetOperationLogId} className={styles.item}>
            {formatOperationMessage(
              operationLog.username,
              operationLog.emailAddress,
              operationLog.commandType,
              operationLog.value,
              operationLog.operationUtc,
              operationLog.commandSucceeded
            )}
          </div>
        ))}

        {props.operationLogs.length === 0 && (
          <div className={styles.item}>No operation logs created for this asset.</div>
        )}
      </div>
    </div>
  );
}

OperationLogList.propTypes = {
  assetName: PropTypes.string.isRequired,
  operationLogs: PropTypes.array.isRequired,
};

interface Props {
  assetName: string;
  operationLogs: OperationLog[];
}

interface OperationLog {
  assetOperationLogId: number;
  commandType: string;
  value: string;
  commandSucceeded: boolean;
  username: string;
  emailAddress: string;
  operationUtc: string;
}
