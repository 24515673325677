// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2024-05-14
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants that describe reducer actions.
export const ATTRIBUTE_TYPES = {
  SET_ATTRIBUTES: "SET_ATTRIBUTES",
  UPDATE_ATTRIBUTE: "UPDATE_ATTRIBUTE",
  SAVE_ATTRIBUTE: "SAVE_ATTRIBUTE",
  SORT_ATTRIBUTES: "SORT_ATTRIBUTES",
};
export const LENT_ASSET_TYPES = {
  SET_ASSETS: "SET_ASSETS",
  CREATE_ASSET: "CREATE_ASSET",
  DELETE_ASSET: "DELETE_ASSET",
  APPROVE_ASSET: "APPROVE_ASSET",
};
export const TRANSFER_ASSET_TYPES = {
  SET_ASSETS: "SET_ASSETS",
  CREATE_ASSET: "CREATE_ASSET",
  DELETE_ASSET: "DELETE_ASSET",
};
export const REPORT_TYPES = {
  SET_REPORTS: "SET_REPORTS",
  CREATE_REPORT: "CREATE_REPORT",
  UPDATE_REPORT: "UPDATE_REPORT",
  DELETE_REPORT: "DELETE_REPORT",
};
export const VIEW_TYPES = {
  SET_VIEWS: "SET_VIEWS",
  CREATE_VIEW: "CREATE_VIEW",
  UPDATE_VIEW: "UPDATE_VIEW",
  DELETE_VIEW: "DELETE_VIEW",
};
export const ASSET_TYPES = {
  SET_ASSETS: "SET_ASSETS",
  CREATE_ASSET: "CREATE_ASSET",
  UPDATE_ASSET: "UPDATE_ASSET",
  UPDATE_ASSET_NICKNAME: "UPDATE_ASSET_NICKNAME",
  DELETE_ASSET: "DELETE_ASSET",
};
export const ASSETGROUP_TYPES = {
  SET_ASSETGROUPS: "SET_ASSETGROUPS",
  CREATE_ASSETGROUP: "CREATE_ASSETGROUP",
  UPDATE_ASSETGROUP: "UPDATE_ASSETGROUP",
  DELETE_ASSETGROUP: "DELETE_ASSETGROUP",
};
export const MAINTENANCE_LOG_TYPES = {
  SET_MAINTENANCE_LOGS: "SET_MAINTENANCE_LOGS",
  CREATE_MAINTENANCE_LOG: "CREATE_MAINTENANCE_LOG",
  UPDATE_MAINTENANCE_LOG: "UPDATE_MAINTENANCE_LOG",
  DELETE_MAINTENANCE_LOG: "DELETE_MAINTENANCE_LOG",
};
export const ROLE_TYPES = {
  SET_ROLES: "SET_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
};
export const USER_TYPES = {
  SET_USERS: "SET_USERS",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  CREATE_USER: "CREATE_USER",
};
export const USERGROUP_TYPES = {
  SET_USERGROUPS: "SET_USERGROUPS",
  CREATE_USERGROUP: "CREATE_USERGROUP",
  UPDATE_USERGROUP: "UPDATE_USERGROUP",
  DELETE_USERGROUP: "DELETE_USERGROUP",
};
export const THRESHOLD_TYPES = {
  SET_THRESHOLDS: "SET_THRESHOLDS",
  CREATE_THRESHOLD: "CREATE_THRESHOLD",
  UPDATE_THRESHOLD: "UPDATE_THRESHOLD",
  DELETE_THRESHOLD: "DELETE_THRESHOLD",
};
