// --------------------------------------------------------------
// Created On: 2023-06-01
// Author: Zachary Thomas
//
// Last Modified: 2024-08-08
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants related to attributes.
export const LOCATION_ATTRIBUTE = "LocationAttr";
export const GPS_TRAVEL_SPEED_ATTRIBUTE = "GPSMPHAttr";
export const POWER_TO_PANEL_ATTRIBUTE = "PowerToPanelAttr";
export const ENGINE_RUNNING_ATTRIBUTE = "EngineRunningAttr";
export const SUCTION_PRESSURE_ATTRIBUTE = "SuctionPressureAttr";
export const DISCHARGE_PRESSURE_ATTRIBUTE = "DischargePressureAttr";
export const COMMON_ALARM_ATTRIBUTE = "CommonAlarmAttr";
export const RPM_ATTRIBUTE = "RPMAttr";
export const FUEL_RATE = "FuelRateAttr";

export const RUNTIME_ASSET_ATTRIBUTE = "CumulativeOperatingHoursAttr";
export const RUNTIME_VIBRATION_ATTRIBUTE = "TotalRuntimeAttr";
export const RUNTIME_SERVICE_ATTRIBUTE = "ServiceRuntimeAttr";

export const ANALOG_INPUT_ONE_ATTRIBUTE = "AI1EngineeringValueAttr";
export const ANALOG_INPUT_ONE_CONNECTED_ATTRIBUTE = "AI1ConnectedAttr";
export const ANALOG_INPUT_ONE_MIN_ATTRIBUTE = "AI1EngineeringMinAttr";
export const ANALOG_INPUT_ONE_SPAN_ATTRIBUTE = "AI1EngineeringSpanAttr";
export const ANALOG_INPUT_ONE_MODE_ATTRIBUTE = "AI1ModeAttr";

export const ANALOG_INPUT_TWO_ATTRIBUTE = "AI2EngineeringValueAttr";
export const ANALOG_INPUT_TWO_CONNECTED_ATTRIBUTE = "AI2ConnectedAttr";
export const ANALOG_INPUT_TWO_MIN_ATTRIBUTE = "AI2EngineeringMinAttr";
export const ANALOG_INPUT_TWO_SPAN_ATTRIBUTE = "AI2EngineeringSpanAttr";
export const ANALOG_INPUT_TWO_MODE_ATTRIBUTE = "AI2ModeAttr";

export const ANALOG_INPUT_THREE_ATTRIBUTE = "AI3EngineeringValueAttr";
export const ANALOG_INPUT_THREE_CONNECTED_ATTRIBUTE = "AI3ConnectedAttr";
export const ANALOG_INPUT_THREE_MIN_ATTRIBUTE = "AI3EngineeringMinAttr";
export const ANALOG_INPUT_THREE_SPAN_ATTRIBUTE = "AI3EngineeringSpanAttr";
export const ANALOG_INPUT_THREE_MODE_ATTRIBUTE = "AI3ModeAttr";

export const ANALOG_INPUT_FOUR_ATTRIBUTE = "AI4EngineeringValueAttr";
export const ANALOG_INPUT_FOUR_CONNECTED_ATTRIBUTE = "AI4ConnectedAttr";
export const ANALOG_INPUT_FOUR_MIN_ATTRIBUTE = "AI4EngineeringMinAttr";
export const ANALOG_INPUT_FOUR_SPAN_ATTRIBUTE = "AI4EngineeringSpanAttr";
export const ANALOG_INPUT_FOUR_MODE_ATTRIBUTE = "AI4ModeAttr";

export const ANALOG_INPUT_FIVE_ATTRIBUTE = "AI5EngineeringValueAttr";
export const ANALOG_INPUT_FIVE_CONNECTED_ATTRIBUTE = "AI5ConnectedAttr";
export const ANALOG_INPUT_FIVE_MIN_ATTRIBUTE = "AI5EngineeringMinAttr";
export const ANALOG_INPUT_FIVE_SPAN_ATTRIBUTE = "AI5EngineeringSpanAttr";
export const ANALOG_INPUT_FIVE_MODE_ATTRIBUTE = "AI5ModeAttr";

export const ANALOG_INPUT_SIX_ATTRIBUTE = "AI6EngineeringValueAttr";
export const ANALOG_INPUT_SIX_CONNECTED_ATTRIBUTE = "AI6ConnectedAttr";
export const ANALOG_INPUT_SIX_MIN_ATTRIBUTE = "AI6EngineeringMinAttr";
export const ANALOG_INPUT_SIX_SPAN_ATTRIBUTE = "AI6EngineeringSpanAttr";
export const ANALOG_INPUT_SIX_MODE_ATTRIBUTE = "AI6ModeAttr";

export const ANALOG_INPUT_SEVEN_ATTRIBUTE = "AI7EngineeringValueAttr";
export const ANALOG_INPUT_SEVEN_CONNECTED_ATTRIBUTE = "AI7ConnectedAttr";
export const ANALOG_INPUT_SEVEN_MIN_ATTRIBUTE = "AI7EngineeringMinAttr";
export const ANALOG_INPUT_SEVEN_SPAN_ATTRIBUTE = "AI7EngineeringSpanAttr";
export const ANALOG_INPUT_SEVEN_MODE_ATTRIBUTE = "AI7ModeAttr";

export const ANALOG_INPUT_EIGHT_ATTRIBUTE = "AI8EngineeringValueAttr";
export const ANALOG_INPUT_EIGHT_CONNECTED_ATTRIBUTE = "AI8ConnectedAttr";
export const ANALOG_INPUT_EIGHT_MIN_ATTRIBUTE = "AI8EngineeringMinAttr";
export const ANALOG_INPUT_EIGHT_SPAN_ATTRIBUTE = "AI8EngineeringSpanAttr";
export const ANALOG_INPUT_EIGHT_MODE_ATTRIBUTE = "AI8ModeAttr";

export const DIGITAL_INPUT_ONE_STATE_ATTRIBUTE = "DI1StateAttr";
export const DIGITAL_INPUT_ONE_FLOW_ATTRIBUTE = "DI1FlowAttr";
export const DIGITAL_INPUT_ONE_ACCUMULATION_ATTRIBUTE = "DI1AccumulationAttr";
export const DIGITAL_INPUT_ONE_MODE_ATTRIBUTE = "DI1ModeAttr";
export const DIGITAL_INPUT_ONE_K_FACTOR_ATTRIBUTE = "DI1KFactorAttr";
export const DIGITAL_INPUT_ONE_OFFSET_ATTRIBUTE = "DI1OffsetAttr";
export const DIGITAL_INPUT_ONE_DEBOUNCE_ATTRIBUTE = "DI1DebounceAttr";
export const DIGITAL_INPUT_ONE_UNITS_PER_PULSE_ATTRIBUTE = "DI1UnitsPerPulseAttr";

export const DIGITAL_INPUT_TWO_STATE_ATTRIBUTE = "DI2StateAttr";
export const DIGITAL_INPUT_TWO_FLOW_ATTRIBUTE = "DI2FlowAttr";
export const DIGITAL_INPUT_TWO_ACCUMULATION_ATTRIBUTE = "DI2AccumulationAttr";
export const DIGITAL_INPUT_TWO_MODE_ATTRIBUTE = "DI2ModeAttr";
export const DIGITAL_INPUT_TWO_K_FACTOR_ATTRIBUTE = "DI2KFactorAttr";
export const DIGITAL_INPUT_TWO_OFFSET_ATTRIBUTE = "DI2OffsetAttr";
export const DIGITAL_INPUT_TWO_DEBOUNCE_ATTRIBUTE = "DI2DebounceAttr";
export const DIGITAL_INPUT_TWO_UNITS_PER_PULSE_ATTRIBUTE = "DI2UnitsPerPulseAttr";

export const DIGITAL_INPUT_THREE_STATE_ATTRIBUTE = "DI3StateAttr";
export const DIGITAL_INPUT_THREE_FLOW_ATTRIBUTE = "DI3FlowAttr";
export const DIGITAL_INPUT_THREE_ACCUMULATION_ATTRIBUTE = "DI3AccumulationAttr";
export const DIGITAL_INPUT_THREE_MODE_ATTRIBUTE = "DI3ModeAttr";
export const DIGITAL_INPUT_THREE_K_FACTOR_ATTRIBUTE = "DI3KFactorAttr";
export const DIGITAL_INPUT_THREE_OFFSET_ATTRIBUTE = "DI3OffsetAttr";
export const DIGITAL_INPUT_THREE_DEBOUNCE_ATTRIBUTE = "DI3DebounceAttr";
export const DIGITAL_INPUT_THREE_UNITS_PER_PULSE_ATTRIBUTE = "DI3UnitsPerPulseAttr";

export const DIGITAL_INPUT_FOUR_STATE_ATTRIBUTE = "DI4StateAttr";
export const DIGITAL_INPUT_FOUR_FLOW_ATTRIBUTE = "DI4FlowAttr";
export const DIGITAL_INPUT_FOUR_ACCUMULATION_ATTRIBUTE = "DI4AccumulationAttr";
export const DIGITAL_INPUT_FOUR_MODE_ATTRIBUTE = "DI4ModeAttr";
export const DIGITAL_INPUT_FOUR_K_FACTOR_ATTRIBUTE = "DI4KFactorAttr";
export const DIGITAL_INPUT_FOUR_OFFSET_ATTRIBUTE = "DI4OffsetAttr";
export const DIGITAL_INPUT_FOUR_DEBOUNCE_ATTRIBUTE = "DI4DebounceAttr";
export const DIGITAL_INPUT_FOUR_UNITS_PER_PULSE_ATTRIBUTE = "DI4UnitsPerPulseAttr";
