// --------------------------------------------------------------
// Created On: 2023-04-18
// Author: Zachary Thomas
//
// Last Modified: 2024-06-25
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Returns true if a string can successfully be parsed by parseFloat.
export default function stringIsValidNumber(value: string): boolean {
  const number = parseFloat(value);
  return !isNaN(number);
}
