// --------------------------------------------------------------
// Created On: 2022-09-14
// Author: Zachary Thomas
//
// Last Modified: 2024-06-25
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./DataHistoryControls.module.scss";

// Controls for changing data history settings.
export default function DataHistoryControls(props: Props): Component {
  return (
    <div className={`${styles.body} row mx-1`}>
      <div className={props.isGraphable ? "col-6 col-md-4" : "col-6"}>
        <label>Start Date</label>
        <input
          className="form-control"
          type="date"
          value={props.startDate}
          onChange={(e) => props.onChangeStartDate(e.target.value)}
        />
      </div>

      <div className={props.isGraphable ? "col-6 col-md-4" : "col-6"}>
        <label>End Date</label>
        <input
          className="form-control"
          type="date"
          value={props.endDate}
          onChange={(e) => props.onChangeEndDate(e.target.value)}
        />
      </div>

      {props.isGraphable && (
        <div className="col-12 col-md-4">
          <label>Mode</label>
          <select className="form-select" value={props.mode} onChange={(e) => props.onChangeMode(e.target.value)}>
            <option value="STANDARD">Standard</option>
            <option value="MOVING_AVERAGE">Moving Average</option>
          </select>
        </div>
      )}
    </div>
  );
}

DataHistoryControls.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  isGraphable: PropTypes.bool.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onChangeMode: PropTypes.func.isRequired,
};

interface Props {
  startDate: string;
  endDate: string;
  mode: string;
  isGraphable: boolean;
  onChangeStartDate: (startDate: string) => void;
  onChangeEndDate: (endDate: string) => void;
  onChangeMode: (mode: string) => void;
}
