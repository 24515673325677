// --------------------------------------------------------------
// Created On: 2021-08-24
// Author: Zachary Thomas
//
// Last Modified: 2024-05-13
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import store from "../../redux/store";
import isRealObject from "../isRealObject";

// Makes a request to an API.
export default async function apiRequest<Type>(url: string, method: string, body: Type): Promise<[Response, unknown]> {
  let response: Response = new Response(null, { status: 500 });
  let clonedResponse: Response = new Response(null, { status: 500 });
  let responseBody: unknown = {};

  // Get the current authorization token.
  const authorizerToken = store.getState().authorizerToken;

  try {
    // Check if there is a request body object to send.
    if (body) {
      // Omit the request body log if it contains sensitive information.
      if (
        isRealObject(body) &&
        ("password" in (body as RealObject) ||
          "currentPassword" in (body as RealObject) ||
          "newPassword" in (body as RealObject))
      ) {
        console.log(`Request: ${method} ${url}`, "[Body omitted to hide sensitive information]");
      } else {
        console.log(`Request: ${method} ${url}`, body);
      }

      response = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizerToken,
        },
      });
    } else {
      // Don't include the body if there is no valid body to send.
      console.log(`Request: ${method} ${url}`);
      response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizerToken,
        },
      });
    }

    // Clone the response to allow other functions to read the response body stream if needed.
    clonedResponse = response.clone();

    console.log(`Response:`, response);
    responseBody = await getResponseBody(response);

    if (responseBody !== null && typeof responseBody === "object" && "accessToken" in responseBody) {
      console.log("Response Body: [Body omitted to hide sensitive information]");
    } else {
      console.log("Response Body:", responseBody);
    }

    // Check if the response was a 401 status code.
    // If it was then we will redirect to the login page.
    if (response !== null && response !== undefined && response.status === 401) {
      console.log("An unauthorized request was made. Returning to login page.");
      window.location.href = "/login/auth";
    }
  } catch (e) {
    // If an error occurs while attempting to make a request,
    // create a generic 500 internal server error and log the error.
    console.error(e);
    response = new Response(null, {
      status: 500,
      statusText: "",
      headers: [],
    });
  }

  return [clonedResponse, responseBody as unknown];
}

// Attempt to get the response body.
async function getResponseBody(response: Response): Promise<unknown> {
  const json = await response.text();
  try {
    const object = JSON.parse(json);
    return object;
  } catch (e) {
    return { error: "Response body does not contain valid JSON." } as unknown;
  }
}
