// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2023-11-22
// Modified By: Lilly Kane
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./TitleBar.module.scss";

// Simple bar with title.
export default function TitleBar(props: Props): Component {
  return (
    <div data-test="title-bar" className={`${styles.body} py-3`}>
      {props.title}
    </div>
  );
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
};

interface Props {
  title: string;
}
