// --------------------------------------------------------------
// Created On: 2021-10-04
// Author: Zachary Thomas
//
// Last Modified: 2024-08-20
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import {
  API,
  COPILOT_DEVICE_TYPE,
  PULSE_DEVICE_TYPE,
  SEEKER_C_DEVICE_TYPE,
  SEEKER_SOLAR_DEVICE_TYPE,
  COPILOT_PMG_DEVICE_TYPE,
  PULSE_MANUAL,
  COPILOT_MANUAL,
  SEEKER_MANUAL,
  PMG_MANUAL,
} from "../../constants/miscellaneous";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import Card from "../../components/Card/Card";
import Document from "./Document/Document";

// Page that lists downloadable documents.
export default function AssetDocumentsPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>("");
  const [manuals, setManuals] = useState<Resource[]>([]);
  const [curves, setCurves] = useState<Resource[]>([]);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();

  // Get device subscription info.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/document`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAssetName(responseBody.assetName);

        // Add conditional device type manuals.
        const conditionalManuals: Resource[] = [];
        if (responseBody.deviceType === COPILOT_DEVICE_TYPE) {
          conditionalManuals.push(COPILOT_MANUAL);
        } else if (responseBody.deviceType === PULSE_DEVICE_TYPE) {
          conditionalManuals.push(PULSE_MANUAL);
        } else if (
          responseBody.deviceType === SEEKER_C_DEVICE_TYPE ||
          responseBody.deviceType === SEEKER_SOLAR_DEVICE_TYPE
        ) {
          conditionalManuals.push(SEEKER_MANUAL);
        } else if (responseBody.deviceType === COPILOT_PMG_DEVICE_TYPE) {
          conditionalManuals.push(PMG_MANUAL);
        }
        setManuals([...responseBody.manuals, ...conditionalManuals]);
        setCurves(responseBody.pumpCurves);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [assetId]
  );

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      {manuals.length > 0 || curves.length > 0 ? (
        <Fragment>
          {manuals.length > 0 && (
            <div className="col-12 col-xl-10 col-xxl-8 offset-xl-1 offset-xxl-2 pb-4">
              <Card title={`${assetName}'s Manuals`}>
                <div className="p-4">
                  {manuals.map((manual) => (
                    <Document key={manual.resourceId} name={manual.name} endpoint={manual.endpoint} />
                  ))}
                </div>
              </Card>
            </div>
          )}

          {curves.length > 0 && (
            <div className="col-12 col-xl-10 col-xxl-8 offset-xl-1 offset-xxl-2">
              <Card title={`${assetName}'s Pump Curves`}>
                <div className="p-4">
                  {curves.map((curve) => (
                    <Document key={curve.resourceId} name={curve.name} endpoint={curve.endpoint} />
                  ))}
                </div>
              </Card>
            </div>
          )}
        </Fragment>
      ) : (
        <Card title="Documents">
          <p className="h4 text-center py-5 px-4">No documents found for {assetName}</p>
        </Card>
      )}
    </div>
  );
}

interface Resource {
  resourceId: number;
  name: string;
  endpoint: string;
}

interface ResponseBody {
  assetName: string;
  deviceType: string;
  pumpCurves: Resource[];
  manuals: Resource[];
}
