// --------------------------------------------------------------
// Created On: 2023-02-02
// Author: Zachary Thomas
//
// Last Modified: 2024-05-31
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import SaveChangesModal from "../SaveChangesModal/SaveChangesModal";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import Error from "../Error/Error";
import Warning from "../Warning/Warning";
import Spinner from "../Spinner/Spinner";
import IconTooltip from "../IconTooltip/IconTooltip";
import MonitoringDeviceForm from "./MonitoringDeviceForm/MonitoringDeviceForm";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import {
  API,
  MIN_ASSET_NAME_LENGTH,
  MAX_ASSET_NAME_LENGTH,
  MIN_ASSET_FIELDS_LENGTH,
  MAX_ASSET_FIELDS_LENGTH,
  PULSE_DEVICE_TYPE,
  CREATE_MODE,
  EDIT_MODE,
  PRODUCT_MANUFACTURERS,
} from "../../constants/miscellaneous";
import { ASSET_TYPES } from "../../constants/reducerActions";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import userHasPermission from "../../utilities/userHasPermission";
import { RUNTIME_VIBRATION_ATTRIBUTE, RUNTIME_ASSET_ATTRIBUTE } from "../../constants/attributes";
import formatDateLocal from "../../utilities/time/formatDateLocal";
import {
  UPDATE_ASSETS_PERMISSION,
  DELETE_ASSETS_PERMISSION,
  CREATE_ASSETS_PERMISSION,
  UPDATE_SERVICE_HOURS_PERMISSION,
} from "../../constants/permissions";
import styles from "./AssetModal.module.scss";

// Modal for creating, editing, and deleting assets.
export default function AssetModal(props: Props): Component {
  const initialAsset = {
    assetId: 0,
    name: "",
    nickname: "",
    productType: "",
    productModel: "",
    productIdentifier: "",
    productManufacturer: "",
    controllerModel: null,
    controllerManufacturer: null,
    deviceType: null,
    deviceIdentifier: null,
    isRented: false,
    analogSensors: [],
    digitalSensors: [],
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [showConfirmTemplateConfig, setShowConfirmTemplateConfig] = useState<boolean>(false);
  const [showConfirmSensorConfig, setShowConfirmSensorConfig] = useState<boolean>(false);
  const [previousAsset, setPreviousAsset] = useState<AssetConfiguration>(initialAsset);
  const [name, setName] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [productType, setProductType] = useState<string>("");
  const [productManufacturer, setProductManufacturer] = useState<string>("");
  const [productModel, setProductModel] = useState<string>("");
  const [productIdentifier, setProductIdentifier] = useState<string>("");
  const [controllerIndex, setControllerIndex] = useState<number>(-1);
  const [isRented, setIsRented] = useState<boolean>(false);
  const [isMigrating, setIsMigrating] = useState<boolean>(false);
  const [lastConfigSuccessful, setLastConfigSuccessful] = useState<boolean>(true);
  const [lastConfigAttemptUtc, setLastConfigAttemptUtc] = useState<string>("");
  const [deviceType, setDeviceType] = useState<string | null>(null);
  const [deviceIdentifier, setDeviceIdentifier] = useState<string | null>(null);
  const [analogSensorMap, setAnalogSensorMap] = useState<AnalogSensorMap>({});
  const [digitalSensorMap, setDigitalSensorMap] = useState<DigitalSensorMap>({});
  const [otherProductManufacturer, setOtherProductManufacturer] = useState<string>("");
  const [serviceAttribute, setServiceAttribute] = useState<string | null>(null);
  const [previousServiceAttribute, setPreviousServiceAttribute] = useState<string | null>(null);
  const [serviceTime, setServiceTime] = useState<string>("0");
  const [previousServiceTime, setPreviousServiceTime] = useState<string>("0");
  const [updateServiceTime, setUpdateServiceTime] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);

  // Get asset data from API.
  useApi(
    () => {
      if (props.assetId > 0 && props.showModal) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${props.assetId}/configuration`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setPreviousAsset(responseBody);
        setName(responseBody.name);
        setNickname(responseBody.nickname);
        setProductType(responseBody.productType);
        setProductModel(responseBody.productModel);
        setProductIdentifier(responseBody.productIdentifier);
        setDeviceType(responseBody.deviceType);
        setDeviceIdentifier(responseBody.deviceIdentifier);
        setIsRented(responseBody.isRented);
        setIsMigrating(responseBody.isMigrating);
        if (lastConfigAttemptUtc !== undefined && lastConfigAttemptUtc !== null) {
          setLastConfigAttemptUtc(responseBody.lastConfigAttemptUtc);
        }
        if (lastConfigSuccessful !== undefined && lastConfigSuccessful !== null) {
          setLastConfigSuccessful(responseBody.lastConfigSuccessful);
        }
        if (responseBody.serviceHoursAttributeCode !== undefined) {
          setServiceAttribute(responseBody.serviceHoursAttributeCode);
          setPreviousServiceAttribute(responseBody.serviceHoursAttributeCode);
        }
        if (responseBody.runtimeSinceLastServiced !== undefined && responseBody.runtimeSinceLastServiced !== null) {
          setServiceTime(String(responseBody.runtimeSinceLastServiced));
          setPreviousServiceTime(String(responseBody.runtimeSinceLastServiced));
        }
        // Find the controller index to use.
        setControllerIndex(
          props.controllers.findIndex(
            (controller) =>
              controller.controllerModel === responseBody.controllerModel &&
              controller.manufacturerCompanyCode === responseBody.controllerManufacturer
          )
        );
        // Find the product manufacturer.
        if (
          responseBody.productManufacturer === "" ||
          props.productManufacturers.includes(responseBody.productManufacturer)
        ) {
          setProductManufacturer(responseBody.productManufacturer);
          setOtherProductManufacturer("");
        } else {
          setProductManufacturer("OTHER");
          setOtherProductManufacturer(responseBody.productManufacturer);
        }

        // Setup the analog sensor map.
        const newAnalogSensorMap: AnalogSensorMap = {};
        responseBody.analogSensors.forEach((analogSensor) => {
          newAnalogSensorMap[analogSensor.attributeCode] = analogSensor;
        });
        setAnalogSensorMap(newAnalogSensorMap);

        // Setup the digital sensor map.
        const newDigitalSensorMap: DigitalSensorMap = {};
        responseBody.digitalSensors.forEach((digitalSensor) => {
          newDigitalSensorMap[digitalSensor.attributeStateCode] = digitalSensor;
        });
        setDigitalSensorMap(newDigitalSensorMap);

        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to get asset settings.");
      }
      setLoading(false);
    },
    [props.assetId, props.showModal]
  );

  // If an initial asset is defined and we are in create mode, set all asset details to the initial asset.
  useEffect(() => {
    if (props.mode === CREATE_MODE && props.initialAsset !== undefined) {
      setPreviousAsset(props.initialAsset);
      setName(props.initialAsset.name);
      setNickname(props.initialAsset.nickname);
      setProductType(props.initialAsset.productType);
      setProductManufacturer(props.initialAsset.productManufacturer);
      setProductModel(props.initialAsset.productModel);
      setProductIdentifier(props.initialAsset.productIdentifier);
      setIsRented(props.initialAsset.isRented);
      setIsMigrating(false);
      setDeviceType(props.initialAsset.deviceType);
      setDeviceIdentifier(props.initialAsset.deviceIdentifier);
      setAnalogSensorMap({});
      setDigitalSensorMap({});
      setControllerIndex(
        props.controllers.findIndex((controller) => {
          if (props.initialAsset !== undefined) {
            controller.controllerModel === props.initialAsset.controllerModel &&
              controller.manufacturerCompanyCode === props.initialAsset.controllerManufacturer;
          }
        })
      );
    }
  }, [JSON.stringify(props.initialAsset), JSON.stringify(props.controllers), props.mode]);

  // Validate the asset settings.
  function assetIsValid(): boolean {
    // Get all selected applications for sensors, so we can determine if two sensors are trying to report the same data.
    let duplicateAnalogSensorMapping = false;
    const sensorSelectedAttributes: number[] = [];
    Object.keys(analogSensorMap).forEach((analogSensorMapKey) => {
      if (sensorSelectedAttributes.includes(analogSensorMap[analogSensorMapKey].mappedAttributeId)) {
        duplicateAnalogSensorMapping = true;
      } else {
        sensorSelectedAttributes.push(analogSensorMap[analogSensorMapKey].mappedAttributeId);
      }
    });

    let duplicateDigitalSensorMapping = false;
    const sensorSelectedReportingDescriptions: string[] = [];
    Object.keys(digitalSensorMap).forEach((digitalSensorMapKey) => {
      if (sensorSelectedReportingDescriptions.includes(digitalSensorMap[digitalSensorMapKey].reportingDescription)) {
        duplicateDigitalSensorMapping = true;
      } else {
        sensorSelectedReportingDescriptions.push(digitalSensorMap[digitalSensorMapKey].reportingDescription);
      }
    });

    if (name.trim().length < MIN_ASSET_NAME_LENGTH || name.trim().length > MAX_ASSET_NAME_LENGTH) {
      setErrorMessage(
        `The asset name must be between ${MIN_ASSET_NAME_LENGTH} and ${MAX_ASSET_NAME_LENGTH} characters long.`
      );
      return false;
    } else if (nickname.trim().length > MAX_ASSET_FIELDS_LENGTH) {
      setErrorMessage(`The asset nickname must be a maximum of ${MAX_ASSET_FIELDS_LENGTH} characters long.`);
      return false;
    } else if (productType.trim().length === 0) {
      setErrorMessage("You must select an option for the product type.");
      return false;
    } else if (
      productModel.trim().length < MIN_ASSET_FIELDS_LENGTH ||
      productModel.trim().length > MAX_ASSET_FIELDS_LENGTH
    ) {
      setErrorMessage(
        `The product part number must be between ${MIN_ASSET_FIELDS_LENGTH} and ${MAX_ASSET_FIELDS_LENGTH} characters long.`
      );
      return false;
    } else if (
      productIdentifier.trim().length < MIN_ASSET_FIELDS_LENGTH ||
      productIdentifier.trim().length > MAX_ASSET_FIELDS_LENGTH
    ) {
      setErrorMessage(
        `The product serial number must be between ${MIN_ASSET_FIELDS_LENGTH} and ${MAX_ASSET_FIELDS_LENGTH} characters long.`
      );
      return false;
    } else if (productManufacturer.trim().length === 0) {
      setErrorMessage("You must select an option for the product manufacturer.");
      return false;
    } else if (
      productManufacturer === "OTHER" &&
      (otherProductManufacturer.trim().length < MIN_ASSET_FIELDS_LENGTH ||
        otherProductManufacturer.trim().length > MAX_ASSET_FIELDS_LENGTH)
    ) {
      setErrorMessage(
        `The product manufacturer must be between ${MIN_ASSET_FIELDS_LENGTH} and ${MAX_ASSET_FIELDS_LENGTH} characters long.`
      );
      return false;
    } else if (deviceType !== null && deviceIdentifier === null) {
      setErrorMessage("When a monitoring device type is selected an identifier is also required.");
      return false;
    } else if (duplicateAnalogSensorMapping) {
      setErrorMessage("Two or more analog sensors cannot report the same type of data.");
      return false;
    } else if (duplicateDigitalSensorMapping) {
      setErrorMessage("Two or more digital sensors cannot report the same type of data.");
      return false;
    } else if (Number.isNaN(parseFloat(serviceTime))) {
      setErrorMessage("Hours since last serviced must be a valid number.");
      return false;
    } else {
      return true;
    }
  }

  // Create an asset.
  async function createAsset(updateTemplate: boolean, updateSensors: boolean): Promise<void> {
    setShowConfirmTemplateConfig(false);
    setShowConfirmSensorConfig(false);
    if (assetIsValid()) {
      // Get the selected product manufacturer or the write in.
      let currentProductManufacturer;
      if (productManufacturer === "OTHER") {
        currentProductManufacturer = otherProductManufacturer;
      } else {
        currentProductManufacturer = productManufacturer;
      }

      // Check if a controller was selected.
      let controllerModel: string | null = null;
      let controllerManufacturerCode: string | null = null;
      if (controllerIndex >= 0) {
        controllerModel = props.controllers[controllerIndex].controllerModel;
        controllerManufacturerCode = props.controllers[controllerIndex].manufacturerCompanyCode;
      }

      // If service hours attribute is null, also make request service time null.
      let requestServiceTime = null;
      if (serviceAttribute !== null) {
        requestServiceTime = parseFloat(serviceTime);
      }

      const requestBody: AssetRequestBody = {
        assetName: name.trim(),
        assetNickname: nickname.trim(),
        productType: productType.trim(),
        productModel: productModel.trim(),
        productIdentifier: productIdentifier.trim(),
        productManufacturer: currentProductManufacturer.trim(),
        controllerModel: controllerModel,
        controllerManufacturerCode: controllerManufacturerCode,
        deviceType: deviceType,
        deviceIdentifier: deviceIdentifier,
        updateTemplate: updateTemplate,
        updateSensors: updateSensors,
        analogSensors: [],
        digitalSensors: [],
        serviceHoursAttributeCode: serviceAttribute,
        runtimeSinceLastServiced: requestServiceTime,
      };

      // Add analog sensor settings to request body.
      Object.keys(analogSensorMap).forEach((analogSensorKey) =>
        requestBody.analogSensors.push(analogSensorMap[analogSensorKey])
      );

      // Add digital sensor settings to request body.
      Object.keys(digitalSensorMap).forEach((digitalSensorKey) =>
        requestBody.digitalSensors.push(digitalSensorMap[digitalSensorKey])
      );

      if (!updateServiceTime) {
        requestBody.serviceHoursAttributeCode = null;
        requestBody.runtimeSinceLastServiced = null;
      }

      setErrorMessage("");
      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const newAsset = {
          assetId: responseBody.assetId,
          name: name,
          nickname: nickname,
          productType: productType,
          productModel: productModel,
          productIdentifier: productIdentifier,
          productManufacturer: currentProductManufacturer,
          controllerModel: controllerModel,
          controllerManufacturer: controllerManufacturerCode,
          deviceType: deviceType,
          deviceIdentifier: deviceIdentifier,
          isRented: false,
          analogSensors: requestBody.analogSensors,
          digitalSensors: requestBody.digitalSensors,
        };

        props.onAction({
          type: ASSET_TYPES.CREATE_ASSET,
          payload: {
            asset: newAsset,
          },
        });

        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to create asset."));
      }
    }
  }

  // Edit an asset.
  async function editAsset(updateTemplate: boolean, updateSensors: boolean, assetId: number): Promise<void> {
    setShowConfirmTemplateConfig(false);
    setShowConfirmSensorConfig(false);
    if (assetIsValid()) {
      // Get the selected product manufacturer or the write in.
      let currentProductManufacturer;
      if (productManufacturer === "OTHER") {
        currentProductManufacturer = otherProductManufacturer;
      } else {
        currentProductManufacturer = productManufacturer;
      }

      // Check if a controller was selected.
      let controllerModel: string | null = null;
      let controllerManufacturerCode: string | null = null;
      if (controllerIndex >= 0) {
        controllerModel = props.controllers[controllerIndex].controllerModel;
        controllerManufacturerCode = props.controllers[controllerIndex].manufacturerCompanyCode;
      }

      // If service hours attribute is null, also make request service time null.
      let requestServiceTime = null;
      if (serviceAttribute !== null) {
        requestServiceTime = parseFloat(serviceTime);
      }

      const requestBody: AssetRequestBody = {
        assetName: name.trim(),
        assetNickname: nickname.trim(),
        productType: productType.trim(),
        productModel: productModel.trim(),
        productIdentifier: productIdentifier.trim(),
        productManufacturer: currentProductManufacturer.trim(),
        controllerModel: controllerModel,
        controllerManufacturerCode: controllerManufacturerCode,
        deviceType: deviceType,
        deviceIdentifier: deviceIdentifier,
        updateTemplate: updateTemplate || !lastConfigSuccessful,
        updateSensors: updateSensors || !lastConfigSuccessful,
        analogSensors: [],
        digitalSensors: [],
        updateServiceHours: updateServiceTime,
        serviceHoursAttributeCode: serviceAttribute,
        runtimeSinceLastServiced: requestServiceTime,
      };

      // Add analog sensor settings to request body.
      Object.keys(analogSensorMap).forEach((analogSensorKey) =>
        requestBody.analogSensors.push(analogSensorMap[analogSensorKey])
      );

      // Add digital sensor settings to request body.
      Object.keys(digitalSensorMap).forEach((digitalSensorKey) =>
        requestBody.digitalSensors.push(digitalSensorMap[digitalSensorKey])
      );

      setErrorMessage("");
      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        const updatedAsset = {
          assetId: props.assetId,
          name: name,
          nickname: nickname,
          productType: productType,
          productModel: productModel,
          productIdentifier: productIdentifier,
          productManufacturer: currentProductManufacturer,
          controllerModel: controllerModel,
          controllerManufacturer: controllerManufacturerCode,
          deviceType: deviceType,
          deviceIdentifier: deviceIdentifier,
          isRented: isRented,
          isMigrating: isMigrating,
          analogSensors: requestBody.analogSensors,
          digitalSensors: requestBody.digitalSensors,
        };
        setErrorMessage("");
        props.onClose();

        props.onAction({
          type: ASSET_TYPES.UPDATE_ASSET,
          payload: {
            asset: updatedAsset,
          },
        });

        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to update asset."));
      }
    }
  }

  // Update an asset's nickname.
  async function updateAssetNickname(nickname: string, assetId: number): Promise<void> {
    const requestBody = {
      assetNickname: nickname,
    };

    setErrorMessage("");
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/nickname`,
      "PUT",
      requestBody
    )) as [Response, PutResponseBody];
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      props.onClose();

      props.onAction({
        type: ASSET_TYPES.UPDATE_ASSET_NICKNAME,
        payload: {
          assetId: props.assetId,
          nickname: nickname,
        },
      });
      discardChanges();
    } else {
      setErrorMessage(await getApiError(response, "Unable to update asset."));
    }
  }

  // Delete an asset.
  async function deleteAsset(assetId: number): Promise<void> {
    setErrorMessage("");
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: ASSET_TYPES.DELETE_ASSET,
        payload: {
          assetId: assetId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete asset."));
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal(): void {
    // Check to see if state is the same as props.
    if (
      !sensorsHaveChanged() &&
      !controllerHasChanged() &&
      name === previousAsset.name &&
      nickname === previousAsset.nickname &&
      productType === previousAsset.productType &&
      productModel === previousAsset.productModel &&
      productIdentifier === previousAsset.productIdentifier &&
      deviceType === previousAsset.deviceType &&
      deviceIdentifier === previousAsset.deviceIdentifier &&
      serviceAttribute === previousServiceAttribute &&
      serviceTime === previousServiceTime &&
      (productManufacturer === previousAsset.productManufacturer ||
        (productManufacturer === "OTHER" && otherProductManufacturer === previousAsset.productManufacturer))
    ) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Compare the current control with the previous controller to see if any changes have occurred.
  function controllerHasChanged(): boolean {
    let controllerModel = null;
    let controllerManufacturer = null;

    // Get the current controller model and controller manufacturer.
    if (controllerIndex <= props.controllers.length && controllerIndex >= 0) {
      const currentController = props.controllers[controllerIndex];
      if (currentController.controllerModel !== null) {
        controllerModel = currentController.controllerModel;
      }
      if (currentController.manufacturerCompanyCode !== null) {
        controllerManufacturer = currentController.manufacturerCompanyCode;
      }
    }

    return (
      controllerModel !== previousAsset.controllerModel ||
      controllerManufacturer !== previousAsset.controllerManufacturer
    );
  }

  // Compare current sensors with previous sensors to see if any changes have occurred.
  function sensorsHaveChanged(): boolean {
    // Compare analog sensors.
    const previousAnalogSensors: AnalogSensorSelection[] = [];
    const currentAnalogSensors: AnalogSensorSelection[] = [];

    previousAsset.analogSensors.forEach((analogSensor) => {
      const analogSensorSelection = {
        analogSensorId: analogSensor.analogSensorId,
        attributeCode: analogSensor.attributeCode,
        attributeConnectedCode: analogSensor.attributeConnectedCode,
        attributeModeCode: analogSensor.attributeModeCode,
        attributeMinCode: analogSensor.attributeMinCode,
        attributeSpanCode: analogSensor.attributeSpanCode,
        mappedAttributeId: analogSensor.mappedAttributeId,
        mappedAttributeConnectedId: analogSensor.mappedAttributeConnectedId,
      };
      previousAnalogSensors.push(analogSensorSelection);
    });
    previousAnalogSensors.sort((a, b) => {
      const attributeCodeA = a.attributeCode;
      const attributeCodeB = b.attributeCode;
      if (attributeCodeA < attributeCodeB) {
        return -1;
      } else if (attributeCodeA > attributeCodeB) {
        return 1;
      } else {
        return 0;
      }
    });

    Object.keys(analogSensorMap).forEach((analogSensorKey) => {
      const analogSensorSelection = {
        analogSensorId: analogSensorMap[analogSensorKey].analogSensorId,
        attributeCode: analogSensorMap[analogSensorKey].attributeCode,
        attributeConnectedCode: analogSensorMap[analogSensorKey].attributeConnectedCode,
        attributeModeCode: analogSensorMap[analogSensorKey].attributeModeCode,
        attributeMinCode: analogSensorMap[analogSensorKey].attributeMinCode,
        attributeSpanCode: analogSensorMap[analogSensorKey].attributeSpanCode,
        mappedAttributeId: analogSensorMap[analogSensorKey].mappedAttributeId,
        mappedAttributeConnectedId: analogSensorMap[analogSensorKey].mappedAttributeConnectedId,
      };
      currentAnalogSensors.push(analogSensorSelection);
    });
    currentAnalogSensors.sort((a, b) => {
      const attributeCodeA = a.attributeCode;
      const attributeCodeB = b.attributeCode;
      if (attributeCodeA < attributeCodeB) {
        return -1;
      } else if (attributeCodeA > attributeCodeB) {
        return 1;
      } else {
        return 0;
      }
    });

    const analogSenorsChanged = JSON.stringify(previousAnalogSensors) !== JSON.stringify(currentAnalogSensors);

    // Compare digital sensors.
    const previousDigitalSensors: DigitalSensorSelection[] = [];
    const currentDigitalSensors: DigitalSensorSelection[] = [];

    previousAsset.digitalSensors.forEach((digitalSensor) => {
      const digitalSensorSelection = {
        digitalSensorId: digitalSensor.digitalSensorId,
        attributeStateCode: digitalSensor.attributeStateCode,
        attributeFlowCode: digitalSensor.attributeFlowCode,
        attributeAccumulationCode: digitalSensor.attributeAccumulationCode,
        attributeModeCode: digitalSensor.attributeModeCode,
        attributeKFactorCode: digitalSensor.attributeKFactorCode,
        attributeOffsetCode: digitalSensor.attributeKFactorCode,
        attributeDebounceCode: digitalSensor.attributeKFactorCode,
        attributeUnitsPerPulseCode: digitalSensor.attributeUnitsPerPulseCode,
        mappedAttributeStateId: digitalSensor.mappedAttributeStateId,
        mappedAttributeFlowId: digitalSensor.mappedAttributeFlowId,
        mappedAttributeAccumulationId: digitalSensor.mappedAttributeAccumulationId,
        reportingDescription: digitalSensor.reportingDescription,
      };
      previousDigitalSensors.push(digitalSensorSelection);
    });
    previousDigitalSensors.sort((a, b) => {
      const attributeCodeA = a.attributeStateCode;
      const attributeCodeB = b.attributeStateCode;
      if (attributeCodeA < attributeCodeB) {
        return -1;
      } else if (attributeCodeA > attributeCodeB) {
        return 1;
      } else {
        return 0;
      }
    });

    Object.keys(digitalSensorMap).forEach((digitalSensorKey) => {
      const digitalSensorSelection = {
        digitalSensorId: digitalSensorMap[digitalSensorKey].digitalSensorId,
        attributeStateCode: digitalSensorMap[digitalSensorKey].attributeStateCode,
        attributeFlowCode: digitalSensorMap[digitalSensorKey].attributeFlowCode,
        attributeAccumulationCode: digitalSensorMap[digitalSensorKey].attributeAccumulationCode,
        attributeModeCode: digitalSensorMap[digitalSensorKey].attributeModeCode,
        attributeKFactorCode: digitalSensorMap[digitalSensorKey].attributeKFactorCode,
        attributeOffsetCode: digitalSensorMap[digitalSensorKey].attributeKFactorCode,
        attributeDebounceCode: digitalSensorMap[digitalSensorKey].attributeKFactorCode,
        attributeUnitsPerPulseCode: digitalSensorMap[digitalSensorKey].attributeUnitsPerPulseCode,
        mappedAttributeStateId: digitalSensorMap[digitalSensorKey].mappedAttributeStateId,
        mappedAttributeFlowId: digitalSensorMap[digitalSensorKey].mappedAttributeFlowId,
        mappedAttributeAccumulationId: digitalSensorMap[digitalSensorKey].mappedAttributeAccumulationId,
        reportingDescription: digitalSensorMap[digitalSensorKey].reportingDescription,
      };
      currentDigitalSensors.push(digitalSensorSelection);
    });
    currentDigitalSensors.sort((a, b) => {
      const attributeCodeA = a.attributeStateCode;
      const attributeCodeB = b.attributeStateCode;
      if (attributeCodeA < attributeCodeB) {
        return -1;
      } else if (attributeCodeA > attributeCodeB) {
        return 1;
      } else {
        return 0;
      }
    });

    const digitalSenorsChanged = JSON.stringify(previousDigitalSensors) !== JSON.stringify(currentDigitalSensors);

    return analogSenorsChanged || digitalSenorsChanged;
  }

  // Save changes to an asset. If a change requires device configuration, confirm configuration with user before saving.
  function saveChanges(): void {
    const templateUpdateRequired =
      deviceType !== null &&
      deviceType !== PULSE_DEVICE_TYPE &&
      (controllerHasChanged() ||
        deviceType !== previousAsset.deviceType ||
        deviceIdentifier !== previousAsset.deviceIdentifier);

    const sensorUpdatedRequired = sensorsHaveChanged();

    if (templateUpdateRequired || sensorUpdatedRequired) {
      setShowConfirmTemplateConfig(templateUpdateRequired);
      setShowConfirmSensorConfig(sensorUpdatedRequired);
    } else {
      if (props.mode === CREATE_MODE) {
        createAsset(false, false);
      } else if (isRented) {
        updateAssetNickname(nickname, props.assetId);
      } else {
        editAsset(false, false, props.assetId);
      }
    }
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function discardChanges(): void {
    setPreviousAsset(initialAsset);
    setName("");
    setNickname("");
    setProductType("");
    setProductManufacturer("");
    setProductModel("");
    setProductIdentifier("");
    setControllerIndex(-1);
    setIsRented(false);
    setIsMigrating(false);
    setLastConfigSuccessful(true);
    setLastConfigAttemptUtc("");
    setDeviceType(null);
    setAnalogSensorMap({});
    setDigitalSensorMap({});
    setDeviceIdentifier(null);
    setServiceTime("0");
    setPreviousServiceTime("0");
    setUpdateServiceTime(false);
    setServiceAttribute(null);
    setPreviousServiceAttribute(null);
    setShowConfirmExit(false);
    setShowConfirmDelete(false);
    setErrorMessage("");
    props.onClose();
  }

  // Update the monitoring device.
  function updateDevice(tempDeviceType: string | null, tempDeviceIdentifier: string | null): void {
    if (tempDeviceType === "") {
      setDeviceType(null);
      setDeviceIdentifier(null);
    } else if (tempDeviceIdentifier === "") {
      setDeviceType(tempDeviceType);
      setDeviceIdentifier(null);
    } else {
      setDeviceType(tempDeviceType);
      setDeviceIdentifier(tempDeviceIdentifier);
    }

    // If the device type changes, remove the sensor mappings.
    if (tempDeviceType !== deviceType) {
      setAnalogSensorMap({});
      setDigitalSensorMap({});
    }
  }

  // Change an analog sensor that is connected to the monitoring device.
  function updateAnalogSensor(
    analogSensorId: string,
    attributeId: string,
    attributeCode: string,
    attributeConnectedCode: string,
    attributeModeCode: string,
    attributeMinCode: string,
    attributeSpanCode: string
  ): void {
    const tempAnalogSensors = deepCopy(props.analogSensors);
    const tempAnalogSensorMap = deepCopy(analogSensorMap);
    const parsedSensorId = parseInt(analogSensorId, 10);
    const parsedAttributeId = parseInt(attributeId, 10);

    if (!Number.isNaN(parsedSensorId) && Number.isNaN(parsedAttributeId)) {
      // A valid sensor ID was given, update the sensor based on the attribute code.
      const analogSensor = tempAnalogSensors.find((analogSensor) => analogSensor.analogSensorId === parsedSensorId);
      if (analogSensor !== undefined && analogSensor.applications.length > 0) {
        const sensorSelection = {
          analogSensorId: parsedSensorId,
          attributeCode: attributeCode,
          attributeConnectedCode: attributeConnectedCode,
          attributeModeCode: attributeModeCode,
          attributeMinCode: attributeMinCode,
          attributeSpanCode: attributeSpanCode,
          mappedAttributeId: analogSensor.applications[0].attributeId,
          mappedAttributeConnectedId: analogSensor.applications[0].attributeConnectedId,
        };
        tempAnalogSensorMap[attributeCode] = sensorSelection;
        setAnalogSensorMap(tempAnalogSensorMap);
      }
    } else if (!Number.isNaN(parsedSensorId) && !Number.isNaN(parsedAttributeId)) {
      // A valid sensor ID and attribute ID was given, update the attribute based on the attribute code.
      const analogSensor = tempAnalogSensors.find((analogSensor) => analogSensor.analogSensorId === parsedSensorId);
      if (analogSensor !== undefined) {
        const application = analogSensor.applications.find(
          (application) => application.attributeId === parsedAttributeId
        );
        if (application !== undefined) {
          const sensorSelection = {
            analogSensorId: parsedSensorId,
            attributeCode: attributeCode,
            attributeConnectedCode: attributeConnectedCode,
            attributeModeCode: attributeModeCode,
            attributeMinCode: attributeMinCode,
            attributeSpanCode: attributeSpanCode,
            mappedAttributeId: application.attributeId,
            mappedAttributeConnectedId: application.attributeConnectedId,
          };
          tempAnalogSensorMap[attributeCode] = sensorSelection;
          setAnalogSensorMap(tempAnalogSensorMap);
        }
      }
    } else {
      // If nothing is selected, then set the selected application(s) to none.
      delete tempAnalogSensorMap[attributeCode];
      setAnalogSensorMap(tempAnalogSensorMap);
    }
  }

  // Change a digital sensor that is connected to the monitoring device.
  function updateDigitalSensor(
    digitalSensorId: string,
    attributeStateCode: string,
    attributeFlowCode: string,
    attributeAccumulationCode: string,
    attributeModeCode: string,
    attributeKFactorCode: string,
    attributeOffsetCode: string,
    attributeDebounceCode: string,
    attributeUnitsPerPulseCode: string,
    reportingDescription: string
  ): void {
    const tempDigitalSensors = deepCopy(props.digitalSensors);
    const tempDigitalSensorMap = deepCopy(digitalSensorMap);
    const parsedSensorId = parseInt(digitalSensorId, 10);

    if (tempDigitalSensorMap !== null) {
      if (!Number.isNaN(parsedSensorId) && reportingDescription.length === 0) {
        // A valid sensor ID was given, update the sensor based on the attribute codes.
        const digitalSensor = tempDigitalSensors.find(
          (digitalSensor) => digitalSensor.digitalSensorId === parsedSensorId
        );
        if (digitalSensor !== undefined && digitalSensor.applications.length > 0) {
          const sensorSelection = {
            digitalSensorId: parsedSensorId,
            attributeStateCode: attributeStateCode,
            attributeFlowCode: attributeFlowCode,
            attributeAccumulationCode: attributeAccumulationCode,
            attributeModeCode: attributeModeCode,
            attributeKFactorCode: attributeKFactorCode,
            attributeOffsetCode: attributeOffsetCode,
            attributeDebounceCode: attributeDebounceCode,
            attributeUnitsPerPulseCode: attributeUnitsPerPulseCode,
            mappedAttributeStateId: digitalSensor.applications[0].attributeStateId,
            mappedAttributeFlowId: digitalSensor.applications[0].attributeFlowId,
            mappedAttributeAccumulationId: digitalSensor.applications[0].attributeAccumulationId,
            reportingDescription: digitalSensor.applications[0].reportingDescription,
          };
          tempDigitalSensorMap[attributeStateCode] = sensorSelection;
          setDigitalSensorMap(tempDigitalSensorMap);
        }
      } else if (!Number.isNaN(parsedSensorId) && reportingDescription.length !== 0) {
        // A valid sensor ID and attribute ID was given, update the application based on the attribute code.
        const digitalSensor = tempDigitalSensors.find(
          (digitalSensor) => digitalSensor.digitalSensorId === parsedSensorId
        );
        if (digitalSensor !== undefined) {
          const application = digitalSensor.applications.find(
            (application) => application.reportingDescription === reportingDescription
          );
          if (application !== undefined) {
            const sensorSelection = {
              digitalSensorId: parsedSensorId,
              attributeStateCode: attributeStateCode,
              attributeFlowCode: attributeFlowCode,
              attributeAccumulationCode: attributeAccumulationCode,
              attributeModeCode: attributeModeCode,
              attributeKFactorCode: attributeKFactorCode,
              attributeOffsetCode: attributeOffsetCode,
              attributeDebounceCode: attributeDebounceCode,
              attributeUnitsPerPulseCode: attributeUnitsPerPulseCode,
              mappedAttributeStateId: application.attributeStateId,
              mappedAttributeFlowId: application.attributeFlowId,
              mappedAttributeAccumulationId: application.attributeAccumulationId,
              reportingDescription: application.reportingDescription,
            };
            tempDigitalSensorMap[attributeStateCode] = sensorSelection;
            setDigitalSensorMap(tempDigitalSensorMap);
          }
        }
      } else {
        // If nothing is selected, then set the selected attribute(s) to none.
        delete tempDigitalSensorMap[attributeStateCode];
        setDigitalSensorMap(tempDigitalSensorMap);
      }
    }
  }

  // Returns whether the current user is allowed to edit the form with their current permissions.
  function formIsEditable(): boolean {
    return (
      (props.mode === CREATE_MODE && userHasPermission([[CREATE_ASSETS_PERMISSION]])) ||
      (props.mode === EDIT_MODE && userHasPermission([[UPDATE_ASSETS_PERMISSION]]))
    );
  }

  return (
    <div data-test="asset-crud-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        animation
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === CREATE_MODE ? <span>Create Asset</span> : <span>Edit Asset</span>}
          </h5>
        </ModalHeader>

        <ModalBody className={styles.body}>
          {/* Make the name input look different if we are creating or editing. */}
          {props.mode === CREATE_MODE ? (
            <div className="mx-2">
              <label className="mb-3 me-2">Asset Name</label>
              <IconTooltip
                id="asset-name-tooltip"
                icon="info-circle"
                message="The name your asset will have displayed. Each asset must have a unique name."
                color="var(--info-tooltip)"
              />
              <input
                data-test="asset-name-input"
                className="form-control mx-auto"
                type="text"
                maxLength={MAX_ASSET_NAME_LENGTH}
                value={name}
                disabled={isRented || !formIsEditable()}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          ) : (
            <div className="mx-2 mb-2">
              <input
                data-test="asset-name-input"
                className={`${styles.name} form-control px-0 mx-auto`}
                type="text"
                maxLength={MAX_ASSET_NAME_LENGTH}
                value={name}
                disabled={isRented || !formIsEditable()}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          )}

          {/* Asset inputs. */}
          <div className="mx-2 my-3">
            <label className="mb-3 me-2">Asset Nickname</label>
            <IconTooltip
              id="asset-nickname-tooltip"
              icon="info-circle"
              message="An optional descriptive name that will sometimes be displayed along side the asset's name."
              color="var(--info-tooltip)"
            />
            <input
              data-test="asset-nickname-input"
              className="form-control mx-auto"
              type="text"
              maxLength={MAX_ASSET_FIELDS_LENGTH}
              value={nickname}
              disabled={!formIsEditable()}
              onChange={(e) => setNickname(e.target.value)}
            />
          </div>

          <hr className="w-100" />

          {/* Product inputs. */}
          <div className="mx-2 my-3">
            <label className="mb-3 me-2">Product Type</label>
            <select
              data-test="product-type-selection"
              className="form-select"
              value={productType}
              disabled={isRented || !formIsEditable()}
              onChange={(e) => setProductType(e.target.value)}
            >
              <option value="" disabled>
                Please select a product type...
              </option>
              {props.productTypes.map((productType) => (
                <option value={productType.name} key={productType.productTypeId}>
                  {productType.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mx-2">
            <label className="mb-3 me-2">Product Manufacturer</label>
            <select
              data-test="product-manufacturer-selection"
              className="form-select"
              value={productManufacturer}
              disabled={isRented || !formIsEditable()}
              onChange={(e) => setProductManufacturer(e.target.value)}
            >
              <option value="" disabled>
                Please select a product manufacturer...
              </option>
              {PRODUCT_MANUFACTURERS.map((productManufacturer) => (
                <option key={productManufacturer.code} value={productManufacturer.code}>
                  {productManufacturer.name}
                </option>
              ))}
              <option value="OTHER">Other...</option>
            </select>
          </div>

          {productManufacturer === "OTHER" && (
            <div className="mx-2 my-3">
              <input
                data-test="product-manufacturer-custom-input"
                className="form-control mx-auto"
                type="text"
                maxLength={MAX_ASSET_FIELDS_LENGTH}
                value={otherProductManufacturer}
                disabled={isRented || !formIsEditable()}
                onChange={(e) => setOtherProductManufacturer(e.target.value)}
              />
            </div>
          )}

          <div className="mx-2 my-3">
            <label className="mb-3 me-2">Product Part Number</label>
            <IconTooltip
              id="product-part-number-tooltip"
              icon="info-circle"
              message="The product's bill of materials identifier."
              color="var(--info-tooltip)"
            />
            <input
              data-test="product-part-number-input"
              className="form-control mx-auto"
              type="text"
              maxLength={MAX_ASSET_FIELDS_LENGTH}
              value={productModel}
              disabled={isRented || !formIsEditable()}
              onChange={(e) => setProductModel(e.target.value)}
            />
          </div>

          <div className="mx-2 my-3">
            <label className="mb-3 me-2">Product Serial Number</label>
            <IconTooltip
              id="product-serial-number-tooltip"
              icon="info-circle"
              message="The serial number found on the product."
              color="var(--info-tooltip)"
            />
            <input
              data-test="product-serial-number-input"
              className="form-control mx-auto"
              type="text"
              maxLength={MAX_ASSET_FIELDS_LENGTH}
              value={productIdentifier}
              disabled={isRented || !formIsEditable()}
              onChange={(e) => setProductIdentifier(e.target.value)}
            />
          </div>

          <hr className="w-100" />

          {/* Controller select. */}
          <div className="mx-2">
            <label className="mb-3 me-2">Controller</label>
            <IconTooltip
              id="controller-tooltip"
              icon="info-circle"
              message={
                "The type of controller that is attached to the product." +
                " In most cases a controller will support starting and stopping the product."
              }
              color="var(--info-tooltip)"
            />
            <select
              data-test="controller-selection"
              className="form-select"
              value={String(controllerIndex)}
              disabled={isRented || !formIsEditable()}
              onChange={(e) => setControllerIndex(parseInt(e.target.value, 10))}
            >
              <option value="-1">None</option>
              {props.controllers.map((controller, i) => (
                <option value={i} key={i}>
                  {controller.name}
                </option>
              ))}
            </select>
          </div>

          <hr className="w-100" />

          <div className="mx-2 my-3">
            <label className="mb-3 me-2">Monitoring Devices</label>
            <IconTooltip
              id="monitoring-device-tooltip"
              icon="info-circle"
              message={
                "Monitoring devices that are attached to the product. Monitoring devices tracks data about the product," +
                " such as temperature and vibration."
              }
              color="var(--info-tooltip)"
            />

            <br />
            <MonitoringDeviceForm
              deviceIdentifier={deviceIdentifier}
              deviceType={deviceType}
              deviceTypes={props.deviceTypes}
              monitoringDevices={props.monitoringDevices}
              analogSensors={props.analogSensors}
              analogSensorMap={analogSensorMap}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={digitalSensorMap}
              disabled={isRented || !formIsEditable()}
              onChange={(deviceType, deviceIdentifier) => updateDevice(deviceType, deviceIdentifier)}
              onChangeAnalogSensor={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                updateAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
              onChangeDigitalSensor={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                updateDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />
          </div>

          {/* Form for configuring service hours. */}
          {userHasPermission([[UPDATE_SERVICE_HOURS_PERMISSION]]) && (
            <div className="mx-2 mt-4">
              <input
                data-test="configure-hours-checkbox"
                className="form-check-input form-check-big"
                type="checkbox"
                disabled={isRented || !formIsEditable()}
                checked={updateServiceTime}
                onChange={() => setUpdateServiceTime(!updateServiceTime)}
              />
              <label className={styles.serviceLabel} onClick={() => setUpdateServiceTime(!updateServiceTime)}>
                Configure Hours Since Last Serviced
              </label>
              {updateServiceTime && (
                <div className={styles.serviceForm}>
                  <label className="my-2">Select Attribute for Tracking Service&nbsp;</label>
                  <IconTooltip
                    id="asset-service-attribute-tooltip"
                    icon="info-circle"
                    message="Whenever the selected attribute increases, hours since last serviced will increase the same amount."
                    color="var(--info-tooltip)"
                  />
                  <select
                    data-test="service-attribute-selection"
                    className="form-select mb-2"
                    value={serviceAttribute || ""}
                    disabled={isRented || !formIsEditable()}
                    onChange={(e) => setServiceAttribute(e.target.value === "" ? null : e.target.value)}
                  >
                    <option value="">No service attribute</option>
                    <option value={RUNTIME_VIBRATION_ATTRIBUTE}>Vibration-based hours</option>
                    <option value={RUNTIME_ASSET_ATTRIBUTE}>Asset hours</option>
                  </select>
                  <label className="my-2">Hours Since Last Serviced&nbsp;</label>
                  <IconTooltip
                    id="asset-service-time-tooltip"
                    icon="info-circle"
                    message="Manually set the hours since the asset was last serviced."
                    color="var(--info-tooltip)"
                  />
                  <input
                    data-test="service-time-input"
                    className="form-control mb-2"
                    value={serviceTime}
                    disabled={isRented || !formIsEditable()}
                    onChange={(e) => setServiceTime(e.target.value)}
                  />
                </div>
              )}
            </div>
          )}

          {(errorMessage.length > 0 || isRented || isMigrating || !lastConfigSuccessful) && (
            <div className="row">
              <div className="col mt-4 mx-2">
                {isRented && (
                  <Warning message={"You are renting this asset. Your ability to modify this asset is limited."} />
                )}

                {isMigrating && (
                  <Warning
                    message={
                      "This asset has not completed the migration process. New configuration settings will not be applied to the monitoring device hardware to support changes to controllers and sensors."
                    }
                  />
                )}

                {props.mode === EDIT_MODE && !isMigrating && !lastConfigSuccessful && (
                  <Warning
                    message={`This asset failed to update its monitoring device configuration settings on ${formatDateLocal(
                      lastConfigAttemptUtc
                    )}. Once you press 'Save Changes' the configuration will be retried. You will get a notification when the attempt is complete.`}
                  />
                )}
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.mode === CREATE_MODE ? (
            <Fragment>
              <button
                data-test="asset-modal-create-asset-button"
                className={`${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => saveChanges()}
              >
                Create Asset
              </button>

              <button
                data-test="asset-modal-cancel-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {userHasPermission([[DELETE_ASSETS_PERMISSION]]) && !isRented && (
                <button
                  data-test="asset-modal-delete-button"
                  className={`${styles.btn} btn btn-danger me-auto`}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete
                </button>
              )}

              {userHasPermission([[UPDATE_ASSETS_PERMISSION]]) && (
                <button className={`${styles.btn} btn btn-primary`} type="button" onClick={() => saveChanges()}>
                  Save Changes
                </button>
              )}

              <button
                data-test="asset-modal-cancel-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title={`Delete '${name}'?`}
        content={`Are you sure that you want to delete the asset '${name}'?`}
        yesText="Delete Asset"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteAsset(props.assetId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <ConfirmModal
        showModal={props.showModal && (showConfirmTemplateConfig || showConfirmSensorConfig)}
        title="Update monitoring device?"
        content="The attached monitoring device will have to update its configuration. If you proceed, you will get a notification when the update is complete."
        yesText="Update Device and Save Asset"
        noText="Cancel"
        danger={false}
        onClose={() => {
          setShowConfirmTemplateConfig(false);
          setShowConfirmSensorConfig(false);
        }}
        onYes={() =>
          props.mode === CREATE_MODE
            ? createAsset(showConfirmTemplateConfig, showConfirmSensorConfig)
            : isRented
            ? updateAssetNickname(nickname, props.assetId)
            : editAsset(showConfirmTemplateConfig, showConfirmSensorConfig, props.assetId)
        }
        onNo={() => {
          setShowConfirmTemplateConfig(false);
          setShowConfirmSensorConfig(false);
        }}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

AssetModal.propTypes = {
  assetId: PropTypes.number.isRequired,
  mode: PropTypes.oneOf([CREATE_MODE, EDIT_MODE]).isRequired,
  showModal: PropTypes.bool.isRequired,
  initialAsset: PropTypes.object,
  deviceTypes: PropTypes.array.isRequired,
  controllers: PropTypes.array.isRequired,
  productTypes: PropTypes.array.isRequired,
  productManufacturers: PropTypes.array.isRequired,
  monitoringDevices: PropTypes.array,
  analogSensors: PropTypes.array,
  digitalSensors: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  assetId: number;
  mode: string;
  showModal: boolean;
  initialAsset?: AssetConfiguration;
  deviceTypes: DeviceType[];
  controllers: Controller[];
  productTypes: ProductType[];
  productManufacturers: string[];
  monitoringDevices?: SimpleMonitoringDevice[];
  analogSensors: AnalogSensor[];
  digitalSensors: DigitalSensor[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface Controller {
  controllerId: number;
  controllerModel: string | null;
  manufacturerCompanyCode: string | null;
  name: string;
}

interface DeviceType {
  deviceTypeId: number;
  model: string;
  name: string;
}

interface AssetConfiguration {
  assetId: number;
  name: string;
  nickname: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  controllerModel: string | null;
  controllerManufacturer: string | null;
  deviceType: string | null;
  deviceIdentifier: string | null;
  isRented: boolean;
  analogSensors: AnalogSensorSelection[];
  digitalSensors: DigitalSensorSelection[];
}

interface AnalogSensor {
  analogSensorId: number;
  name: string;
  applications: AnalogApplication[];
}

interface AnalogApplication {
  attributeId: number;
  attributeConnectedId: number;
  name: string;
}

interface AnalogSensorMap {
  [key: string]: AnalogSensorSelection;
}

interface AnalogSensorSelection {
  analogSensorId: number;
  attributeCode: string;
  attributeConnectedCode: string;
  attributeModeCode: string;
  attributeMinCode: string;
  attributeSpanCode: string;
  mappedAttributeId: number;
  mappedAttributeConnectedId: number;
}

interface DigitalSensor {
  digitalSensorId: number;
  name: string;
  applications: DigitalApplications[];
}

interface DigitalApplications {
  attributeStateId: number;
  attributeFlowId: number;
  attributeAccumulationId: number;
  reportingDescription: string;
}

interface DigitalSensorMap {
  [key: string]: DigitalSensorSelection;
}

interface DigitalSensorSelection {
  digitalSensorId: number;
  attributeStateCode: string;
  attributeFlowCode: string;
  attributeAccumulationCode: string;
  attributeModeCode: string;
  attributeKFactorCode: string;
  attributeOffsetCode: string;
  attributeDebounceCode: string;
  attributeUnitsPerPulseCode: string;
  mappedAttributeStateId: number;
  mappedAttributeFlowId: number;
  mappedAttributeAccumulationId: number;
  reportingDescription: string;
}

interface AssetRequestBody {
  assetName: string;
  assetNickname: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  controllerModel: string | null;
  controllerManufacturerCode: string | null;
  deviceType: string | null;
  deviceIdentifier: string | null;
  updateTemplate: boolean;
  updateSensors: boolean;
  analogSensors: AnalogSensorSelection[];
  digitalSensors: DigitalSensorSelection[];
  updateServiceHours?: boolean;
  serviceHoursAttributeCode: string | null;
  runtimeSinceLastServiced: number | null;
}

interface GetResponseBody {
  assetId: number;
  name: string;
  nickname: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  controllerModel: string | null;
  controllerManufacturer: string | null;
  deviceType: string | null;
  deviceIdentifier: string | null;
  analogSensors: AnalogSensorSelection[];
  digitalSensors: DigitalSensorSelection[];
  isRented: boolean;
  serviceHoursAttributeCode: string | null;
  runtimeSinceLastServiced: number;
  isMigrating: boolean;
  lastConfigAttemptUtc: string;
  lastConfigSuccessful: boolean;
}

interface PostResponseBody {
  assetId: number;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assetId?: number;
  asset?: AssetConfiguration;
  nickname?: string;
}
