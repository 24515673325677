// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2024-04-10
// Modified By: Lilly Kane
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./AssetThresholdControl.module.scss";

// Control for updating or deleting an asset threshold.
export default function AssetThresholdControl(props: Props): Component {
  // Gets the attribute unit name using the attribute ID.
  function getAttributeUnitName(attributeId: number, attributes: Attribute[]): string {
    const attribute = attributes.find((attribute) => attribute.regAttributeId === attributeId);
    if (attribute !== undefined && attribute.unitShortName !== null) {
      return attribute.unitShortName;
    } else {
      return "";
    }
  }

  // Checks if the specified attribute describes a boolean value.
  function attributeIsBoolean(attributeId: number, attributes: Attribute[]): boolean {
    const attribute = attributes.find((attribute) => attribute.regAttributeId === attributeId);
    if (attribute !== undefined && attribute.isBoolean !== undefined) {
      return attribute.isBoolean;
    } else {
      return false;
    }
  }

  // Get the ID of a comparator by name.
  function getComparatorIdByName(name: string, comparators: Comparator[]): number {
    const foundComparator = comparators.find((comparator) => comparator.name === name);
    if (foundComparator !== undefined) {
      return foundComparator.alertThresholdComparatorId;
    } else {
      return 0;
    }
  }

  // Set attribute type. If we are dealing with a boolean attribute the comparator always defaults to 'equal to'.
  function updateThresholdAttribute(attributeId: number, attributes: Attribute[], comparators: Comparator[]): void {
    if (attributeIsBoolean(attributeId, attributes)) {
      props.onUpdateThreshold(attributeId, getComparatorIdByName("equal to", comparators), "", "");
    } else {
      props.onUpdateThreshold(attributeId, 0, "", "");
    }
  }

  return (
    <div className={styles.body} data-test="asset-manage-threshold-container">
      <div className="mt-2 mb-3">
        {!props.isRental && (
          <i
            data-test="delete-asset-threshold-button"
            className={`${styles.deleteButton} fa fa-fw fa-times float-end`}
            onClick={() => props.onRemoveThreshold()}
          />
        )}
      </div>

      <div>
        <div className="row">
          <div
            className={attributeIsBoolean(props.attributeId, props.attributes) ? "col-12 col-lg-4" : "col-12 col-lg-3"}
          >
            <label className="mb-3 me-2">Attribute</label>
            <select
              data-test="asset-threshold-attribute-selector"
              className="form-select mb-3"
              value={props.attributeId}
              disabled={props.isRental}
              onChange={(e) =>
                updateThresholdAttribute(parseInt(e.target.value, 10), props.attributes, props.comparators)
              }
            >
              <option value="0">Please select an attribute...</option>
              {props.attributes.map((attribute) => (
                <option value={attribute.regAttributeId} key={attribute.regAttributeId}>
                  {attribute.attributeName}
                </option>
              ))}
            </select>
          </div>

          {attributeIsBoolean(props.attributeId, props.attributes) ? (
            <div className="col-12 col-lg-4">
              <label className="mb-3 me-2">Is Value</label>
              <select
                data-test="asset-threshold-boolean-attribute-value-selector"
                className="form-select col-12 mb-3"
                value={props.thresholdValue}
                disabled={props.isRental}
                onChange={(e) =>
                  props.onUpdateThreshold(
                    props.attributeId,
                    getComparatorIdByName("equal to", props.comparators),
                    e.target.value,
                    props.message
                  )
                }
              >
                <option value="">Please select a value...</option>
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </div>
          ) : (
            <Fragment>
              <div className="col-12 col-lg-3">
                <label className="mb-3 me-2">Comparator</label>
                <select
                  data-test="asset-threshold-comparator-selector"
                  className="form-select col-12 mb-3"
                  value={props.alertThresholdComparatorId}
                  disabled={props.isRental}
                  onChange={(e) =>
                    props.onUpdateThreshold(
                      props.attributeId,
                      parseInt(e.target.value, 10),
                      props.thresholdValue,
                      props.message
                    )
                  }
                >
                  <option value="0">Please select a comparator...</option>
                  {props.comparators.map((comparator) => (
                    <option value={comparator.alertThresholdComparatorId} key={comparator.alertThresholdComparatorId}>
                      {comparator.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-12 col-lg-3">
                <label
                  className={`mb-3 me-2 ${
                    getAttributeUnitName(props.attributeId, props.attributes).length > 0 ? "w-75" : "w-100"
                  }`}
                >
                  Value
                </label>
                <input
                  data-test="asset-threshold-comparison-value-input"
                  className={`form-control mx-auto mb-3 d-inline-block ${
                    getAttributeUnitName(props.attributeId, props.attributes).length > 0 ? "w-50" : "w-100"
                  }`}
                  type="text"
                  value={props.thresholdValue}
                  disabled={props.isRental}
                  onChange={(e) =>
                    props.onUpdateThreshold(
                      props.attributeId,
                      props.alertThresholdComparatorId,
                      e.target.value,
                      props.message
                    )
                  }
                />
                <span className="mx-2">{getAttributeUnitName(props.attributeId, props.attributes)}</span>
              </div>
            </Fragment>
          )}

          <div
            className={attributeIsBoolean(props.attributeId, props.attributes) ? "col-12 col-lg-4" : "col-12 col-lg-3"}
          >
            <label className="mb-3 me-2">Message</label>
            <input
              data-test="asset-threshold-message-input"
              className="form-control mx-auto mb-3"
              type="text"
              value={props.message}
              disabled={props.isRental}
              onChange={(e) =>
                props.onUpdateThreshold(
                  props.attributeId,
                  props.alertThresholdComparatorId,
                  props.thresholdValue,
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <div className="my-3">
          <button
            data-test="asset-threshold-manage-subscribed-users-button"
            className="btn btn-primary"
            type="button"
            onClick={() => props.onSelectUsers()}
          >
            Manage Subscribed Users
          </button>
        </div>
      </div>
    </div>
  );
}

AssetThresholdControl.propTypes = {
  attributeId: PropTypes.number.isRequired,
  alertThresholdComparatorId: PropTypes.number.isRequired,
  thresholdValue: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  comparators: PropTypes.array.isRequired,
  isRental: PropTypes.bool.isRequired,
  onUpdateThreshold: PropTypes.func.isRequired,
  onRemoveThreshold: PropTypes.func.isRequired,
  onSelectUsers: PropTypes.func.isRequired,
};

interface Props {
  attributeId: number;
  alertThresholdComparatorId: number;
  thresholdValue: string;
  message: string;
  attributes: Attribute[];
  comparators: Comparator[];
  isRental: boolean;
  onUpdateThreshold: (
    attributeId: number,
    alertThresholdComparatorId: number,
    thresholdValue: string,
    message: string
  ) => void;
  onRemoveThreshold: () => void;
  onSelectUsers: () => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  isBoolean: boolean;
}

interface Comparator {
  alertThresholdComparatorId: number;
  name: string;
}
